import React, { useContext, useEffect, useState } from "react";
import "./Billing.css";

import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import overlayImage from "../../../Assets/Dashboard/Explore/overlayimage.png";
import { api_token } from "../../../Utils/Network";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getPaymentMethod } from "../../../Utils/Utils";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import ComboPopUp from "../ComboPopUp";
import { getDateFormat } from '../../../Utils/Utils'

function Billing(props) {
  const { setPurchaseData } = useContext(UserCredsContext);
  const [openCombo, setOpenCombo] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [comboId, setComboId] = useState(null);
  const [showData, setShowData] = useState({});
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api_token(`/payment/v1/purchase/`)
      .then((response) => {
        setOrderData(response.data.data);
      })
      .catch((error) => { });
  };

  const handleClickOpen = (_data, _method) => {
    setOpen(true);
    setShowData(_data);
    setComboId(_data?.course_info?.id);
    setPaymentMethod(payment_method[_method]?.string);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const grandTotal = () => {
    const new_Data = {
      validity: showData.validity.id,
      course: showData.course_info.id,
    };
    api_token
      .post(`payment/v1/grand_total_price/`, new_Data)
      .then((response) => {
        setPurchaseData(response.data.data, "response");
        setPurchaseData(showData.validity, "purchase");

        props.history.push(
          `/dashboard/totalorder/${showData.course_info.id}/${showData.course_info.is_combo}`
        );
      });
  };
  const orderPurchase = () => {
    if (!showData.is_expired && showData.payment_status == 4) {
      getComboData();
    } else {
      grandTotal();
    }
  };
  const getComboData = () => {
    if (showData.course_info.is_combo) {
      setOpenCombo(true);
    } else {
      props.history.push(
        `/dashboard/mycourse/${showData.course_info.id}/video/true`
      );
    }
  };
  const handleOpen = (_value) => {
    setOpenCombo(_value);
  };
  const getStatusObject = (_item) => {
    const result = payment_status.filter(item => item.id === _item.payment_status)
    console.log(result, 'result is here')
    return result[0]
  }
  return (
    <div className="billing-page-container">
      <div className="explore-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        <img src={overlayImage} className="rect-explore-overlay-c" />
        <div className="explore-hero-text-c">
          <p>Subscriptions</p>
        </div>
      </div>
      <div className="billing-plan-cards-c">
        {comboId != null && (
          <ComboPopUp
            _open={openCombo}
            _handleOpen={handleOpen}
            _id={comboId}
            key={comboId}
          />
        )}
        {orderData.length > 0 && orderData.map((item, index) => {
          const p_method = getPaymentMethod(
            item.payment_method,
            payment_method
          );
          const my_status = getStatusObject(item)
          const expiryDate = getDateFormat(item.expired_on)
          return (
            <div
              className={`${!item.is_expired && item.payment_status == 4
                ? "billing-plan-card"
                : "billing-plan-card-f"
                }`}
              style={{ cursor: "pointer" }}
              onClick={() => handleClickOpen(item, p_method)}
            >
              <p className="current-plan-title">Current Plan</p>
              <div className="plan-details-fc">
                <div className="plan-details-fc-1">
                  <p className="plan-one-tc">{item?.course_info?.title}</p>
                  {/* <p className="plan-two-tc">View Invoice</p> */}
                </div>
                <div className="plan-details-fc-2">
                  <p className="plan-one-tc">All Subjects</p>
                  <p className="plan-two-tc">
                    {item?.course_info?.subject?.length} Subjects
                  </p>
                </div>
                <div className="plan-details-fc-3">
                  <p className="plan-one-tc" >
                    {item?.validity?.validity_period?.days}
                  </p>
                  <p style={{ marginTop: '5px' }}>Days</p>
                  {/* <p>&#8377; 200</p> */}
                </div>
                <div className="plan-details-fc-4">
                  <p className="plan-one-tc">price</p>
                  <p style={{ marginTop: '5px' }}>{item?.validity?.final_price}</p>
                </div>
              </div>
              <p>Purchased Date : <b>{item.provider_id == null ? item.created_date : item.old_created_at}</b></p>
              {my_status?.id !== 5 && <p>Expiry Date : <b>{expiryDate}</b></p>}
              {!item.is_expired && my_status?.id == 4 &&
                <div className="class-active-f">
                  <p>Active</p>
                </div>
              }
              {item.is_expired && my_status?.id == 4 &&
                <div className="class-failed-f">
                  <p>Expired</p>
                </div>
              }

              {my_status?.id == 5 &&
                <div className="class-failed-f">
                  <p>Transaction {item.payment_status_str}</p>
                </div>}
            </div>
          );
        })}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title" style={{color:'black'}}>
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h1 style={{ color: "black", fontSize: "22px" }}>
              Subscription order details
            </h1>
            <hr />
            {showData.hasOwnProperty("id") && (
              <div className="order-details-ui-coups ">
                <div className="order-subject-name-titles">
                  <h3 style={{ width: "100%" }}>
                    {showData?.course_info?.title}
                  </h3>
                  <p className="order-highlight-c">
                    {showData?.validity?.mode?.title}
                  </p>
                  <div className="order-flex-div-c grey-spacing-order-c">
                    <p style={{ margin: "10px 0px" }}>
                      {showData?.validity?.title}
                    </p>
                    <p style={{ margin: "10px 0px" }}>
                      {showData?.validity?.total_price}
                    </p>
                  </div>
                </div>
                <div className="order-add-c">
                  <p
                    className="order-highlight-c"
                    style={{ color: "black", marginBottom: "8px" }}
                  >
                    Delivery Address
                  </p>
                  <div className="order-flex-div-c grey-spacing-order-c">
                    <div>
                      {showData.hasOwnProperty("shipping_address") &&
                        showData.shipping_address !== null ? (
                        <p style={{ color: "black" }}>
                          {" "}
                          {showData.shipping_address.line_1 != null &&
                            showData.shipping_address.line_1}{" "}
                          {showData.shipping_address.line_2 != null &&
                            showData.shipping_address.line_2}{" "}
                          {showData.shipping_address.landmark != null &&
                            showData.shipping_address.landmark}{" "}
                          {showData.shipping_address.city.title != null &&
                            showData.shipping_address.city.title}{" "}
                          {showData.shipping_address.state.title != null &&
                            showData.shipping_address.state.title}{" "}
                          {showData.shipping_address.country.title != null &&
                            showData.shipping_address.country.title}{" "}
                          {showData.shipping_address.zipcode != null &&
                            showData.shipping_address.zipcode}
                        </p>
                      ) : (
                        <p>-</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="order-add-c">
                  <p
                    className="order-highlight-c"
                    style={{ color: "black", marginBottom: "8px" }}
                  >
                    Payment Details
                  </p>
                  <div className="order-flex-div-c grey-spacing-order-c">
                    <div>
                      <p style={{ color: "black" }}>{paymentMethod}</p>
                    </div>
                    <div>
                      <p style={{ color: "black" }}>
                        {showData.payment_status_str}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="order-price-c">
                  <p
                    className="order-highlight-c"
                    style={{ color: "black", marginBottom: "8px" }}
                  >
                    Price Details
                  </p>
                  <div className="order-flex-div-c grey-spacing-order-c">
                    <div style={{ color: "black" }}>
                      <p>Total Price</p>
                      <p>Tax</p>
                      <p>Discount</p>
                      <p>Offer</p>
                      <p>
                        <b>Grand Total</b>
                      </p>
                    </div>
                    <div style={{ color: "black" }}>
                      <p>&#8377; {showData.amount}</p>
                      <p>&#8377; {showData.tax}</p>
                      <p>&#8377; {showData.discount_amt}</p>
                      <p>&#8377; 0.0</p>
                      <p>
                        <b>&#8377; {showData.sub_total}</b>
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={orderPurchase}
            color="primary"
            autoFocus
            variant="contained"
          // disabled={!showData.is_expired && showData.payment_status == 4}
          >
            {!showData.is_expired && showData.payment_status == 4
              ? "Course Details"
              : "re-try payment"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCombo}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      ></Dialog>
    </div>
  );
}

export default withRouter(Billing);

const payment_status = [
  { status: "pending", id: 1, string: "Pending" },
  { status: "cancelled", id: 2, string: "cancelled" },
  { status: "partially_paid", id: 3, string: "Partially Paid" },
  { status: "paid", id: 4, string: "paid" },
  { status: "failed", id: 5, string: "failed" },
  { status: "refund", id: 6, string: "refund" },
];
const payment_method = [
  { status: "card", id: 1, string: "Card" },
  { status: "upi", id: 2, string: "Upi" },
  { status: "netbanking", id: 3, string: "Netbanking" },
  { status: "wallet", id: 4, string: "Wallet" },
];
