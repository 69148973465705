import React from "react";
import styles from "./Contact.module.css";
import call from "../../Assets/call.png";
import email from "../../Assets/email.png";
import reach from "../../Assets/reach.png";
import instagrami from "../../Assets/instagrami.png";
import linkeIn from "../../Assets/linkeIn.png";
import twit from "../../Assets/twit.png";
import youtubeI from "../../Assets/youtubeI.png";
import fb from "../../Assets/fb.png";

function Contact() {
  return (
    <div className={styles.contaoner}>
      <div className={styles.left}>
        <div className={styles.call}>
          <div className={styles.callImg}>
            <img src={call}></img>
          </div>
          <p>CALL US AT</p>
          <hr />
          <a href={`tel: +91 98998 79579`}>+91 98998 79579</a>
        </div>
        <div className={styles.call}>
          <div className={styles.callImg}>
            <img src={email}></img>
          </div>
          <p>EMAIL US AT</p>
          <hr />
          <a href={`mailto: customercare@myburgo.com`}>
            customercare@edunguru.com
          </a>
        </div>
        <div className={styles.call}>
          <div className={styles.callImg}>
            <img src={reach}></img>
          </div>
          <p>REACH US AT</p>
          <hr />
          <span>
            EDUNGURU INC, SAHARA SHAHER VIPUL KHAND 1 GOMTI NAGAR
            <br />
            Lucknow- 226010 (UP)
          </span>
        </div>
      </div>
      <div className={styles.right}>
        <p>FOLLOW US ON</p>
        <div className={styles.iconcontainer}>
          <div className={styles.icons}>
            <a href="https://www.instagram.com/edunguru/" target="_blank">
              {" "}
              <img src={instagrami}></img>
            </a>
          </div>
          <div className={styles.icons}>
            <a
              href="https://www.linkedin.com/company/edunguruonline/"
              target="_blank"
            >
              {" "}
              <img src={linkeIn}></img>
            </a>
          </div>
          <div className={styles.icons}>
            <a href="https://twitter.com/edunguru" target="blank">
              {" "}
              <img src={twit}></img>
            </a>
          </div>
          <div className={styles.icons}>
            <a href="https://www.youtube.com/c/Edunguru" target="blank">
              {" "}
              <img src={youtubeI}></img>{" "}
            </a>
          </div>
          <div className={styles.icons}>
            <a href="https://www.facebook.com/edunguruindia" target="blank">
              {" "}
              <img src={fb}></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
