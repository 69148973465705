import React, { useContext, useEffect, useState } from 'react';
import { UserCredsContext } from '../../ContextApi/UserCredsContext/UserCredsContext';

function Sahaj() {
    const { sahajLink } =
        useContext(UserCredsContext);
    const [sahajData, setSahajData] = useState('')
    useEffect(() => {
        alert('hi')
        setSahajData(sahajLink)
    }, [sahajLink])

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: sahajData }} style={{ height: '10000px' }}></div>
        </div>
    )
}

export default Sahaj;