import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import logoimg from "../../Assets/Goal/logo.png";
import "./Accordian.css";
import { Button } from "@material-ui/core";
import { api_token } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
function AccordionComponent({ _data, ...props }) {
  const { studentProfile } = useContext(UserCredsContext);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [accordianData, setAccordianData] = useState([]);
  const [errors, setErrors] = useState({});
  const [activeId, setActiveId] = useState(-1);
  const [classId, setClassId] = useState({ board_id: null, grade_id: null });
  useEffect(() => {
    setAccordianData(_data);
  }, [_data]);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setClassId({ board_id: null, grade_id: null });
    setActiveId(-1);
  };
  const selectBoard = (index, grade_id, board_id) => {
    setClassId({ grade_id, board_id });
    setActiveId(index);
    setErrors({});
  };

  const validate = () => {
    let temp = {};
    let returnValue = true;
    temp.gradeId =
      classId.board_id !== null
        ? ""
        : "Please select any One grade from Boards below *";
    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };
  const setGoal = () => {
    if (!validate()) {
      return null;
    }
    const data = classId;
    api_token
      .patch(`/profile/v1/student/profile/${studentProfile.id}/`, data)
      .then((response) => {
        if (response.status === 200) {
          if (window.location.href.indexOf("staging") > -1) {
            handleRedirection();
          } else {
            props.history.push("/dashboard/profile");
          }
        }
      })
      .catch((error) => { });
  };

  const handleRedirection = () => {
    // 
    api_token
      .get(`/auth/v1/redirection`)
      .then(response => {

      })
      .catch(error => {

      })
  }
  console.log(studentProfile, "studentProfile")
  console.log(_data, "_data")
  return (
    <div>
      <div className={classes.root} style={{ width: "80%", margin: "auto" }}>
        <div className="button-for-register-c" style={{ marginBottom: "30px" }}>
          <Button variant="outlined" color="primary" onClick={setGoal}>
            Register
          </Button>
        </div>
        <p className="error-message-text">{errors.gradeId}</p>
        {accordianData.length !== 0 &&
          accordianData.map((item, id) => {
            return (
              <Accordion
                expanded={expanded === `panel${id}`}
                onChange={handleChange(`panel${id}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    <img
                      src={logoimg}
                      style={{ width: "25px", marginRight: "10px" }}
                    />
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {item.board.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="accordian-data-container">
                    {item.grade.map((nestedItem, idx) => {
                      return (
                        <div
                          onClick={() =>
                            selectBoard(idx, nestedItem.id, item.board.id)
                          }
                          className={`${activeId === idx
                            ? "active-class-button-goal"
                            : "inactive-class-button-goal"
                            }`}
                        >
                          <p>{nestedItem.title}</p>
                        </div>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
}

export default withRouter(AccordionComponent);
