import React, { useEffect, useRef, useState } from "react";
import recthero from "../../Assets/Goal/Recthero.svg";
import orbitimg from "../../Assets/Goal/orbit.svg";
import cycleimg from "../../Assets/Goal/cycle.svg";
import "./Register.css";
import SearchIcon from "@material-ui/icons/Search";
import AccordionComponent from "../../Components/Accordion/AccordionComponent.js";
import { api_token } from "../../Utils/Network";
import { Switch, Route } from "react-router-dom";
import Introduce from "./Introduce";

function Register(props) {
  const inputRef = useRef(null);
  const [pageType, setPageType] = useState("");
  useEffect(() => {
    getGrade();
    getState();
    if (props.location.pathname === "/register") {
      props.history.push("/register/goal");
    }
    if (props.location.pathname === "/register/introduce") {
      setPageType("introduce");
    } else {
      setPageType("goal");
    }
  }, []);
  const [board, setBoard] = useState([]);
  const [state, setState] = useState([]);
  const getGrade = () => {
    api_token
      .get(`/base/v1/board-grade-detail?1=s`)
      .then((response) => {
        setBoard(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getState = () => {
    api_token
      .get(`/base/v1/state?title=a`)
      .then((response) => {
        setState(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="set-goal-cb">
      <div className="set-goal-cb-hero">
        {pageType === "goal" && (
          <div className="hero-join-now-c-goal">
            <span>
              <SearchIcon />
            </span>

            <input type="text" placeholder="Search" ref={inputRef} />
          </div>
        )}
        <img src={recthero} className="goal-banner-background-hero-h" />
        <img src={orbitimg} className="goal-banner-satellite-img" />
        <img src={cycleimg} className="goal-banner-cycle-img" />
        {pageType === "goal" && <p className="set-a-goal-text">Set a goal</p>}
        {pageType === "introduce" && (
          <p className="set-a-goal-text">Introduce Yourself</p>
        )}
      </div>
      <div className="goal-board-accrordion container-div-box">
        <Switch>
          <Route
            strict
            exact
            path={`${props.match.url}/introduce`}
            render={(_props) => <Introduce {..._props} _data={state} />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/goal`}
            render={(_props) => (
              <AccordionComponent {..._props} _data={board} />
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

export default Register;
