import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import webBanner from "../../Assets/FreeTrailLanding/freeTrialbanner.png";
import mobBanner from "../../Assets/FreeTrailLanding/freeTrialbannermob.png";
import { Button, Snackbar, TextField } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import {
  api_open,
  api_token,
  login_api,
  setEdunguruApi,
} from "../../Utils/Network";
import FreeTrialCourseCard from "../../Components/FreetrialComponent/FreeTrialCourseCard/FreeTrialCourseCard";
import TestimonialSlider from "../../Components/FreetrialComponent/TestimonialSlider/TestimonialSlider";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import About from "../../Components/About/About";
import Benifits from "../../Components/Benifits/Benifits";
import TeachersCards from "../../Components/TeachersCards/TeachersCards";
import LearningProcess from "../../Components/LearningProcess/LearningProcess";
import AccordionUsage from "../../Components/AccordionUsage/AccordionUsage";
import Contact from "../../Components/Contact/Contact";
import TeachertsSLider from "../../Components/TeachertsSLider/TeachertsSLider";
import Bannerimage from "../../Assets/Bannerimage.png";
import Bannermobile from "../../Assets/Bannermobile.png";
import loadGtag from "../../Components/Analytics/Analytics";
import { useLocation } from "react-router-dom";
import OrderPopUp from "../../Panel/Dashboard/TotalOrder/OrderPopUp";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const cookies = new Cookies();

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    // script.onload = display.Razorpay;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function OfferPage() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let history = useHistory();
  const [filterId, setFilterId] = useState({
    boardId: null,
    gradeId: null,
    // subjectId: null,
    // languageId: null,
  });
  const [errors, setErrors] = useState({ name: "" });
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({
    c_phone: "",
    c_otp: "",
    full_name: "",
    schoolname: "",
    board: null,
    grade: null,
  });
  const [board, setBoard] = useState([]);
  const [grade, setGrade] = useState([]);
  const [dataFilled, setDataFilled] = useState(false);
  const [courseData, setCouresData] = useState([]);
  const [isOtpButtonDisabled, setIsOtpButtonDisabled] = useState(false); // New state variable for OTP button
  const [countdown, setCountdown] = useState(30); // State variable for countdown
  const [freeTrial, setFreeTrail] = useState(false); // free trail button disable
  const [fieldDisable, setFieldDisable] = useState(true); // fields disable
  const [courseValidity, setCourseValidity] = useState([]);
  const [userAddress, setUserAddress] = useState(null);
  const [newAddress, setNewAddress] = useState({
    add_one: "",
    add_two: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
  });
  const [openAdd, setOpenAdd] = useState(false);
  const [addressObject, setAddressObject] = useState({
    city: null,
    country: null,
    state: null,
  });
  const [pinError, setPinError] = useState("");
  const [finalSubmit, setFinalSubmit] = useState(false);
  var temp = {};

  const {
    updateLoginData,
    loginData,
    setToken,
    setStudentProfile,
    setUser,
    studentProfile,
    user,
    token,
  } = useContext(UserCredsContext);

  const [userId, setUserId] = useState();

  console.log(user, courseValidity, "localdata");

  const validate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;

    temp.c_phone = num.test(userDetails.c_phone)
      ? ""
      : "Please Enter valid number";
    return returnValue;
  };

  const [selectCardId, setSelectCardId] = useState();
  useEffect(() => {
    getBoard();
    getGrade();
  }, []);

  const getBoard = () => {
    api_open
      .get(`/base/v1/board/free`)
      .then((response) => {
        setBoard(response.data.data);
        // let index = response.data.data.findIndex((el) => el.id == param_id);
        // setFilterId({ ...filterId, boardId: index });
        // if (param_id != "null") {
        //   setFilterState({ ...filterState, board: [param_id] });
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGrade = () => {
    api_open
      .get(`/base/v1/board-grade-detail`)
      .then((response) => {
        setGrade(response.data.data[0].grade);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handle = (event) => {
    // event.preventdefault()
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  // console.log(userDetails, "setSelectCardId");

  const handleChangeBoard = (event, values) => {
    // event.preventdefault()
    // let index = board.findIndex((el) => el.id === values.id);
    // setFilterId({ ...filterId, boardId: index });
    // setFilterState({ ...filterState, board: [values.id] });

    setUserDetails({ ...userDetails, board: values });
    setFilterId({ ...filterId, boardId: values ? values.id : null });
  };

  const handleChangeGrade = (event, values) => {
    // event.preventdefault()
    // let index = grade.findIndex((el) => el.id === values.id);
    // setFilterId({ ...filterId, gradeId: index });
    // setFilterState({ ...filterState, grade: [values.id] });
    setUserDetails({ ...userDetails, grade: values });
    setFilterId({ ...filterId, gradeId: values ? values.id : null });
  };

  // get otp

  const getOtp = () => {
    if (!validate()) return null;

    const payload = {
      phone: userDetails.c_phone,
      code: "+91",
    };

    // console.log(payload, "getOtp ");
    setCountdown(30); // Reset countdown to 30 seconds
    login_api
      .post(
        `/auth/v1/student/account/generate_otp/?isd_code=${payload.code}&phone=${payload.phone}`
      )
      .then((res) => {
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setIsOtpButtonDisabled(true);
          // setTimeout(() => {
          //   setIsOtpButtonDisabled(false);
          // }, 30000); // Disable the button for 30 seconds

          const interval = setInterval(() => {
            setCountdown((prev) => {
              if (prev === 1) {
                clearInterval(interval);
                setIsOtpButtonDisabled(false);
                return 30;
              }
              return prev - 1;
            });
          }, 1000); // Decrease countdown every second
        }
        // console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateOtp = () => {
    const payload = {};

    if (userDetails?.c_otp) {
      login_api
        .post(
          `/auth/v1/student/account/validate_otp_v2/?isd_code=91&phone=${userDetails?.c_phone}&otp=${userDetails?.c_otp}`
        )
        .then((res) => {
          console.log(res?.data?.data);
          if (res?.data?.data?.token) {
            const studentprofile = res?.data?.data?.studentprofile;
            const userProfile = res?.data?.data?.user;
            console.log(userProfile, " resopns:");
            setFieldDisable(false);
            if (userProfile?.first_name) {
              setUserDetails({
                ...userDetails,
                // board: studentprofile?.board,
                // grade: studentprofile?.grade,
                full_name: `${userProfile?.first_name} ${userProfile?.last_name}`,
              });
            }

            // Update filterId to reflect the selected board and grade
            // setFilterId({
            //   boardId: board.findIndex((b) => b.id === studentprofile?.board?.id),
            //   gradeId: grade.findIndex((g) => g.id === studentprofile?.grade?.id),
            // });
            setOpen(true);
            const { message } = res.data.data;
            var redirect = message.includes("try again");
            var redirect = message.includes("expired");
            if (redirect) {
              setErrors({ message });
            } else {
              const { token, user, studentprofile } = res.data.data;

              // if (email_validation_required) {
              //   updateLoginData({
              //     type: "emailAuth",
              //   });
              // } else {
              // handleClose();
              setToken(token);
              setStudentProfile(studentprofile);
              setUser(user);
              console.log("hello worlld");
              setEdunguruApi(token.access);
              // api_token.get(`/auth/v1/redirection`).then((res) => { console.log(res.data.data, "datasad") }).catch((err) => { console.log(err) });
              cookies.set("edutech", token.access, {
                domain: ".edunguru.com",
                path: "/",
              });

              // }
            }
            setUserId(userProfile?.id);
          } else {
            alert(`${res?.data?.data?.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log(userDetails, "new details after resopns:");

  useEffect(() => {
    if (
      userDetails?.full_name?.length > 0 &&
      userDetails?.board &&
      userDetails?.grade &&
      userDetails?.c_otp?.length > 0 &&
      userDetails?.c_phone?.length > 0 &&
      userDetails?.schoolname?.length > 0
    ) {
      setDataFilled(true);
      getCourseListing();
    } else {
      setDataFilled(false);
    }
  }, [
    userDetails?.full_name,
    userDetails?.board,
    userDetails?.grade,
    userDetails?.c_otp,
    userDetails?.c_phone,
    userDetails?.schoolname,
  ]);
  console.log(dataFilled, "dataFilled");

  // ///////////////course listing get data
  useEffect(() => {
    if (userDetails?.board && userDetails?.grade) {
      getCourseListing();
    }
  }, [userDetails?.board, userDetails?.grade]);

  const getCourseListing = () => {
    // setCouresData([])
    setFreeTrail(false);
    const dataforpost = {
      board: [userDetails?.board?.id],
      subject: [],
      grade: [userDetails?.grade?.id],
      language: [],
      is_bookmarked: false,
    };
    console.log(dataforpost, "dataforpost");
    api_open
      .post(`cms/v1/custom_filter`, dataforpost)
      .then((res) => {
        console.log(res?.data?.data);
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setCouresData(res?.data?.data);
          // setFreeTrail(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(selectCardId, "selectCardId");

  const getUserAddress = () => {
    api_token
      .get(`/auth/v1/student/account/me/`)
      .then((response) => {
        if (response.data.data.address.length > 0) {
          setUserAddress(response.data.data.address[0]);
          
            payRazor(response.data.data.address[0]);
          
          
        } else {
          setUserAddress(null);
          setOpenAdd(true);
        }
      })
      .catch((error) => {});
  };

  const checkValidity = (id) => {
    setCourseValidity([]);
    api_token
      .get(`/cms/v1/validity/?course=${id}&validity_period=12`)
      // .get(`/cms/v1/validity/?course=${id}`)
      .then((res) => {
        console.log(res?.data?.data[0], "checkValidity");
        setCourseValidity(res?.data?.data[0]);
       
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyFreeCoures = () => {
    const applydata = {
      user_device: "edunguru web",
      is_coupon_applied: 0,
      user_os: "",
      course_amt: 0,
      validity: 15196,
      coupon_used_amt: 0,
      grand_total: 0,
      platform: 1,
      tax: 0,
      user: userId,
      course: selectCardId?.id,
      user_version: 6.1,
      discount_amt: 0,
      payment_method: 2,
      payment_status: 4,
      shipping_address_id: null,
    };

    if (selectCardId?.id) {
      console.log(applydata, "applyFreeCoures");
      api_token
        .post(`payment/v1/purchase_free_course/`, applydata)
        .then((res) => {
          console.log(res?.data?.data);

          if (res?.status == 200) {
            updateUsreProfile();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("please select coures");
    }
  };

  //  //////USER PATCH /////

  const updateUsreProfile = () => {
    // Extract first name and last name from full name
    const [first_name, ...last_name] = userDetails?.full_name.split(" ");

    const newUserData = {
      full_name: userDetails?.full_name,
      first_name: first_name, // First name extracted
      last_name: last_name.join(" "), // Last name extracted
      grade_id: userDetails?.grade?.id,
      board_id: userDetails?.board?.id,
      school: userDetails?.schoolname,
      reference_id: user?.reference_id,
      languages_id: [1],
    };
    setFinalSubmit(true);
    console.log(newUserData, "updateUsreProfile");
    api_token
      .patch(
        `/auth/v1/student/account/${user?.id}/update_user_detail/`,
        newUserData
      )
      .then((res) => {
        // const { studentprofile } = res.data.data;
        const { studentprofile, ...rest } = res.data.data;
        if (res.status === 201) {
          setStudentProfile(studentprofile);
          setUser(rest);

          //   navigateTocourese(
          //     userDetails?.board,
          //     userDetails?.grade,
          //     selectCardId?.language
          //   );             //// change this for further devlopment
        }
        setTimeout(() => {
          getUserAddress();
        }, 3000);
        // checkValidity(selectCardId?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ///////////////////

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // //////////
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const navigateTocourese = (board_id, grade_id, languages_id) => {
    history.push({
      pathname: "/dashboard/start",
      state: {
        data: true,
        language: languages_id,
        board: board_id?.id,
        grade: grade_id?.id,
      },
    });
  };

  useEffect(() => {
    loadGtag("G-6E0PSFDYDR", location); // Replace 'YOUR_TAG_ID' with your actual Google Analytics tracking ID
  }, [location]);

  // new code adderess input

  const handleOpen = (_boolean) => {
    setOpenAdd(_boolean);
    setNewAddress({
      add_one: "",
      add_two: "",
      landmark: "",
      pincode: "",
      city: "",
      state: "",
    });
    setAddressObject({
      city: null,
      country: null,
      state: null,
    });
  };
  const getLocation = (_pin) => {
    api_token(`/profile/v1/geolocator/?zipcode=${_pin}`)
      .then((response) => {
        const { city, country, state } = response.data.data;
        setNewAddress({
          ...newAddress,
          city: city.title,
          country: country.title,
          state: state.title,
          pincode: _pin,
        });
        setAddressObject({ city, country, state });
        setPinError("");
      })
      .catch((error) => {
        setPinError("No Match !!!!");
      });
  };

  const handleChange = (e, _name) => {
    var name = e.target.name;
    var value = e.target.value;
    setNewAddress({ ...newAddress, [name]: value });
    if (name == "pincode") {
      setTimeout(() => {
        if (value.length == 6) {
          getLocation(value);
        }
      }, 200);
    }
  };
  const handleSubmit = () => {
    postAddress();
  };
  const postAddress = () => {
    const data = {
      zipcode: newAddress.pincode,
      landmark: newAddress.landmark,
      line_2: newAddress.add_two,
      state_id: addressObject.state.id,
      line_1: newAddress.add_one,
      country_id: addressObject.country.id,
      city_id: addressObject.city.id,
    };
    api_token
      .post(`/profile/v1/student/address/`, data)
      .then((response) => {
        // debugger;
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setOpen(false);
          getUserAddress();
          // payRazor(response.data.data.address[0]);
          // setUserAddress(response.data.data.address[0]);
          // setUserAddress(response.data.data);
          setNewAddress({
            add_one: "",
            add_two: "",
            landmark: "",
            pincode: "",
            city: "",
            state: "",
          });
          setAddressObject({
            city: null,
            country: null,
            state: null,
          });
        }
      })
      .catch((error) => {
        setFinalSubmit(false)
      });
  };

  const payRazor = (address) => {
    // debugger;
    // let coupon;
    // if (couponId?.id) {
    //   coupon = couponId.id;
    // } else {
    //   coupon = null;
    // }
    let newAddersobj = address;
    if(courseValidity?.price){
    let datas = {
      amount: courseValidity?.price,
      course: courseData?.id,
      validity_id: courseValidity?.id, ////
      shipping_address_id: newAddersobj?.id,
      user_device: `${user?.first_name} web`,
      user_os: "web",
      platform: 1,
      user_version: 6.1,
      user: user.id,
    };
    api_token
      .post("administration/v2/payment/", datas)
      .then((res) => {
        console.log(res.data, "payment");
        displayRazorPay(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setFinalSubmit(false);
      });
    }
  };

  async function displayRazorPay(amountData, apiCourseidData, validityId) {
    setFinalSubmit(false)
    console.log(amountData, apiCourseidData, validityId, "displayRazorPay");

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("razorpay sdk failed to load. Are you online?");
      return;
    }

    let current_url = window.location.href;
    if (
      current_url.includes("localhost") ||
      current_url.includes("165.22.214.137") ||
      current_url.includes("edunguru2.g10consultancy.com")
    ) {
      //   var razorpay_key = "rzp_test_auECWNj3vbIA10";
      var razorpay_key = "rzp_live_gdFNhp4ajqBhYo";
    } else {
      var razorpay_key = "rzp_live_gdFNhp4ajqBhYo";
    }
    var courseprice = amountData.order.amount;
    const options = {
      key: razorpay_key,
      amount: courseprice,
      currency: "INR",
      name: "Edunguru",
      description: `${courseValidity?.title}`,
      image: "",
      order_id: `${amountData.order.order_id}`,
      handler: function (response) {
        console.log(response, "response");
        getpurchase(
          response.razorpay_order_id,
          response.razorpay_payment_id,
          apiCourseidData,
          validityId,
          amountData
        );
      },
      prefill: {
        name: `${user.first_name}`,
        email: `${user.email}`,
        contact: `${user.phone}`,
      },
    };

    var paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const getpurchase = (orderId, rpid, apiCourseidData, validityId, amt) => {
    console.log(orderId, rpid, apiCourseidData, validityId, "orderIdsss");
    let leads = localStorage.getItem("lead");
    let leadPlat = 1;
    if (leads === undefined) {
      leads = "";
      leadPlat = null;
    }

    api_token
      .patch(`administration/v2/payment/${amt.id}/update_purchased_course/`, {
        transaction_id: rpid,
      })
      .then((r) => {
        if (r.data.data.id) {
          //   setShowDialog(true);
          navigateTocourese(
            userDetails?.board,
            userDetails?.grade,
            selectCardId?.language
          );
        } else {
          alert("Please Check the details");
        }
      })
      .catch((e) => {
        console.log(e, "e");
      });
  };

  console.log(finalSubmit, "setFinalSubmit");

  return (
    <>
      <section className={styles.bannerSection}>
        <div className={styles.bannerImgContainer}>
          {/* <img className={styles.webShow} src={webBanner} alt="" /> */}
          <img className={styles.webShow} src={Bannerimage} alt="" />

          {/* <img className={styles.mobShow} src={mobBanner} alt="" /> */}
          <img className={styles.mobShow} src={Bannermobile} alt="" />
        </div>
      </section>

      <section className={styles.fromConatiner}>
        <div className={styles.innerFromContainer}>
          <div className={styles.MainFrom}>
            <div className={styles.leftSide}>
              <div className={styles.input_box}>
                <div className={styles.mobileNo_box}>
                  <div className={styles.noInput}>
                    <TextField
                      required
                      id="outlined-required"
                      value={userDetails?.c_phone}
                      label="Mobile"
                      variant="outlined"
                      style={{ width: "100%", marginBottom: "20px" }}
                      onChange={handle}
                      name="c_phone"
                    />
                    {/* <p style={{ margin: "0", color: "red" }}>{errors.c_phone}</p> */}
                  </div>
                  <Button
                    variant="contained"
                    className={`${
                      isOtpButtonDisabled ? styles.disableOtp : styles.sendOtp
                    }`}
                    onClick={getOtp}
                    disabled={isOtpButtonDisabled}
                    style={{
                      cursor: `${
                        isOtpButtonDisabled ? "not-allowed" : "pointer"
                      }`,
                    }}
                  >
                    {isOtpButtonDisabled ? `Wait ${countdown} sec` : "Send OTP"}{" "}
                    {/* Show countdown when disabled */}
                  </Button>
                </div>
              </div>

              <div className={styles.input_box}>
                <div className={styles.mobileNo_box}>
                  <div className={styles.otpInpot}>
                    <TextField
                      required
                      value={userDetails?.c_otp}
                      id="outlined-required"
                      label="OTP"
                      variant="outlined"
                      style={{ width: "100%", marginBottom: "20px" }}
                      onChange={handle}
                      name="c_otp"
                    />
                    {/* <p style={{ margin: "0", color: "red" }}>{errors.c_phone}</p> */}
                  </div>
                  <Button
                    variant="contained"
                    className={styles.sendOtp}
                    onClick={validateOtp}
                  >
                    Validate OTP
                  </Button>
                </div>
                {/* <TextField
                  required
                  id="outlined-required"
                  label="OTP"
                  variant="outlined"
                  style={{
                    width: "100%",
                    marginRight: "10px",
                    marginBottom: "20px",
                  }}
                    onChange={handle}
                  name="c_otp"
                /> */}
                {/* <p style={{ margin: "0", color: "red" }}>{errors.c_email}</p> */}
                {fieldDisable && (
                  <p> Please validate the OTP to proceed to the next fields.</p>
                )}
              </div>
              <div className={styles.input_box}>
                <TextField
                  required
                  id="outlined-required"
                  value={userDetails?.full_name}
                  label="Full Name"
                  disabled={fieldDisable}
                  variant="outlined"
                  autoComplete="false"
                  style={{
                    width: "100%",
                    marginRight: "10px",
                    marginBottom: "20px",
                  }}
                  onChange={handle}
                  name="full_name"
                />
                {/* <p style={{ margin: "0", color: "red" }}>{errors.c_email}</p> */}
              </div>
            </div>
            <div className={styles.rightside}>
              <div className={styles.input_box}>
                <Autocomplete
                  // disabled={loading}
                  value={userDetails?.board}
                  id="Board"
                  disabled={fieldDisable}
                  options={board}
                  getOptionLabel={(option) => option.title}
                  // className={classes.autoWidth}
                  onChange={handleChangeBoard}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Board"
                      variant="outlined"
                      size="medium"
                      name="board"
                      style={{
                        width: "100%",
                        marginRight: "10px",
                        marginBottom: "20px",
                      }}
                    />
                  )}
                />
              </div>
              <div className={styles.input_box}>
                <Autocomplete
                  // disabled={loading}
                  disabled={fieldDisable}
                  value={userDetails?.grade}
                  id="Grade"
                  options={grade}
                  getOptionLabel={(option) => option.title}
                  // className={classes.autoWidth}
                  onChange={handleChangeGrade}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Grade"
                      variant="outlined"
                      size="medium"
                      name="grade"
                      style={{
                        width: "100%",
                        marginRight: "10px",
                        marginBottom: "20px",
                      }}
                    />
                  )}
                />
              </div>
              <div className={styles.input_box}>
                <TextField
                  required
                  id="outlined-required"
                  label="School Name"
                  disabled={fieldDisable}
                  variant="outlined"
                  style={{
                    width: "100%",
                    marginRight: "10px",
                    marginBottom: "20px",
                  }}
                  value={userDetails?.schoolname}
                  onChange={handle}
                  name="schoolname"
                />
                {/* <p style={{ margin: "0", color: "red" }}>{errors.c_email}</p> */}
              </div>
            </div>
          </div>

          {/* to slider */}

          {courseData?.length === 0
            ? // <p> No Data Found</p>
              ""
            : dataFilled && (
                <FreeTrialCourseCard
                  data={courseData}
                  setSelectCardId={setSelectCardId}
                  checkValidity={checkValidity}
                />
              )}
          <div className={styles.btnContainer}>
              <Button
                variant="contained"
                disabled={dataFilled ? false : true}
                className={`${dataFilled ? styles.sendOtp : styles.disableOtp}`}
                //   onClick={() => checkValidity(selectCardId?.id)}
                onClick={updateUsreProfile}
              >
                {" "}
                Get Trial Now
              </Button>
            
          </div>
        </div>
      </section>

      <section>
        <About />
      </section>
      <section className={styles.benifit}>
        <Benifits />
      </section>
      <section className={styles.Cards}>
        <h2>Know Your Teachers </h2>
        <div className={styles.align}>
          <TeachertsSLider />
        </div>
      </section>
      <section>
        <LearningProcess />
      </section>
      <section className={styles.contact}>
        <Contact />
      </section>
      <section className={styles.accordian}>
        <h2>Frequently Asked Questions</h2>
        <AccordionUsage />
      </section>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Phone number verified. Complete all required details.
        </Alert>
      </Snackbar>

      <OrderPopUp
        form_title="Shipping Address"
        _open={openAdd}
        _handleOpen={handleOpen}
        formSubmit={handleSubmit}
      >
        <div>
          <TextField
            id="outlined-password-input"
            label="Address Line 1"
            type="text"
            autoComplete=""
            variant="outlined"
            value={newAddress.add_one}
            style={{ width: "100%", marginBottom: "10px" }}
            name="add_one"
            onChange={(e) => handleChange(e, "add_one")}
          />
          <TextField
            id="outlined-password-input"
            label="Address Line 2"
            type="text"
            autoComplete=""
            name="add_two"
            value={newAddress.add_two}
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => handleChange(e, "add_two")}
          />
          <TextField
            id="outlined-password-input"
            label="Nearest Bus stop/ landmark"
            type="text"
            name="landmark"
            autoComplete=""
            value={newAddress.landmark}
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => handleChange(e, "landmark")}
          />
          <TextField
            id="outlined-password-input"
            label="Pincode"
            type="number"
            autoComplete=""
            value={newAddress.pincode}
            name="pincode"
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => handleChange(e, "pincode")}
          />
          {/* {
            pinError && <p style={{color:"red", margin:"0 5px 5px"}}>{pinError}</p>
          } */}
          <TextField
            id="outlined-password-input"
            type="text"
            disabled
            autoComplete=""
            placeholder="city"
            value={newAddress.city}
            name="city"
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <TextField
            id="outlined-password-input"
            type="text"
            name="state"
            value={newAddress.state}
            disabled
            placeholder="state"
            autoComplete=""
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <TextField
            id="outlined-password-input"
            type="text"
            name="country"
            placeholder="country"
            value={newAddress.country}
            disabled
            autoComplete=""
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
          />
        </div>
      </OrderPopUp>

      <Backdrop className={classes.backdrop} open={finalSubmit}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default OfferPage;
