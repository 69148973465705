import React, { Fragment, useContext, useEffect } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import HomePage from "../Pages/Home/Home.js";
import Register from "../Pages/Register/Register.js";
import NavBar from "../Components/NavBar/NavBar.js";
import { UserCredsContext } from "../ContextApi/UserCredsContext/UserCredsContext";
import Dashboard from "../Pages/Dashboard/Dashboard.js";
import PageNotFound from "../Pages/PageNotFound/PageNotFound.js";
import TestComponent from "../Components/TestComponent/TestComponent.js";
import Statistics from "../Panel/Dashboard/Statistics/Statistics";
import Sahaj from "../Pages/Sahaj/Sahaj.js";
import Footer from "../Components/Footer/Footer.js";
import VideoPlay from "../Pages/VideoPlay/VideoPlay.js";
import CourseWare from "../Pages/CourseWare/CourseWare.js";
import Performance from "../Pages/Performance/Performance.js";
import Governing from "../Pages/Governing/Governing.js";
import Academics from "../Pages/Academics/Academics.js";
import StudentAdvisor from "../Pages/StudentsAdvisor/StudentAdvisor.js";
import Contact from "../Pages/Contact/Contact.js";
import Terms from "../Pages/Terms/Terms.js";
import Privacy from "../Pages/Privacy/Privacy.js";
import RedirectCookie from "../Pages/RedirectCookie/RedirectCookie.js";
import Lead from "../Pages/Lead/Lead.js";
import Cancelation from "../Pages/Cancelation/Cancelation.js";
import LeadWinds from "../Pages/LeadWinds/LeadWinds.js";
import FreeTrialLandiangPage from "../Pages/FreeTrialLandiangPage/FreeTrialLandiangPage.js";
import OfferPage from "../Pages/OfferPage/OfferPage.js";
function AppRouter(props) {

  useEffect(() => {
    // debugger;
    let wUrl = window.location.href;
    console.log(wUrl, "WindowURL")
    
    if(wUrl.includes('lead=')){
      debugger;
      let data = wUrl.indexOf('lead=')
      let leadPlat = wUrl.indexOf('lead_platform=')
      let thirdPartyTokenUrl = wUrl.substring(data,wUrl.length - 1) 
      let lead_plat;
      if(leadPlat){
        lead_plat = wUrl.substring(leadPlat,wUrl.length - 1);
      }
      console.log(thirdPartyTokenUrl, lead_plat, "thirdddd")
      let leadSplit = thirdPartyTokenUrl.split('&');
      let demolead = leadSplit[0].split('=');

      if(lead_plat){
        let leadSplit = lead_plat.split('&');
        let demolead = leadSplit[0].split('=');
        localStorage.setItem('lead_platform', demolead[1]);
      }
      localStorage.setItem('lead', demolead[1])
      props.history.push('/')
      // setcookie //if he is logedin or not

      /**
       * 1. If Normal user and if he come from SBI then what? Logout and relogin or what to do? 
       * 
       * 2. sent to login if he is not logedin
       * 
       * 3. if sbi and logged in then where to redirect him and if not then what logout and login 
       */

      // props.history.push(`/lead/${thirdPartyTokenUrl}`)
    }
  }, []);

  return (
    <React.Fragment>
      {props.history.location.pathname.indexOf("/dashboard") === -1 &&
        props.history.location.pathname.indexOf("/video/") === -1 && <NavBar />}
      <Switch>
        <Route strict exact path="/home" component={HomePage} />
        <Route strict exact path="/freetrial" component={FreeTrialLandiangPage} />
        <Route strict exact path="/offer" component={OfferPage} />
        <Route strict exact path="/contact" component={Contact} />
        <Route strict exact path="/CourseWare" component={CourseWare} />
        <Route strict exact path="/Performance" component={Performance} />
        <Route strict exact path="/Governing" component={Governing} />
        <Route strict exact path="/Academics" component={Academics} />
        <Route strict exact path="/Students" component={StudentAdvisor} />
        <Redirect strict exact from="/" to="/home" />
        <Route path="/dashboard" component={Dashboard} />
        <ProtectedRoutes path="/video/:cid" component={VideoPlay} />
        <ProtectedRoutes path="/register" component={Register} />
        <Route path="/mytest/:id" component={TestComponent} />
        <Route path="/statistics" component={Statistics} />
        <Route path="/sahaj" component={Sahaj} />
        <Route strict exact path="/terms" component={Terms} />
        <Route path="/policy" component={Privacy} />
        <Route path="/cancellation-policy" component={Cancelation} />
        <Route path="/redirect-setcookie/:token" component={RedirectCookie} />
        <Route strict exact path="/lead/:thirdUrl" component={Lead} />
        {/* <Route strict exact path="/freetrial" component={FreeTrialLandiangPage} /> */}
        {/* <Route exact path="/leads/:thirdUrl" component={LeadWinds} /> */}
        <Route component={PageNotFound} />
      </Switch>
      {props.history.location.pathname.indexOf("/dashboard") === -1 && (
        <Footer />
      )}
    </React.Fragment>
  );
}


export default withRouter(AppRouter);

export const ProtectedRoutes = ({ component: CFomponent, ...rest }) => {
  const { token } = useContext(UserCredsContext);
  return (
    <Fragment>
      <Route
        {...rest}
        render={(props) => {
          return token.access ? <CFomponent {...props} /> : <Redirect to="/" />;
        }}
      />
    </Fragment>
  );
};
