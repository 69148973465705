import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OrderPopUp({ form_title, _open, _handleOpen, formSubmit, ...props }) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(_open);
  }, [_open]);

  const handleClickOpen = () => {
    setOpen(true);
    _handleOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    _handleOpen(false);
  };

  const handleSubmit = () => {
    formSubmit();
    setOpen(false);
    _handleOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{form_title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {form_title == "Shipping Address" ? (
            <Button onClick={handleSubmit} color="primary">
              Update
            </Button>
          ) : (
            <Button onClick={handleSubmit} color="primary">
              Apply
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default OrderPopUp;
