import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "@material-ui/core";
import { api_open, api_token } from "../../Utils/Network";
import "./Introduce.css";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { withRouter } from "react-router-dom";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
function Introduce({ _data, ...props }) {
  const { user, setStudentProfile } = useContext(UserCredsContext);
  const [language, setLanguage] = useState([]);
  const [activeLanguage, setActiveLanguage] = useState(1);
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [stateId, setStateId] = useState(null);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [srcData, setSrcData] = useState([]);
  const [srcValue, setSrcValue] = useState(null);
  useEffect(() => {
    getLanguage();
    getSource();
  }, []);

  const handleChange = (event, values) => {
    setStateId(values.id);
    setErrors({});
  };

  const getSource = () => {
    api_open
    .get(`base/v1/source`)
    .then((res) => {
      setSrcData(res.data.data);
    })
    .catch(err => console.log(err))
  }
  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    setErrors({});
  };

  const handleSource = (event, values) => {
    console.log(values);
    setSrcValue(values.id);
  }
  const validate = () => {
    let temp = {};
    let returnValue = true;
    const NameRegex = /^[A-Za-z]+$/;
    temp.first_name =
      userData.first_name !== "" &&
        userData.first_name !== undefined &&
        userData.first_name !== null
        ? ""
        : "First Name is required";
    temp.last_name =
      userData.last_name !== "" &&
        userData.last_name !== undefined &&
        userData.last_name !== null
        ? ""
        : "Last Name is required";
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    // temp.email =
    //   userData.email !== "" &&
    //   userData.email !== undefined &&
    //   userData.email !== null
    //     ? EmailRegex.test(userData.email)
    //       ? ""
    //       : "Email is not valid."
    //     : "Email required";
    // temp.stateId = stateId !== null ? "" : "State is required";
    setErrors({
      ...temp,
    });

    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("valid");
    });

    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  const submitPersonalData = () => {
    if (!validate()) {
      return null;
    }
    let leads = localStorage.getItem('lead')
    const data = {
      first_name: userData.first_name,
      languages_id: [activeLanguage],
      // email: userData.email,
      last_name: userData.last_name,
      // state_id: stateId,
      full_name: `${userData.first_name} ${userData.last_name}`,
      reference_id: srcValue,
      lead_id: leads,
    };
    api_token
      .patch(`/auth/v1/student/account/${user.id}/update_user_detail/`, data)
      .then((response) => {
        const { studentprofile } = response.data.data;
        if (response.status === 201) {
          setStudentProfile(studentprofile);
          if (studentprofile.grade === null) {
            props.history.push("/register/goal");
          } else {
            // props.history.push("/");
            if (window.location.href.indexOf("staging") > -1) {
              handleRedirection();
            } else {
              props.history.push("/");
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRedirection = () => {
    // 
    api_token
      .get(`/auth/v1/redirection`)
      .then(response => {

      })
      .catch(error => {

      })
  }
  const getLanguage = () => {
    api_token
      .get(`/base/v1/language/`)
      .then((response) => {
        setLanguage(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const setLanguageId = (id) => {
    setActiveLanguage(id);
  };

  console.log(srcData, "srcData")
  return (
    <div class="introduce-container container-div-box">
      <div className="button-for-register-c">
        <Button variant="outlined" color="primary" onClick={submitPersonalData}>
          Register
        </Button>
      </div>
      <div className="register-yourself-c">
        <div>
          <TextField
            required
            id="standard-required"
            label="First Name"
            name="first_name"
            value={userData.first_name}
            onChange={handleChangeData}
            style={{ width: 300 }}
          />
          <p className="error-message-text error-marginbottom">
            {errors.first_name} &nbsp;
          </p>
        </div>
        <div>
          <TextField
            required
            id="standard-required"
            label="Last Name"
            name="last_name"
            value={userData.last_name}
            onChange={handleChangeData}
            style={{ width: 300 }}
          />
          <p className="error-message-text error-marginbottom">
            {errors.last_name} &nbsp;
          </p>
        </div>
        <div>
        {/* <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={srcData[srcValue]}
          onChange={handleSource}
        >
         {srcData && srcData.map((v,i) => {
          <MenuItem value={v.id}>{v.title}</MenuItem>
         })} 
        </Select> */}

        <div>
          <Autocomplete
            value={srcData[srcValue]}
            id="combo-box-demo"
            options={srcData}
            onChange={handleSource}
            getOptionLabel={(option) => option.title}
            style={{ width: 300, margin: "auto" }}
            renderInput={(params) => (
              <TextField required {...params} label="Source" />
            )}
          />
          {/* <p className="error-message-text error-marginbottom">
            {errors.stateId}&nbsp;
          </p> */}
        </div> 
        </div>
        {/* <div>
          <TextField
            required
            id="standard-required"
            label="Email"
            name="email"
            onChange={handleChangeData}
            value={userData.email}
            style={{ width: 300 }}
          />
          <p className="error-message-text error-marginbottom">
            {errors.email}&nbsp;
          </p>
        </div>
        <div>
          <Autocomplete
            id="combo-box-demo"
            options={_data}
            onChange={handleChange}
            getOptionLabel={(option) => option.title}
            style={{ width: 300, margin: "auto" }}
            renderInput={(params) => (
              <TextField required {...params} label="State" />
            )}
          />
          <p className="error-message-text error-marginbottom">
            {errors.stateId}&nbsp;
          </p>
        </div> */}
        <div>
          <div className="select-language-c">
            <p>Select Language</p>
            <div className="select-language-c-f">
              {language.map((item, id) => {
                return (
                  <div
                    key={id}
                    className={`${activeLanguage === item.id
                        ? "active-language-c"
                        : "inactive-language-c"
                      }`}
                    onClick={() => setLanguageId(item.id)}
                  >
                    {item.title}
                  </div>
                );
              })}
              {/* <div
                className={`${
                  activeLanguage === 1
                    ? "active-language-c"
                    : "inactive-language-c"
                }`}
                onClick={() => setLanguageId(1)}
              >
                English
              </div>
              <div
                className={`${
                  activeLanguage === 2
                    ? "active-language-c"
                    : "inactive-language-c"
                }`}
                onClick={() => setLanguageId(2)}
              >
                Hindi
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Introduce);
