import React, { useEffect, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";

function VimeoPlayer({ video_id, ...props }) {
  const [videoId, setVideoId] = useState(null);
  useEffect(() => {
    if (video_id != null) {
      // if (video_id.vimeo_video_id == 332179860) {
      //   setVideoId(671428176);
      // } else {
        setVideoId(video_id.vimeo_video_id);
      // }
    }
  }, [video_id]);
  console.log(videoId, "videoId");
  return (
    <div>
      {videoId != null && (
        <Vimeo
          style={{ width: "100%" }}
          // width={100}
          // height={480}
          video={videoId}
          autoplay
        />
      )}
      {/* hello */}
      {/* <iframe
        src="https://player.vimeo.com/videos/335101398"
        width="640"
        height="360"
        frameborder="0"
        allowfullscreen
        allow="autoplay; encrypted-media"
      ></iframe> */}
      {/* <iframe
        src="https://player.vimeo.com/videos/671428176"
        width="640"
        height="360"
        frameborder="0"
        allowfullscreen
        allow="autoplay; encrypted-media"
      ></iframe> */}
    </div>
  );
}

export default VimeoPlayer;
// 671428176
// https://player.vimeo.com/video/335101398?h=82ddf25be6
// 335101398
