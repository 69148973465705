import React, { useContext, useEffect, useState } from "react";
import "./Mycourse.css";
import arrowpath from "../../../Assets/Dashboard/arrowpath.png";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import Video from "../Video/Video";
import Note from "../Note/Note";
import Test from "../Test/Test";
import { api_token } from "../../../Utils/Network";
import { greetUser } from "../../../Utils/Utils";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function MyCourse(props) {
  const history = useHistory();
  const { selectedCourse } = useContext(UserCredsContext);
  const [selectCourse, setSelectCourse] = useState(null);
  const { id: params_id } = useParams();
  const [nestedId, setNestedId] = useState(0);
  const [videoListing, setVideoListing] = useState([]);
  const [notesListing, setNotesListing] = useState([]);
  const [greetingMessage, setGreetingMessage] = useState("");
  const [videoCList, setVideCList] = useState([]);
  const handleRoute = (_idx, _url) => {
    setNestedId(_idx);
    props.history.push(
      `${props.match.url}${_url}/${selectCourse.is_purchased}`
    );
  };

  useEffect(() => {
    setGreetingMessage(greetUser);
  }, []);
  useEffect(() => {
    // getChapterVideos();
    getChapterNotes();
    getCourses();
  }, [params_id]);

  const getCourses = () => {
    api_token(`/cms/v1/course/${params_id}`)
      .then((response) => {
        console.log(response.data.data, "DDDDdd")
        setSelectCourse(response.data.data);
      })
      .catch((error) => { });
  };

  const getChapterVideos = () => {
    api_token
      .get(`/cms/v1/chapter_videos_listing/?course=${params_id}&page_size=3`)
      .then((response) => {
        var chapter_list = response.data.data;
        for (var i = 0; i <= chapter_list.length - 1; i++) {
          var video_listing = [];
          for (var j = 0; j <= chapter_list[i].topic.length - 1; j++) {
            var video_in = [...chapter_list[i].topic[j].videos];
            video_listing = [...video_listing, ...video_in];
          }
          chapter_list[i].video_listing = video_listing;
        }
        setVideoListing(chapter_list);
        setVideCList(response.data.data);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    // getVideoList();
  }, []);

  const getVideoList = () => {
    api_token
      .get(`/cms/v1/topic_videos_listing?chapter=9434`)
      .then((response) => {
        console.log(response, "response from page");
      });
  };

  const getChapterNotes = () => {
    api_token
      .get(`/cms/v1/chapter_notes_listing/?course=${params_id}`)
      .then((response) => {
        var chapter_list = response.data.data;
        for (var i = 0; i <= chapter_list.length - 1; i++) {
          var video_listing = [];
          for (var j = 0; j <= chapter_list[i].topic.length - 1; j++) {
            var video_in = [...chapter_list[i].topic[j].notes];
            video_listing = [...video_listing, ...video_in];
          }
          chapter_list[i].video_listing = video_listing;
        }
        setNotesListing(chapter_list);
      })
      .catch((error) => { });
  };

  const goBack = () => {
    history.push({
      pathname: '/dashboard/start',
      state: {
        data: true,
      }
     })
    // props.history.push(`/dashboard/start`, { state: { datas: true}});
  };

  console.log(selectedCourse, "selectedCourse");
  console.log(nestedId, "nestedItems");
  return (
    <div>
      <div
        className="mycourse-hero-section"
        style={{
          // backgroundColor: "#bc1559",
          backgroundImage: `linear-gradient(to right, #${selectCourse !== null ? (selectCourse?.subject[0]?.dark_colour) : 'red'
            }, #${selectCourse !== null ? (selectCourse?.subject[0]?.light_colour): 'blue'
            })`,
        }}
      >
        {/* <div className="mycourse-hero-text-c">
          <p>{greetingMessage}</p>
          <p>Learn from India’s most experienced Gurus.</p>
        </div> */}
        <div className="mycourse-nav-details-c">
          <div
            className="mycourse-i-detail-c"
            onClick={goBack}
            style={{ cursor: "pointer" }}
          >
            <div>
              <img src={arrowpath} className="arrow-path-course" />
            </div>
            <div>
              <div className="mycourse-class-chapter-c">
                <p style={{ fontWeight: "900", fontSize: "2rem" }}>
                  { selectCourse?.subject[0]?.title || selectCourse?.title || "demo"}
                </p>
                <p>
                  {selectCourse !== null && selectCourse?.chapter_count} Chapters
                </p>
              </div>
              <div className="mycourse-filter-c-width">
                {/* <div className="mycourse-filler-c">
                  <div
                    className="mycourse-filler-inner-c"
                    style={{ background: "#F39E40", width: "0%" }}
                  ></div>
                </div>
                <div>
                <span>2/10</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="course-details-iconls">
          <img
            src={selectCourse !== null && selectCourse?.subject[0]?.icon1}
            style={{ width: "50%" }}
          />
        </div>
      </div>
      {/* ${
                  idx === nestedId ? "nested-nav-li-i-active" : ""
                } */}
      <div className="nested-routing-mycourse">
        <div className="nested-navbar-mc">
          {nestedItems.map((item, idx) => {
            return (
            
              <div
                className={`nested-nav-li-i ${idx === nestedId ? "nested-nav-li-i-active" : ""
                  }`}
                style={{
                  backgroundImage: `${idx === nestedId
                    ? `linear-gradient(to right, #${
                      selectCourse?.subject[0]?.dark_colour || "red"
                    }, #${
                      selectCourse?.subject[0]?.light_colour || "blue"
                    })`
                    : `linear-gradient(to right, white, white)`
                    }`,
                }}
                onClick={() => handleRoute(idx, item.url_param)}
              >
                {console.log(selectCourse?.subject[0]?.dark_colour || "blue", 
                  "selectCourse?.subject[0]?.dark_colour")}
                {item.title}
              </div>
            );
          })}
        </div>
        <div className="nested-route-c-p">
          <Switch>
            <Redirect
              from={`${props.match.url}`}
              to={`${props.match.url}/video/:boolean`}
              exact
              strict
            />
            {/* <Redirect from="/dashboard/" to="/dashboard/explore" exact strict /> */}
            <Route
              strict
              exact
              path={`${props.match.url}/video/:boolean`}
              render={(_props) => (
                <Video
                  {..._props}
                  _data={videoListing}
                  _course={selectCourse}
                  _vc_list={videoCList}
                  params_id={params_id}
                />
              )}
            />
            <Route
              strict
              exact
              path={`${props.match.url}/note/:boolean`}
              render={(_props) => (
                <Note {..._props} _data={notesListing} params_id={params_id} />
              )}
            />
            <Route
              strict
              exact
              path={`${props.match.url}/test/:boolean`}
              render={(_props) => (
                <Test {..._props} _data="" param_id={params_id} selectedCourse={selectedCourse} />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default MyCourse;

const nestedItems = [
  {
    title: "Video",
    id: 1,
    url_param: "/video",
  },
  {
    title: "Note",
    id: 2,
    url_param: "/note",
  },
  {
    title: "Test",
    id: 3,
    url_param: "/test",
  },
];
