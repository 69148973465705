import React, { useState, useContext } from 'react'
import PageNotFound from "../../../Pages/PageNotFound/PageNotFound";
import "./Analysis.css";
import soon from '../../../Assets/Dashboard/doubts.png'
import { api_token, base_url } from '../../../Utils/Network';
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from 'antd';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useEffect } from 'react';
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import axios from "axios";
import arrowpath from "../../../Assets/Dashboard/arrowpath.png";
import { useHistory } from "react-router-dom";
import { Alert } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '400px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function AskDoubt() {
    const { user, studentProfile, setUser, setStudentProfile, token } = useContext(UserCredsContext);
    const classes = useStyles();
    const [file, setFile] = useState('');
    const [data, setData] = useState('');
    const [sub, setSub] = React.useState(null);
    const [subjectData, setSubjects] = useState([]);
    const [open, setOpen] = React.useState(false);
    let history = useHistory();

    useEffect(() => {
        getSubjectData();
    }, []);

    const getSubjectData = () => {
        api_token
            .get(`base/v1/subject`)
            .then((res) => {
                setSubjects(res.data.data);
            }).catch(err => console.log(err))
    }

    const handleChanged = (event) => {
        setSub(event.target.value);
    };

    function handleChange(event) {
        setFile(event.target.files[0])
    }

    const handleClose = () => {
        setOpen(false);
      };

    const changesDatas = (e) => {
        const { name, value } = e.target;
        setData(value)
    }

    const backTOPrevious = () => {
        history.push("/dashboard/analysis/mydoubt");
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(event.target, "data")
        const formData = new FormData();
        const apiEnd = 'question/'
        if (file) formData.append('attachment', file);
        formData.append('question', data);
        formData.append('subject_id', sub)


        try {
            await axios({
                method: "POST",
                url: `${base_url}osr/v1/` + apiEnd,
                data: formData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } },
                headers: { 'Authorization': `Bearer ${token.access}` },
            })
                .then(response => {
                    // setLoading(false)
                    if (response.data.data) {
                        setOpen(true);
                        setTimeout(() => {
                            setOpen(false);
                            history.push("/dashboard/analysis/mydoubt");
                        }, 4000);
                        
                    }
                    console.log(response.data, 'response for this data');
                    //   setPersonalDetails({...personalDetails, avatar: response.data.data.image})
                })
                .catch(error => {
                    console.log(error, 'error');
                })
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            <div>
                <div className="explore-hero-section">
                    <img src={rectangleExplore} className="rect-explore-c-h" />
                    <div className="explore-hero-text-c">
                        <div className='data_back'>
                            <div onClick={() => backTOPrevious()} style={{ cursor: 'pointer' }}><img src={arrowpath} alt="image" className='img_back_arrow' /></div>
                            <p>Ask Doubt</p>
                        </div>
                    </div>
                </div>

                <div className="dbt_main">
                    <form onSubmit={handleSubmit} className="fm_data">

                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sub}
                                onChange={handleChanged}
                            >
                                {subjectData && subjectData.map((v, i) => (
                                    <MenuItem value={v.id}>{v.title}</MenuItem>))}
                                {/* <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>
                        </FormControl>
                        <TextField
                            id="outlined-multiline-static"
                            label="Ask Question"
                            multiline
                            rows={7}
                            variant="outlined"
                            className={classes.root}
                            onChange={(e) => changesDatas(e)}
                        />
                        <div className='chooseFile'>
                            <input type="file" onChange={handleChange} />

                        </div>
                        <div>
                            <button type='Submit' className='submitBtn'> Submit</button>
                        </div>
                    </form>
                </div>


                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Question</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Your Question has been added successfully.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} className='submitBtn'>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default AskDoubt