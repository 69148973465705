import React, {useEffect, useState} from 'react';
import Donut from '../ChartDemo/Donut';
import "./Stats.css";
import LinearProgress from '@material-ui/core/LinearProgress';

function Stats({ datas, finalResult, stats }) {

    useEffect(() => {demos()}, [stats.id]);

    const [easys, setEasy] = useState(0);
    const [med, setMed] = useState(0);
    const [har, setHard] = useState(0);

    console.log(stats, "finalResult")

    const demos = () => {
        let easy = 0;
        let medium = 0;
        let hard = 0;
        stats.response !== undefined && stats.response.map((x, i) => {
            if(x.question.complexity.title === 'Easy'){
                easy++;
            } else if(x.question.complexity.title === 'Medium'){
                medium++;
            }else if(x.question.complexity.title === 'Hard'){
                hard++;
            }
        })
        // console.log(easy, medium, hard, "EMH")
        setEasy((easy *100)/stats.questions_attempted);
        setMed((medium *100)/stats.questions_attempted);
        setHard((hard *100)/stats.questions_attempted);
    }

    const demo1 = {
        correct: finalResult.correct_answer,
        wrong: finalResult.wrong_answer,
        total: finalResult.total_questions,
        color1: "#00851b",
    color2: "#bfbaba",
    }
    console.log(stats, "STATs")
    return (
        <div>
            <button onClick={() => datas(1)} className='btn_backStats'>Back</button>
            <div>
                <h1>Accuracy</h1>
            </div>
            <div className="stats_charts">
                <Donut _type={demo1} />
                <div className='stat_result'>
                    <div className='stats_content'>
                        <p>{stats?.questions_attempted}</p>
                        <p>Questions Attempted</p>
                    </div>
                    <div className='stats_content'>
                        <p>{parseInt(stats?.total_time_spend / stats?.questions_attempted)}</p>
                        <p>Time per question</p>
                    </div>
                </div>
            </div>
            <div>
                <h1>Performance analysis</h1>
                <div className='performance'>
                    <div className='progress-main'>
                        <div className='progress_stats'>
                            <LinearProgress variant="determinate" value={easys} style={{ padding: '10px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }} />
                        </div>
                        <p className='text-align'>Easy</p>
                    </div>

                    <div className='progress-main'>
                        <div className='progress_stats'>
                            <LinearProgress variant="determinate" value={med} style={{ padding: '10px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }} />
                        </div>
                        <p className='text-align'>medium</p>
                    </div>
                    <div className='progress-main'>
                        <div className='progress_stats'>
                            <LinearProgress variant="determinate" value={har} style={{ padding: '10px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }} />
                        </div>
                        <p className='text-align'>hard</p>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <h1>Time taken per question</h1>
                </div>
                <div>
                    {stats && stats.response > 0 && stats.response.map((x, i) => (
                        <div key={i} className='prg_Sec_main'>
                            <div className='prg_sec_q'>
                                <p>{i + 1}E</p>
                            </div>
                            <div className='prg_secdata'>
                                <p className='prg_sec'>{x.question.time_to_solve} seconds</p>
                                <div>
                                {/* <LinearProgress variant='determinate' value={80} /> */}
                                    <LinearProgress variant='determinate' value={x.question.time_to_solve} />
                                    </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>)
}

export default Stats;
