import React from 'react'
import styles from "./TeachersCards.module.css"
import { Button } from '@material-ui/core'
import teachersbg from '../../Assets/teachersbg.png'
import teachersDp from '../../Assets/teachersDp.png'


function TeachersCards({data}) {
    console.log(data,"TeachersCards");
    return (
        <div className={styles.container}>

            



                        {/* <div className={styles.middle}>
                            <img src={data.img}></img>

                        </div> */}

                        <div className={styles.bottom}>
                            <h2 className={styles.name}>{data.name}</h2>
                            <span className={styles.sub}>{data.degree}</span>
                            <p className={styles.decs}>{data.desc}</p>


                        </div>
                
        </div>
    )
}

export default TeachersCards
