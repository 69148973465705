import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { api_token } from '../../../Utils/Network';
import RedirectDialog from '../TotalOrder/RedirectDialog';

function SahajResponse() {
    const [showDialog, setShowDialog] = useState(true);
    const [payementData, setPaymentData] = useState({})
    const { id: param_id } = useParams()
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        api_token.get(`/payment/v1/purchase/`)
            .then(response => {
                setPaymentData(response.data.data[0]);
            })
            .catch(error => [

            ])
    }
    const handleShowDialog = (_value) => {
        setShowDialog(_value);
    };
    return (
        <div>
            <RedirectDialog
                showDialog={showDialog}
                handleShowDialog={handleShowDialog}
                param_id={param_id}
                descp={payementData.validity}
            />
        </div>
    )
}

export default SahajResponse;