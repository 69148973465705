import React, { useContext, useState } from "react";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import { login_api } from "../../../Utils/Network";
import "./EmailAuth.css";

function EmailAuthentication() {
  const { updateLoginData } = useContext(UserCredsContext);
  const [user, setUser] = useState({ email: "" });
  const [errors, setErrors] = useState({});

  const checkEmail = () => {
    var temp = {};
    let returnValue = true;
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    temp.email =
      user.email !== "" && user.email !== undefined && user.email !== null
        ? EmailRegex.test(user.email)
          ? ""
          : "Email is not valid."
        : "Email required";
    setErrors({
      ...temp,
    });

    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("valid");
    });

    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };
  const checkId = (e, type) => {
    setUser({ [e.target.name]: e.target.value });
  };
  const getOtp = () => {
    updateLoginData({ email: user.email })
    if (!checkEmail()) {
      return
    }
    login_api.get(`/auth/v1/student/account/generate_email_otp/?email=${user.email}`)
      .then(respone => {
        updateLoginData({
          type: "auth",
          s_type: 'email'
        });
      })
      .catch(error => {

      })
  };
  return (
    <div className="enter-number-login-c">
      <p className="login-num-title">Enter Your Email Id</p>
      <p className="login-num-fields">Email</p>
      <div className="input-field-email">
        <div>
          <input
            name="email"
            type="email"
            id="numberId"
            value={user.email}
            onChange={(e) => checkId(e, "input")}
          />
          <p className="error-message-text">{errors.email}</p>
        </div>
      </div>
      <div className="button-login-c">
        <button className="button-login-cb" onClick={getOtp}>
          GET OTP
        </button>
      </div>
    </div>
  );
}

export default EmailAuthentication;
