import React from 'react'
import { Switch, Route, Redirect, withRouter, Link } from "react-router-dom";
import Analysis from '../Analysis/Analysis';
import AskDoubt from '../Analysis/AskDoubt';

function AnalysisMain(props) {

    console.log(props, "Props")
    return (
        <div>
            <Switch>
                <Redirect from="/dashboard/analysis" to="/dashboard/analysis/mydoubt" exact strict />
                <Route strict
                    exact
                    path={`${props.match.url}/mydoubt`}
                    render={(_props) => <Analysis />} />
                <Route strict
                    exact
                    path={`${props.match.url}/askdoubt`}
                    render={(props) => <AskDoubt />} />

            </Switch>
        </div>
    )
}

export default withRouter(AnalysisMain)