import React, { useEffect, useState } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function CardCarousel({ partial = false, item = 3,next=3, ...props }) {
  const [responsive, setResponsive] = useState({
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 40,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 993 },
      items: item,
      partialVisibilityGutter: 40,
      slidesToSlide: next,
    },
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 2,
      partialVisibilityGutter: 40,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
  });
  return (
    <div>
      <Carousel partialVisible={partial} responsive={responsive}>
        {props.children}
      </Carousel>
    </div>
  );
}

export default CardCarousel;
