import React, { useState, useEffect, useContext } from "react";
import { api_token, login_api } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

function Login() {
  const { updateLoginData, joinNumber } = useContext(UserCredsContext);

  const [errors, setErrors] = useState({});
  const [contactNumber, setContactNumber] = useState({
    code: "+91",
    number: null,
  });
  useEffect(() => {
    if (joinNumber && joinNumber.length == 10) {
      setContactNumber({ ...contactNumber, number: joinNumber });
    }
  }, [joinNumber]);
  const checkId = (e, type) => {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value);
    if (errors.hasOwnProperty(name) && errors[name].length !== 0) {
      setErrors({ ...errors, [name]: "" });
    }
    if (name == "number" && value.length > 10) {
      return;
    }
    setContactNumber({
      ...contactNumber,
      [name]: value,
    });
  };
  const validate = () => {
    let temp = {};
    let returnValue = true;
    temp.code = contactNumber.code ? "" : "Code is required";
    temp.number = /^[0-9][0-9]{9}$/.test(contactNumber.number)
      ? ""
      : "Please enter valid phone number.";
    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("valid");
    });
    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };

  const getOtp = () => {
    if (!validate()) {
      return null;
    }
    login_api
      .post(
        `/auth/v1/student/account/generate_otp/?isd_code=${contactNumber.code}&phone=${contactNumber.number}`
      )
      .then((response) => {
        const { message, status } = response.data.data;
        updateLoginData({
          code: contactNumber.code,
          phone: contactNumber.number,
          otp: "",
          type: "auth",
          s_type: "phone",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="enter-number-login-c">
      <p className="login-num-title">Enter Your Mobile Number</p>
      <p className="login-num-fields">Phone Number</p>
      <div className="input-field-num">
        <div>
          <input
            type="text"
            name="code"
            list="countryCode"
            id="cityId"
            onChange={(e) => checkId(e, "select")}
            defaultValue={contactNumber.code}
          />
          <datalist id="countryCode">
            {optionList.map((item, key) => {
              return <option key={key} value={item.number} />;
            })}
          </datalist>
          <span className="caret"></span>
          <p className="error-message-text"> {errors.code}</p>
        </div>
        <div>
          <input
            name="number"
            type="number"
            id="numberId"
            value={contactNumber.number}
            onChange={(e) => checkId(e, "input")}
          />
          <p className="error-message-text">{errors.number}</p>
        </div>
      </div>
      <div className="button-login-c">
        <button className="button-login-cb" onClick={getOtp}>
          GET OTP
        </button>
      </div>
    </div>
  );
}

export default Login;

const optionList = [
  {
    number: "+91",
    id: 1,
  },
  {
    number: "+92",
    id: 2,
  },
];
