import React from "react";
import Chart from "react-google-charts";
import './Statistics.css';

function Statistics() {
  return (
    <div>
      <section style={{ marginTop: "3%" }}>
        <div className="container">
          <div className="row">
            <h2>Your Score</h2>
          </div>
          <div className="wrapper">
            <div className="countbox">
              <h6>Number of questions you Attempted</h6>
              {"\n"}
              <h3>100</h3>
            </div>
            <div className="countbox">
              <h6>Number of questions you Not Attempted</h6>
              {"\n"}
              <h3>100</h3>
            </div>
            <div className="countbox">
              <h6>Number of questions you Pending</h6>
              {"\n"}
              <h3>100</h3>
            </div>
            <div className="countbox">
              <h6>Total Number of questions</h6>
              {"\n"}
              <h3>100</h3>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row">
            <h2>Quantitative</h2>
          </div>
          <div className="wrapper">
            <div className="countbox">
              <h6>Number of questions you Attempted</h6>
              {"\n"}
              <h3>100</h3>
            </div>
            <div className="countbox">
              <h6>Number of questions you Not Attempted</h6>
              {"\n"}
              <h3>100</h3>
            </div>
            <div className="countbox">
              <h6>Number of questions you Pending</h6>
              {"\n"}
              <h3>100</h3>
            </div>
            <div className="countbox">
              <h6>Total Number of questions</h6>
              {"\n"}
              <h3>100</h3>
            </div>
          </div>
        </div> */}
        <div className="container" style={{ display: "flex" }}>
          <div className="col-md-6">
            <h3>Your Score</h3>
            <Chart
              width={"600px"}
              height={"400px"}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={[
                ["Task", "Hours per Day"],
                ["Number of questions you Attempted", 60],
                ["Number of questions you Not Attempted", 25],
                ["Number of questions you Pending", 15],
              ]}
              options={{
                title: "All task by completion status",
                // Just add this option
                pieHole: 0.6,
              }}
              rootProps={{ "data-testid": "3" }}
            />
          </div>
          <div className="col-md-6">
            <h3>Accuracy</h3>
            <Chart
              width={"600px"}
              height={"400px"}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={[
                ["Task", "Hours per Day"],
                ["Questions Correct", 1],
                ["Question Attempted", 3],
              ]}
              options={{
                title: "All task by completion status",
                // Just add this option
                pieHole: 0.6,
              }}
              rootProps={{ "data-testid": "3" }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Statistics;