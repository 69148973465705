import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: "32px",
        fontWeight: "700",
        color: "#292866",
        marginLeft: "20px"
    },
    expanded: {
        backgroundColor: "#F7F7FD",
        '& $numbers': {
            color: "#292866",
        },
        '& $paras': {
            color: "#3C3C43D9",
        }
    },
    paras: {
        fontSize: "20px",
        fontWeight: "400",

        marginLeft: "75px"
    },
    numbers: {
        fontSize: "48px",
        fontWeight: "700",
        marginTop: "-5px",
        color: "#3C3C4380"

    },
    alignment: {
        display: 'flex',

        justifyContent: "center"

    },
}));

export default function SimpleAccordion() {
    const classes = useStyles();

    return (

        <div className={classes.root}>
            {accordiance.map((item) => {


return(
                <Accordion classes={{ expanded: classes.expanded }}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.alignment} >
                        <Typography className={classes.numbers}>{item.no}</Typography>
                        <Typography className={classes.heading}>{item.head}</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Typography className={classes.paras}>

                         {item.desc}
                        </Typography>
                    </AccordionDetails>
                </Accordion>

       )     })}

        </div>
    );
}
const accordiance = [

    {
        id: 1,
        no: " 01",
        head: " Will there be any course material provided by Edunguru?",
        desc: "Yes, Edunguru will provide the course/program in the form of video lectures and tutor authored e-books which you can access once your login is activated. ",
    },
    {
        id: 2,
        no: "02 ",
        head: "In which all mediums are the courses offered? ",
        desc: "The courses are offered both in English & Hindi medium .",
    },
    {
        id: 3,
        no: " 03",
        head: "  What are the 'learning methods' used by Edunguru for courses offered?",
        desc: "At Edunguru, we help you achieve your desired goal without restrictions-anywhere, anytime. The highlight of our methodology is our innovative approach of providing online and offline options of study material. The teaching methodology at Edunguru includes: Video lectures with concept based learning, tutor authored e-books, live webinars & video & web chats. ",
    },

    {
        id: 4,
        no: " 04",
        head: " Who will be tutors/teachers for the programs offered?",
        desc: "Edunguru has carefully selected & appointed a group of accomplished professors, lecturers, authors and dedicated teachers. Students can reach out to teachers for guidance through our call a Teacher facility ",
    },
    {
        id: 5,
        no: " 05",
        head: "Can I get personal guidance for the program I choose? ",
        desc: " Yes, you can get personal guidance by course Teachers & All India Rank holders through our video & web chat feature.",
    },

]
