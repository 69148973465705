import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./SubjectDialog.css";
import { Link } from "react-router-dom";

function SubjectDialog({ _data, _handleShowDialog, _showDialog, ...props }) {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (_showDialog) {
      setOpen(_showDialog);
    }
  }, [_showDialog]);

  const handleClose = () => {
    setOpen(false);
    _handleShowDialog();
  };
  const getHandle = (_id) => {
    setOpen(false);
    _handleShowDialog(_id);
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "black !important" }}
        >
          Subject
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="display-subject-cd">
              {_data.map((item, idx) => {
                return (
                  <div className="display-subject-f-i" onClick={() => getHandle(item.id)}>
                    <Link
                      to={{
                        pathname: `/dashboard/explore-courses/${null}`,
                        state: { fromSubject: item.id, fromType: 3 },
                      }}
                      key={idx}
                    >
                      <div className="image-container-f-is">
                        <img src={item.icon} style={{ width: "60%" }} />
                      </div>
                      <div className="text-container-f-is">
                        <p>{item.title}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubjectDialog;
