import { AppBar } from "@material-ui/core";
import axios from "axios";

export const handleBaseUrl = () => {
  var url = "https://prod-api.edunguru.com/";
  return url;
  // if (window.location.href.indexOf("localhost") > -1) {
  //   url = "https://api-edunguru2.g10consultancy.com/";
  //   return url;
  // } else if (window.location.href.indexOf("edunguru2") > -1) {
  //   url = "https://api-edunguru2.g10consultancy.com/";
  //   return url;
  // } else if (
  //   window.location.href.indexOf("staging") > -1 ||
  //   window.location.href.indexOf("edunguru") > -1
  // ) {
  //   url = "https://prod-api.edunguru.com/";
  //   return url;
  // }
};

export const handleTestUrl = () => {
  var test = "";
  if (window.location.href.indexOf("localhost") > -1) {
    test = "https://testseries.edunguru.com/my_profile";
    return test;
  } else if (window.location.href.indexOf("edunguru2") > -1) {
    test = "https://testseries.edunguru.com/my_profile";
    return test;
  } else if (
    window.location.href.indexOf("staging") > -1 ||
    window.location.href.indexOf("edunguru") > -1
  ) {
    test = "https://tests.edunguru.com/my_profile";
    return test;
  }
};

export const test_url = handleTestUrl();
export const base_url = handleBaseUrl();

const _access_token = localStorage.getItem("edunguru_access_token");
console.log(_access_token, "_access_token")
var headers =
  _access_token === null || _access_token === undefined
    ? {
        "Content-Type": "application/json",
      }
    : {
        "Content-Type": "application/json",
        Authorization: `Bearer ${_access_token}`,
      };

export var api_open = axios.create({
  baseURL: base_url,
  timeout: 300000000,
  headers: {
    "Content-Type": "application/json",
  },

  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export var login_api = axios.create({
  baseURL: base_url,
  timeout: 300000000,
  headers: headers,

  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export var api_token = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export function setEdunguruApi(access_token) {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem("edunguru_access_token", access_token);
      api_token = axios.create({
        baseURL: base_url,
        timeout: 300000000,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },

        transformRequest: [
          function (data, headers) {
            return JSON.stringify(data);
          },
        ],
      });
      resolve("Successfully set edunguru_api.");
    } catch {
      reject("Error to ser edunguru_api");
    }
  });
}

export async function formDataApi(
  apiName = "",
  formData,
  patch = false,
  apiUrl = "auth/v1/"
) {
  let data = {};
  // const token = JSON.parse(localStorage.getItem("edunguru_access_token"));
  // if (patch)
  //   await axios
  //     .patch(base_url + apiUrl + apiName, formData, {
  //       headers: {
  //         Authorization: token ? `Bearer ${token}` : "",
  //         "content-type": "multipart/form-data",
  //       },
  //       validateStatus: (status) => status < 500,
  //     })
  //     .then((response) => {
  //       if (response.status === 200 || response.status === 201)
  //         return (data = response.data.data);
  //       return (data = response.data);
  //     })
  //     .catch((e) => console.log(e));
  // else
  await axios
    .patch(base_url + apiUrl + apiName, formData, {
      headers: {
        Authorization: _access_token ? `Bearer ${_access_token}` : "",
        "content-type": "multipart/form-data",
      },
      validateStatus: (status) => status < 500,
    })
    .then((response) => {
      if (response.status === 200 || response.status === 201)
        return (data = response.data.data);
      return (data = response.data);
    })
    .catch((e) => console.log(e));
  return data;
}
