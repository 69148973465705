import React, { useState, useEffect } from 'react'
import moment from "moment";
import clock from "../../Assets/LiveSession/clocks.png"
import styles from "./index.module.css";

function TimeLeft({ times, dates }) {

    const [daysLeft, setDaysLeft] = useState("");
    const [timeLeft, setTimeLeft] = useState({
        hrs: '',
        min: '',
        sec: '',
    });

    useEffect(() => {
        getDate();
        getTime();
    }, []);

    useEffect(() => {
        setInterval(() => {
            getTime();
        }, 1000);
    }, [])

    const getDate = () => {
        var eventdate = moment(dates);
        var todaysdate = moment();
        setDaysLeft(eventdate.diff(todaysdate, 'days'))
    }

    const getTime = () => {
        let date = moment(dates, "YYYY-MM-DD").format(
            "DD MMM YYYY "
        )
        var datess = new Date(`${date} ${times} GMT+05:30`);
        var timeStamp = datess.getTime();
        var now = new Date().getTime();
        var distance = timeStamp - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimeLeft({ hrs: hours, min: minutes, sec: seconds })
    }

    return (
        <div>
            <div>
                {/* {`${timeLeft.hours()} ${timeLeft.minutes()} ${timeLeft.seconds()}`} */}
            </div>
            {
                (daysLeft > 0) ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={clock} alt="images" style={{ width: '50px', margin: '10px' }} />
                        <div style={{ color: '#787878', fontSize: '18px', marginBottom: '10px' }}><span style={{ fontSize: '23px', color: '#1E1E5C', fontWeight: 'bold' }}>{daysLeft}</span> Days To Go</div> </div> :
                    (timeLeft.hrs >= 0) ? <div className={styles.timesSession}>
                        <div style={{margin: '10px'}}><div className={styles.timeing}>{timeLeft.hrs}</div><div>HOUR</div></div>  :
                        <div style={{margin: '10px'}}><div className={styles.timeing}>{timeLeft.min}</div><div>MINS</div></div> :
                        <div style={{margin: '10px'}}><div className={styles.timeing}>{timeLeft.sec}</div><div>SECS</div></div>

                    </div> : <div>session over</div>}
        </div>
    )
}

export default TimeLeft