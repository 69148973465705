import React, { useState, useEffect } from "react";
import PageNotFound from "../../../Pages/PageNotFound/PageNotFound";
import "./Analysis.css";
import soon from '../../../Assets/Dashboard/doubts.png'
import { api_token } from '../../../Utils/Network';
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import { useHistory } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';
import doubt from "../../../Assets/Doubt/doubt.png";

function Analysis() {

  const [doubtData, setDoubtData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();

  useEffect(() => {
    getDoubts();
  }, [])

  const getDoubts = () => {
    setIsLoading(true)
    api_token
      .get(`osr/v1/question/`)
      .then((res) => {
        setDoubtData(res.data.data);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const moveto = () => {
    history.push('/dashboard/analysis/askdoubt')
  }
  console.log(doubtData, "doubt")
  return (
    <div>
      <div className="explore-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        <div className="explore-hero-text-c">
          <p>My Doubt</p>
        </div>
      </div>

      <div className="dbt_main">
        <div className="dbt_box">
          {doubtData && doubtData.map((values, index) => (
            <div className="dbt_main_container">
              <div className="dbt_container">
                <div className="dbt_img">
                  <img src={values?.created_by?.avatar} alt="images" className="dbt_question_image" />
                </div>
                <div className="doubt_detail">
                  <p className="dbt_quest">{`${values?.created_by?.first_name} ${values?.created_by?.last_name}`}</p>
                  <p className="dbt_tag" style={{ color: 'red' }}>Question</p>
                  <p className="dbt_text">{values?.question}</p>
                  {values?.attachment && <div className="wid">
                    <img src={values?.attachment} alt="images" className="wid" />
                  </div>}
                </div>
              </div>
              <div>
                {values?.answer_data && values?.answer_data.map((vx, i) => (
                  <div className="dbt_container">

                    <div className="dbt_img">
                      <img src={vx?.created_by?.avatar} alt="images" className="dbt_question_image" />
                    </div>
                    <div className="doubt_detail">
                      <p className="dbt_quest">{`${vx?.created_by?.first_name} ${vx?.created_by?.last_name}`}</p>
                      <p className="dbt_tag" style={{ color: 'green' }}>Answer</p>
                      <p className="dbt_text">{vx?.answer}</p>
                    </div>
                  </div>))
                }
                {values?.answer_data.length == 0 && <div className="dbt_container"><p>No Answer</p></div>}

              </div>
            </div>))}

          {isLoading &&
            <div>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="rect" width={210} height={118} />
            </div>}
        </div>
      </div>

      <div>
        <button className="askDoubtBtn" onClick={() => moveto()}>Ask a Doubt <img src={doubt} alt="image" /></button>
      </div>
      {/* Analysis */}
      {/* ../Assets/Doubt/doubt.png */}
    </div>
  );
}

export default Analysis;
