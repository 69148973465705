import React, { useEffect, useState } from "react";
import live_btn from "../../../Assets/LiveSession/live_btn.png";
import card_background from "../../../Assets/LiveSession/live_class_background.png";
import omgoingBg from "../../../Assets/LiveSession/omgoingBg.svg";
import female_teacher from "../../../Assets/LiveSession/portrait-female-teacher-white.png";
import { api_open, api_token } from "../../../Utils/Network";
import calender_Icon_black from "../../../Assets/LiveSession/calender_Icon_black.svg";
import { Box, Button, Typography } from "@material-ui/core";
import styles from "./index.module.css";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import TimeLeft from "../../../Components/TimeLeft/TimeLeft";

function Live(props) {
  const [liveVideo, setLiveVideo] = useState([]);
  const [upcomming, setUpcomming] = useState([]);
  const [liveClass, setLiveClass] = useState([]);
  let history = useHistory();

  useEffect(() => {
    getLiveVideo();
    onGoingSession();
    upCommingSession();
    getDate();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const getDate = () => {
    var eventdate = moment("2022-9-30");
    var todaysdate = moment();
    console.log(eventdate.diff(todaysdate, 'days'), "Dates")
  }
  const onGoingSession = () => {
    api_token
      .get(`/live/v1/sessions/live`)
      .then((res) => {
        setLiveClass(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const upCommingSession = () => {
    api_token
      .get(`/live/v1/sessions/upcoming`)
      .then((res) => {
        setUpcomming(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const JoinNOW = (value) => {
    api_token
      .get(`live/v1/sessions/${value}/issue_token`)
      .then((res) => {
        let data = res.data.data;
        window.open(data.url)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getLiveVideo = () => {
    api_token
      .get(`/live/v1/live_courses/`)
      .then((response) => {
        setLiveVideo(response.data.data);
        console.log(response, "getLiveVideo");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handelDescription = (id) => {
    console.log(id);
    api_token
      .get(`/sessions/?live_course=${id}`)
      .then((response) => {
        var chapter_list = response.data.data;
      })
      .catch((error) => { });
  };
  const goToDescription = (id) => {
    console.log(id, "ID")
    history.push(`description/${id}`);
  };
  console.log(upcomming,liveVideo, "liveVideo");

  return (
    <div style={{ marginTop: "50px", width: "auto" }}>

      {/* onGoing Class */}
      {liveClass.length > 0 && <div className={styles.flex}>
        <p className={styles.live_session_header} style={{ padding: '5px' }}>Ongoing Classes</p>
      </div>}
      {liveClass.length > 0 && liveClass.map((data, idx) => (<div className={styles.mainList}>
        <div className={`${styles.upcoming_container} ${styles.ongoingBG}`}>
          <h2 style={{ color: '#1E1E5C' }}>{data.title}</h2>
          <div className={styles.upcoming}>
            <div className={styles.textData}>Live Classes</div>
            <div className={styles.textData}>Session 1</div>
          </div>
          <div className={styles.upcoming}>
            <div className={styles.boxUpcoming}>
              {(!data?.instructor?.user?.avatar) ? <div className={styles.imagesDiv}></div> : <img src={data?.instructor?.user?.avatar} alt="imageData" className={styles.image_data} />}

            </div>
            <div>
              <p style={{ margin: '5px 0' }}>{`${data?.instructor?.user?.first_name} ${data?.instructor?.user?.last_name}`}</p>
              <p style={{ margin: '5px 0' }}>Guru</p>
            </div>
          </div>

          <div>
            <div className={styles.timeLeftFor}>
              <div style={{ color: '#787878', fontSize: '18px', marginBottom: '10px' }}>Time Left</div>
              <TimeLeft times={data.conclude} dates={data.date} />

              <button className={`${styles.btnGreen} ${styles.btnJoin} `} onClick={() => JoinNOW(data.id)}> Join Now</button>
            </div>
          </div>
        </div>
      </div>))}

      {/* Batches Enrolled */}

      <div className={styles.mainList}>
        <div className={styles.flex}>
          <p className={styles.live_session_header} style={{ padding: '5px' }}>Upcoming Classes</p>
          <Link to="#">View All</Link>
        </div>

        {upcomming && upcomming.map((v, i) => {

          return (
            <div className={styles.upcoming_container}>
              <h2 style={{ color: '#1E1E5C' }}>{v?.title}</h2>
              <div className={styles.upcoming}>
                <div className={styles.textData}>Lives Classes</div>
                <div className={styles.textData}>Session {i + 1}</div>
              </div>
              <div className={styles.upcoming}>
                <div className={styles.boxUpcoming}>
                  <img src={v.instructor.user.avatar} alt="imageData" className={styles.image_data} />
                </div>
                <div>
                  <p style={{ margin: '5px 0' }}>{v.instructor.user.first_name} {v.instructor.user.last_name}</p>
                  <p style={{ margin: '5px 0' }}>Guru</p>
                </div>
              </div>

              <div>
                <div className={styles.timeLeftFor}>
                  <div style={{ color: '#787878', fontSize: '18px', marginBottom: '10px' }}>Starts In</div>
                  <TimeLeft times={v.commence} dates={v.date} />

                  <button className={`${styles.btnJoin}`} disabled> Join Now</button>
                </div>
              </div>
            </div>)
        })}

      </div>

      <div className={styles.live_video_c}>
        <p className={styles.live_session_header}>Batches Enrolled</p>
        <div className={styles.live_video_fc}>
          {liveVideo && liveVideo.map((item, id) => {
            return (
              <>
                <Box
                  boxShadow={3}
                  borderRadius="20px"
                  key={id}
                  m={5}
                  className={styles.liveContainer}
                  onClick={() => goToDescription(item?.id)}
                >
                  <div className={styles.positionRelative}>
                    <img src={item?.image} alt={item.title} style={{ height: "auto", width: "100%" }} />
                    {item.subject.map((v, i) => (<div
                      className={styles.subjectIcon}
                      style={{
                        padding: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                        height: "50px",
                        width: "50px",
                        boxShadow: "0px 4px 10px #00000040",
                      }}
                    >
                      <img
                        src={v.icon}
                        alt={v.title}
                        style={{ height: "auto", width: "100%" }}
                      />
                    </div>))}
                    <div className={styles.liveButtonIcon}>
                      <img
                        src={live_btn}
                        alt=""
                        className={styles.liveTitleBanner}
                      />
                      <p className={styles.liveTitleBanner}>{item.title}</p>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "56px 20px 15px",
                      display: "flex",
                      color: "#000",
                      fontWeight: "400",
                      fontSize: "20px",
                      lineHeight: "24px",
                      borderRadius: "20px",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      whiteSpace="nowrap"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        whiteSpace="nowrap"
                        justifyContent="space-between"
                        alignItems="center"
                        fontWeight={700}
                        fontSize={40}
                      >
                        <p className={styles.liveCardTitle}>
                          {item?.title}
                        </p>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        whiteSpace="nowrap"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <p className={styles.liveCardSubTitle}>
                          live classes | session 2
                        </p>
                      </Box>
                    </Box>
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      display: "flex",

                      fontWeight: "400",
                      fontSize: "20px",
                      lineHeight: "24px",
                      borderRadius: "20px",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      pl={2}
                      pr={2}
                    >
                      <Box display="flex">
                        <Box pr={2}>
                          <img
                            src={calender_Icon_black}
                            alt="calenderIcon"
                            width={20}
                          />
                        </Box>
                        <Box
                          display=" "
                          flexDirection="row"
                          whiteSpace="nowrap"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <p className={styles.liveCardSubTitle_blue}>
                            {/* 4 Feb, 2022 - 4 Apr, 2022 */}
                            {moment(item.start, "YYYY-MM-DD").format(
                              "DD MMM YYYY "
                            )} - {moment(item.end, "YYYY-MM-DD").format(
                              "DD MMM YYYY "
                            )}
                          </p>
                          <p className={styles.liveCardSubTitle}> Duration</p>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Box>
              </>
            );
          })}
        </div>
      </div>

      {/* Explore More */}
      {/* <div className={styles.exploreMoreContainer}>
        <Button variant="contained" className={styles.exploreMore}>
          Explore More
        </Button>
      </div> */}
    </div>
  );
}

export default Live;
