import React, { useEffect, useState } from "react";
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import overlayImage from "../../../Assets/Dashboard/Explore/overlayimage.png";
import "./TeacherProfile.css";
import profileImg from "../../../Assets/Profile/user.png";
import { Button } from "@material-ui/core";
import { useParams } from "react-router";
import { api_token } from "../../../Utils/Network";
import inst1 from "../../../Assets/Dashboard/Instructor/inst1.png";
import CardCarousel from "../../../Components/CardCarousel/CardCarousel";

function TeacherProfile() {
  const [instructorData, setInstructorData] = useState({});
  const [similarEducators, setSimilarEducators] = useState([]);
  const { id: param_id } = useParams();
  const [selectedId, setSelectedId] = useState(1);
  const [personalDetails, setPersonalDetails] = useState({
    first_name: "",
    last_name: "",
    id: "",
    use_type: "",
    avatar: "",
  });

  useEffect(() => {
    getProfessorData();
  }, []);

  const getProfessorData = () => {
    api_token
      .get(`/profile/v1/instructor/${param_id}`)
      .then((response) => {
        console.log(response, "response for prof");
        setInstructorData(response.data.data);
        const { avatar, first_name, id, last_name, user_type, subjects } =
          response.data.data;
        setPersonalDetails({ avatar, first_name, id, last_name, user_type });
        if (subjects.length !== 0) {
          getSimilarProfessor(subjects[0].id);
        } else {
          getSimilarProfessor(1);
        }
      })
      .catch((error) => { });
  };

  const getSimilarProfessor = (_id) => {
    api_token
      .get(`/profile/v1/instructor/?default_subject=${_id}`)
      .then((response) => {
        setSimilarEducators(response.data.data);
      })
      .catch((error) => { });
  };
  const handleSelected = (_id) => {
    setSelectedId(_id);
  };
  return (
    <div>
      <div className="profile-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        <img src={overlayImage} className="rect-explore-overlay-c" />

        <div className="professor-hero-text-c">
          <div>
            <div className="profile-image-c">
              {!personalDetails.avatar && (
                <img src={profileImg} className="profile-image-tag" />
              )}
              {personalDetails.avatar && (
                <img
                  src={personalDetails.avatar}
                  className="profile-image-tag"
                />
              )}
            </div>
          </div>
          <div className="professor-details-hc">
            <p className="profile-name-c-npf-p">
              {personalDetails.first_name} {personalDetails.last_name}
            </p>
            <p className="profile-mdetails-c-npf">Chemistry Educator</p>
            <p className="profile-mdetails-c-npf">M.sc in Organic Chemistry</p>
            <p className="profile-mdetails-c-npf">8+ years of experience</p>
          </div>
        </div>
        <div className="professor-int-details-c">
          <div className="professor-intd-i-c">
            <p className="int-d-t">
              {instructorData.hasOwnProperty("likes_count") ? (
                <span>{instructorData.likes_count}</span>
              ) : (
                <span></span>
              )}
            </p>
            <p className="int-d-d">Likes</p>
          </div>
          <div className="professor-intd-i-c">
            <p className="int-d-t">-</p>
            <p className="int-d-d">Watch Mins</p>
          </div>
          <div className="professor-intd-i-c">
            <p className="int-d-t">
              {instructorData.hasOwnProperty("followers_count") ? (
                <span>{instructorData.followers_count}</span>
              ) : (
                <span></span>
              )}
            </p>
            <p className="int-d-d">Followers</p>
          </div>
          <div className="professor-intd-i-c">
            <Button variant="outlined" color="primary" size="small">
              Follow
            </Button>
          </div>
        </div>
      </div>
      <div className="professor-tab-section">
        <div className="professor-tab-header">
          {tabData.map((item, index) => {
            return (
              <p
                className={`${item.id === selectedId
                  ? "active-tab-prof-h"
                  : "professor-tab-header-p"
                  }`}
                onClick={() => handleSelected(item.id)}
              >
                {item.title}
              </p>
            );
          })}
        </div>
        <div className="profile-details-tt-c">
          {selectedId == 1 && (
            <div className="about-instructor-cc">
              {instructorData.hasOwnProperty("institute") && (
                <div className="about-intructor-cc-ip">
                  <div className="list-icon-inst-details">
                    <img src={inst1} className="list-icon-c" />
                  </div>
                  <div className="list-descp-inst-details">
                    <p className="list-descp-para">
                      Works at <span>{instructorData.institute.title}</span>
                    </p>
                  </div>
                </div>
              )}
              {instructorData.hasOwnProperty("education") && (
                <div className="about-intructor-cc-ip">
                  <div className="list-icon-inst-details">
                    <img src={inst1} className="list-icon-c" />
                  </div>
                  <div className="list-descp-inst-details">
                    {instructorData.education.map((item, idx) => {
                      return (
                        <p className="list-descp-para">
                          Studied at <span>{item.institute}</span>
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}
              {instructorData.hasOwnProperty("education") && (
                <div className="about-intructor-cc-ip">
                  <div className="list-icon-inst-details">
                    <img src={inst1} className="list-icon-c" />
                  </div>
                  <div className="list-descp-inst-details">
                    {instructorData.education.map((item, idx) => {
                      return (
                        <p className="list-descp-para">
                          <span>{item.degree.title}</span>
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}
              {instructorData.hasOwnProperty("known_language") && (
                <div className="about-intructor-cc-ip">
                  <div className="list-icon-inst-details">
                    <img src={inst1} className="list-icon-c" />
                  </div>
                  <div className="list-descp-inst-details">
                    <p className="list-descp-para">
                      Knows &nbsp;
                      {instructorData.known_language.map((item, idx) => {
                        return <span>{item.title} </span>;
                      })}
                    </p>
                  </div>
                </div>
              )}
              {instructorData.hasOwnProperty("subjects") && (
                <div className="about-intructor-cc-ip">
                  <div className="list-icon-inst-details">
                    <img src={inst1} className="list-icon-c" />
                  </div>
                  <div className="list-descp-inst-details">
                    <p className="list-descp-para">
                      Teaches &nbsp;
                      {instructorData.subjects.map((item, idx) => {
                        return <span>{item.title} </span>;
                      })}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedId == 2 && <div style={{ minHeight: '350px' }}>Review Section</div>}
        </div>
        <div className="similar-educators-c">
          <p className="similar-educators-t">Similar Educators</p>
          <CardCarousel>
            {[...Array(4)].map((item, idx) => {
              return (
                <div className="prof-carousel-card-c">
                  <div className="prof-carousel-card-a">
                    <div className="prof-carousel-card-ups">
                      <div>
                        <img
                          src={profileImg}
                          className="prof-carousel-avatar"
                        />
                      </div>
                      <div className="prof-card-named">
                        <p>Vaibhav Gupta</p>
                        <p>22K Followers</p>
                      </div>
                    </div>
                    <div className="prof-carousel-card-lower">
                      <p>chemistry educator</p>
                      <p>8+ years of experience</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </CardCarousel>
        </div>
      </div>
    </div>
  );
}

export default TeacherProfile;

const tabData = [
  {
    title: "About",
    id: 1,
  },
  {
    title: "Reviews",
    id: 2,
  },
];
