import React from "react";
import noData from "../../Assets/empty/no notes.png";
function PageNotFound() {
  return (
    <div>
      <img src={noData} alt="noData" style={{ width: "100%" }}></img>
      <p style={{textAlign: 'center'}}>No Data Found</p>
    </div>
  );
}

export default PageNotFound;
