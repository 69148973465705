import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  useParams,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import VimeoPlayer from "../../Components/Vimeo/VimeoPlayer";
import { api_token } from "../../Utils/Network";
import { secondsToMinutes } from "../../Utils/Utils";
import "./VideoPlay.css";

function VideoPlay(props) {
  const [videoData, setVideoData] = useState([]);
  const { cid: params_id } = useParams();
  const [videoId, setVideoId] = useState(0);
  const [videoObject, setVideoObject] = useState(null);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api_token(`/cms/v1/topic_videos_listing?chapter=${params_id}`)
      .then((response) => {
        setVideoData(response.data.data);
        setVideoId(0);
        setVideoObject(response.data.data[0]);
      })
      .catch((error) => {});
  };

  const toggleVideo = (_item, _id) => {
    setVideoId(_id);
    setVideoObject(_item);
  };

  return (
    <div>
      <div className="viewvideo-main">
        <div className="left-side-video">
          <Button
            // color="primary"
            variant="contained"
            style={{
              margin: "10px 0px 20px",
              background: "#515491",
              color: "white",
            }}
            onClick={() => props.history.goBack()}
          >
            Go Back to Course
          </Button>
          <div className="">
            <VimeoPlayer video_id={videoObject} key={Math.random()} />
          </div>
        </div>
        <div className="vvp-c-list">
          {videoData.length != 0 &&
            videoData.map((item, idx) => {
              var totalTime = secondsToMinutes(
                item.videos[0].video_link.duration
              );
              return (
                <div>
                  <div className="course-video-co">
                    <div
                      className="video-bg-sec"
                      onClick={() => toggleVideo(item, idx)}
                      style={{
                        border: `${
                          videoId == idx
                            ? "2px solid #f17717"
                            : "2px solid black"
                        }`,
                        borderRadius: "5px",
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={item.videos[0].video_link.pictures.base_link}
                        />
                        <div className="float-timec">
                          <p>{totalTime}</p>
                        </div>

                        {/* <div className="video-title-sec">
                              <p>{item_list.title}</p>
                            </div> */}
                      </div>
                      {/* {item_list.resume_video.length !== 0 && (
                <div className="video-bar-resume-c">
                  <div
                    style={{
                      background: "red",
                      width: `${50}%`,
                      height: "4px",
                    }}
                  ></div>
                </div>
              )} */}
                      <div className="video-title-sec">
                        <p>{item.videos[0].title}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default withRouter(VideoPlay);
// #f17717
