import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router";
import CardCarousel from "../../../Components/CardCarousel/CardCarousel";
import TestComponent from "../../../Components/TestComponent/TestComponent";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../../Utils/Network";
import ChartDemo from "../../../Panel/Dashboard/ChartDemo/ChartDemo";

import "./Test.css";
import Statistics from "../Statistics/Statistics";
import Stats from "../Stats/Stats";
function Test({ param_id, selectedCourse, ...props }) {
  const {
    user,
    setTestData: _setTestData,
    testData: _testData,
    resetTestData,
  } = useContext(UserCredsContext);
  const [giveTest, setGiveTest] = useState(false);
  const [testData, setTestData] = useState([]);
  const [testOptions, setTestOptions] = useState({});
  const [submit, setSubmit] = useState(null);
  const [stats, setStats] = useState({});
  const [finalResult, setFinalResult] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsloading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    count: null,
    max_pages: null,
    next_page: null,
    page: null,
    previous_page: null,
    total_count: null,
  });
  useEffect(() => { _setTestData([]); }, []);
  useEffect(() => getTestData(), [submit]);
  useEffect(() => { getData(1) }, []);
  useEffect(() => {
    // alert("hi");
  }, []);
  const getData = (_page_no) => {
    api_token
      .get(`/cms/v1/chapter_test_listing/?course=${param_id}&page=${_page_no}&page_size=4`)
      .then((response) => {
        setIsloading(true);
        const {
          count,
          max_pages,
          next_page,
          page,
          previous_page,
          total_count,
        } = response.data;
        setPaginationData({
          count,
          max_pages,
          next_page,
          page,
          previous_page,
          total_count,
        });
        setCurrentPage(page);
        setIsFetching(false);
        setIsloading(false);
        var test_list = response.data.data;
        for (var i = 0; i <= test_list.length - 1; i++) {
          var test_listing = [];
          var test_in = [...test_list[i].tests];
          test_listing = [...test_listing, ...test_in];
          test_list[i].test_listing = test_listing;
        }
        if (test_list.length) {
          setTestData([...testData, ...test_list]);
        }
      })
      .catch((error) => { });
  };
  const toggleTestView = (_value, _data) => {
    setGiveTest(_value);
    setTestOptions(_data);
  };
  const getTestData = () => {
    api_token
      .get(`cms/v1/test_result/?test=${finalResult.test}`)
      // .get(`cms/v1/test_result/?test=193`)
      .then((response) => {
        console.log(response, "DDDDDd")
        setStats(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleScroll() {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight ||
      isFetching
    )
      return;
    setIsFetching(true);
  }
  useEffect(() => {
    if (!isFetching) return;
    if (currentPage == paginationData.max_pages) {
      return;
    }
    var x = Number(currentPage);
    getData(x + 1);
  }, [isFetching]);
  // console.log(selectedCourse.subject[0], "FFFFFFF")
  const testList = () => {
    return (
      <div className="tests-container-c-i">
        {!isLoading ? <>

          {testData.length > 0 &&
            testData.map((item, idx) => {
              return (
                <React.Fragment>
                  <div style={{display: 'flex'}}>
            <span style={{  marginTop: '15px', marginRight: '10px',  }}>{idx + 1}.</span>{" "}
                  <p>{item.title}</p>

                  </div>
                  <p>{item.tests.length} Topics</p>
                  <div className="tests-card-c">
                    <CardCarousel partial={true} item={4}>
                      {item.tests.map((_item, _idx) => {
                        return (
                          <div>
                            <div className="tests-card-c-i">
                              <div className="tests-card-c-iu">
                                <p> {_item.title}</p>
                              </div>
                              <div
                                className="tests-card-c-il"
                                style={{
                                  backgroundImage: `${`linear-gradient(to right, #${selectedCourse !== null &&
                                    selectedCourse?.subject[0]?.dark_colour
                                    }, #${selectedCourse !== null &&
                                    selectedCourse?.subject[0]?.light_colour
                                    })`
                                    }`
                                }}

                                onClick={() => toggleTestView(true, _item)}
                              >
                                <p>Start</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </CardCarousel>
                  </div>
                </React.Fragment>
              );
            })}</> : <>
          (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
          )
        </>
        }
      </div>
    );
  };

  console.log(submit, "stats");
  console.log(isLoading, "ISLOAd")
  return (
    <div className="tests-container-c">
      {!giveTest && testList()}
      {giveTest && submit === null && (
        <TestComponent
          _data={testOptions}
          setFinalResult={setFinalResult}
          _datas={(submit) => setSubmit(submit)}
        />
      )}
      {submit === 1 && (
        <ChartDemo
          finalResult={finalResult}
          datas={(submit) => setSubmit(submit)}
        />
      )}
      {submit === 2 && (
        <Stats
          finalResult={finalResult}
          stats={stats}
          datas={(submit) => setSubmit(submit)}
        />
      )}
    </div>
  );
}

export default withRouter(Test);
