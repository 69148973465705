import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  useParams,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import ComboPopUp from "../ComboPopUp";

function RedirectDialog({
  showDialog,
  handleShowDialog,
  param_id,
  descp,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openCombo, setOpenCombo] = useState(false);
  const { boolean: is_purchased } = useParams();
  useEffect(() => {
    if (showDialog == true) {
      setOpen(true);
    }
  }, [showDialog]);

  const handleOpen = (_value) => {
    setOpenCombo(_value);
  };

  const handleClose = () => {
    setOpen(false);
    handleShowDialog(false);
  };
  const redirect = (_type) => {
    if (_type == "learn") {
      // props.history.push(`/dashboard/mycourse/${param_id}/video/true`);
      // setOpen(false);
      // handleShowDialog(false);
      if (is_purchased == 'false') {
        props.history.push(`/dashboard/mycourse/${param_id}/video/true`);
      } else {
        setOpenCombo(true);
      }
    } else {
      props.history.push(`/dashboard/billing`);
      setOpen(false);
      handleShowDialog(false);
    }
  };
  console.log(descp, 'descp  ')
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        // onClose={handleClose}
        maxWidth="xl"
        style={{ borderRadius: "50px" }}
      >
        <div className="change-gradient">
          <div style={{ textAlign: "center" }}>
            <p style={{ color: 'white' }}>Your Subscription has been activated</p>
            {descp !== undefined && descp.hasOwnProperty('mode') && <p style={{ color: 'white' }}>
              {descp.mode.title} {descp.validity_period.title} content
            </p>}
            <p style={{ color: 'white' }}>Subscription has been activated</p>
            <h2 style={{ margin: "30px 0px", color: 'white' }}>Happy Learning!</h2>
            <Button
              onClick={() => redirect("learn")}
              style={{ background: "#fbc002", marginRight: "10px" }}
            >
              Start Learning Now
            </Button>
            <Button onClick={() => redirect("subs")}>
              view your subscription
            </Button>
          </div>
        </div>
      </Dialog>
      <ComboPopUp
        _open={openCombo}
        _handleOpen={handleOpen}
        _id={param_id}
        key={param_id}
      />
    </div>
  );
}

export default withRouter(RedirectDialog);
// 065e5d
// 12a47a

// fbc002
