import React from 'react'
import styles from './Benifits.module.css'
import acces from '../../Assets/acces.png'
import Lectures from '../../Assets/Lectures.png'
import Topic from '../../Assets/Topic.png'
import Revision from '../../Assets/Revision.png'
import Medium from '../../Assets/Medium.png'

function Benifits() {
    return (<>        <h2 className={styles.head}>Benefits</h2>
        <div className={styles.container}>
       
            {data.map((item) => {
                return (
                    <div className={styles.benefits}>
                        <div>
                            <img src={item.img}></img>
                        </div>
                        <h3>{item.head}</h3>
                        <p>{item.desc}</p>
                    </div>

                )
            })}


        </div>
        </>
 )
}

export default Benifits
const data = [
    {
        id: 1,
        img: acces,
        head: "Free 60 Day Access",
        desc: "Emphasize the free trial period to reduce sign-up friction."
    },
    {
        id: 2,
        img: Lectures,
        head: "chapter-wise Video Lectures",
        desc: "Focus on the structured learning approach."
    }, {
        id: 3,
        img: Topic,
        head: "Detailed Topic Coverage",
        desc: "Emphasize the comprehensiveness of the content."
    }, {
        id: 4,
        img: Revision,
        head: "Revision Tests",
        desc: "Emphasize the free trial period to reduce sign-up friction."
    }, {
        id: 5,
        img: Medium,
        head: "Hindi & English Medium",
        desc: "Cater to the language preferences of students in Madhya Pradesh."
    },


]