import React from "react";
import { Switch, Route, Redirect, withRouter, Link } from "react-router-dom";
import Live from "../Live/Live";
import Livelist from "../Live/LiveList";
import PayLive from "../Live/PayLive";

function LiveMain(props) {
  console.log(props, "Props");
  return (
    <div>
      <Switch>
        <Redirect
          from="/dashboard/live"
          to="/dashboard/live/live"
          exact
          strict
        />
        <Route
          strict
          exact
          path={`${props.match.url}/live`}
          render={(props) => <Live />}
        />
        <Route
          strict
          exact
          path={`${props.match.url}/description/:id`}
          render={(_props) => <Livelist />}
        />
        <Route 
        strict
        exact
        path={`${props.match.url}/description/:id/pay`}
        render={(__props) => <PayLive props={__props}/>}
        />
      </Switch>
    </div>
  );
}

export default withRouter(LiveMain);
