import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import VideoPlayerN from "../../../Components/VideoPlayerN/VideoPlayerN";
import "./PlayVideo.css";
function PlayVideo(props) {
  const [video, setVideo] = useState([]);
  const [chapter, setChapter] = useState();
  const props_id = props.match.params.id;
  useEffect(() => {
    var video_url = localStorage.getItem("video_listing");
    var chapter_count = props_id;
    video_url = JSON.parse(video_url);
    setChapter(chapter_count);
    setVideo(video_url);
    console.log(video_url, 'video_url')
  }, [props_id]);
  console.log(chapter, 'chapter')
  return (
    <div className="video-player-view-c">
      {video.length !== 0 && (
        <React.Fragment>
          <h2>Chapter {chapter}</h2>
          <p>{video[chapter - 1].title}</p>
        </React.Fragment>
      )}
      <div className="video-play-section">
        <VideoPlayerN _data={video[chapter - 1]} />
      </div>
    </div>
  );
}

export default withRouter(PlayVideo);
