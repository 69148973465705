import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { setEdunguruApi, base_url, test_url } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function Lead(props) {
  const { setToken, setStudentProfile, setUser } = useContext(UserCredsContext);
  const [messageData, setMessageData] = useState("");
  const [loading, setLoading] = useState(true);
  const [leadUrl, setLeadsUrl] = useState([]);
  const { thirdUrl } = useParams();

  useEffect(() => {
    console.log(thirdUrl, "ThirdUrl")

    let leadSplit = thirdUrl.split('&');
    setLeadsUrl(leadSplit);
    // let url_token = localStorage.getItem('edunguru_access_token')
    // LoginUser(url_token);
  }, [thirdUrl])

  console.log(leadUrl, "LeadURL")

  let leads = leadUrl[0]?.split('=')
  console.log(leads, "llllll")
  localStorage.setItem('lead', leads[1]);
  props.history.push('/')
  //   const LoginUser = async (url_token) => {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Authorization", `Bearer ${url_token}`);

  //     var requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };

  //     fetch(
  //       `${base_url}auth/v1/app_to_web_autologin`,
  //       requestOptions
  //     )
  //       .then((response) => response.text())
  //       .then((result) => {
  //         var response = JSON.parse(result);
  //         const { token, user, studentprofile, message } = response.data;
  //         setLoading(false);
  //         setToken(token);
  //         setStudentProfile(studentprofile);
  //         setUser(user);
  //         setEdunguruApi(token.access);
  //         setLoading(false);
  //         setMessageData(message);
  //         // console.log(test_url, "test_url");
  //         cookies.set('edutech', token.access, { domain: '.edunguru.com', path: '/' });
  //         localStorage.setItem('leads', leads[1]);
  //         props.history.push('/')
  //       })
  //       .catch((error) => {
  //         console.error('Error', error)
  //         setLoading(false);
  //         setMessageData('Something went wrong');
  //       });
  //   };

  return (
    <div>
      <div style={{ marginTop: '100px' }}>
        <p>Please Login to </p>
      </div>
    </div>
  )
}

export default Lead