import React from 'react'
import styles from './LearningProcess.module.css'
import planet from '../../Assets/planet.png'
import bulb from '../../Assets/bulb.png'
import TestimonialSlider from '../FreetrialComponent/TestimonialSlider/TestimonialSlider'
import Contact from '../Contact/Contact'

function LearningProcess() {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2>The Learning Process </h2>
                <p>At Edunguru, we have innovatively designed the courseware with the aim of building strong fundamentals. As the course progresses, the content is built up for better and easy understanding with best-in- class teaching technique and pedagogy. The learners are made to learn with the help of high quality videos and conceptual learning which extends extra assistance to make learning quicker and faster.</p>
                <div className={styles.bulb}>
                    <img src={bulb}></img>
                </div>
                <div className={styles.planet}>
                    <img src={planet}></img>
                </div>
            </div>
            <section className={styles.testimonialsContainer}>
                <h2 className={styles.sectionHeading}>Testimonial</h2>

                <TestimonialSlider />
            </section>
            
        </div>
    )
}

export default LearningProcess