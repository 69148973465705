import React, { useContext, useEffect, useState } from "react";
import a1 from "../../../Assets/Home/a1.png";
import a2 from "../../../Assets/Home/a2.png";
import a3 from "../../../Assets/Home/a3.png";
import a4 from "../../../Assets/Home/a4.png";
import a5 from "../../../Assets/Home/a5.png";
import a6 from "../../../Assets/Home/a6.png";
import a7 from "../../../Assets/Home/a7.png";
import a8 from "../../../Assets/Home/a8.png";
import a9 from "../../../Assets/Home/a9.png";
import a10 from "../../../Assets/Home/a10.png";
import a11 from "../../../Assets/Home/a11.png";
import a12 from "../../../Assets/Home/a12.png";
import rect1 from "../../../Assets/Home/rectangle1.png";
import rect2 from "../../../Assets/Home/rectangle2.png";
import rect3 from "../../../Assets/Home/rectangle3.png";
import rect4 from "../../../Assets/Home/rectangle4.png";
import rect5 from "../../../Assets/Home/rectangle5.png";
import rect6 from "../../../Assets/Home/rectangle6.png";
import rect7 from "../../../Assets/Home/rectangle7.png";
import rect8 from "../../../Assets/Home/rectangle8.png";
import rect9 from "../../../Assets/Home/rectangle9.png";
import rect10 from "../../../Assets/Home/rectangle10.png";
import rect11 from "../../../Assets/Home/rectangle11.png";
import rect12 from "../../../Assets/Home/rectangle12.png";
import "./Explore.css";
import groupcat from "../../../Assets/Dashboard/Explore/groupcat.png";
import CardCarousel from "../../../Components/CardCarousel/CardCarousel";
import greenRect from "../../../Assets/Home/greenrectangle.png";
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import overlayImage from "../../../Assets/Dashboard/Explore/overlayimage.png";
import { Link } from "react-router-dom";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import SubjectDialog from "../../../Components/SubjectDialog/SubjectDialog";
import { api_open } from "../../../Utils/Network";

function Explore({ _data, ...props }) {
  const { setFilterTabs, filterTabs } = useContext(UserCredsContext);
  const [exploreData, setExploreData] = useState([]);
  const [subjectArray, setSubjectArray] = useState([]);
  const [showDialog, setShowDialog] = useState();
  useEffect(() => {
    if (_data.length !== 0) {
      setExploreData(_data);
      setFilterTabs(_data[1].data);
    }
  }, [_data]);

  useEffect(() => {
    getSubject();
  }, []);

  const getSubject = () => {
    api_open
      .get(`/base/v1/subject/`)
      .then((response) => {
        console.log(response.data.data, " from ");
        setSubjectArray(response.data.data);
      })
      .catch((error) => {});
  };

  const handleShowDialog = () => {
    setShowDialog(false);
  };

  console.log(exploreData, "DATA")
  return (
    <div>
      <div className="explore-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        {/* <img src={overlayImage} className="rect-explore-overlay-c" /> */}
        <div className="explore-hero-text-c">
          <p>Start Your Journey Today</p>
          <p>Learn from India’s most experienced Gurus.</p>
        </div>
      </div>
      <div className="explore-cat-f-c container-div-box">
        <p className="subject-home-f-c-title container-div-box-title-e">
          {exploreData.length != 0 && exploreData[1].title}
        </p>
        <CardCarousel>
          {exploreData.length !== 0 &&
            exploreData[1].data.map((item, id) => {
              console.log(item.id, "ITEM")
              return (
                <div>
                  {(item.id == 3) ? <div onClick={() => setShowDialog(true)} style={{cursor: 'pointer'}}>

                  <div className="explore-cat-container">
                        <div
                          className="explore-cat-text"
                          style={{ color: `${item.text_color}` }}
                        >
                          <p
                            dangerouslySetInnerHTML={{ __html: `${item.title}` }}
                          ></p>
                        </div>
                        <img src={item.icon} />
                      </div>
                  </div> :

                    <Link
                      to={{
                        pathname: `/dashboard/explore-courses/${null}`,
                        state: { fromType: item.id },
                      }}
                    >

                      <div className="explore-cat-container">
                        <div
                          className="explore-cat-text"
                          style={{ color: `${item.text_color}` }}
                        >
                          <p
                            dangerouslySetInnerHTML={{ __html: `${item.title}` }}
                          ></p>
                        </div>
                        <img src={item.icon} />
                      </div>
                    </Link>}
                </div>
              );
            })}
        </CardCarousel>

        <SubjectDialog
          _data={subjectArray}
          _handleShowDialog={handleShowDialog}
          _showDialog={showDialog}
        />
      </div>
      <div className="subject-home-f-c container-div-box">
        <p className="subject-home-f-c-title container-div-box-title-e">
          {exploreData.length != 0 && exploreData[2].title}
        </p>
        <div className="subject-flex-c-h">
          {exploreData.length !== 0 &&
            exploreData[2].data.map((item, idx) => {
              return (
                <div className="subject-flex-i-d-c">
                  <Link to={`/dashboard/explore-courses/${item.id}`}>
                    <div className="subject-img-c-t">
                      <div
                        style={{
                          width: "80px",
                          height: "80px",
                          padding: "3px",
                          borderRadius: "10px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                        }}
                      >
                        {/* <img src={rect1} className="subject-icon-outer" /> */}
                        <img src={item.icon} className="subject-icon-overlay" />
                      </div>
                    </div>
                    <p>{item.title}</p>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      <div className="prepare-for-banner container-div-box">
        <div className="prepare-for-bamnner-container">
          <p className="subject-home-f-c-title container-div-box-title-e">
            {exploreData.length != 0 && exploreData[3].title}
          </p>
          {/* <img src={greenRect} /> */}
          <CardCarousel item={1} next={1}>
            {exploreData.length !== 0 &&
              exploreData[3].data.map((item, id) => {
                return (
                  <div key={id}>
                    <div className="explore-cat-container">
                      <img src={item.image} />
                    </div>
                  </div>
                );
              })}
          </CardCarousel>
          {/* <p>Prepare For IIT JEE</p> */}
        </div>
      </div>
    </div>
  );
}

export default Explore;

const category = [
  {
    title: "Subject",
    sub_title: "Tutorials",
    id: 1,
    img: groupcat,
  },
  {
    title: "Test",
    sub_title: "Preparation",
    id: 1,
    img: groupcat,
  },
  {
    title: "Language",
    sub_title: "",
    id: 1,
    img: groupcat,
  },
];

const subjectsList = [
  {
    name: "Mathematics",
    id: 1,
    oimg: rect1,
    iimg: a1,
  },
  {
    name: "Physics",
    id: 2,
    oimg: rect2,
    iimg: a2,
  },
  {
    name: "Chemistry",
    id: 3,
    oimg: rect3,
    iimg: a3,
  },
  {
    name: "Biology",
    id: 4,
    oimg: rect4,
    iimg: a4,
  },
  {
    name: "English",
    id: 5,
    oimg: rect5,
    iimg: a5,
  },
  {
    name: "Hindi",
    id: 6,
    oimg: rect6,
    iimg: a6,
  },
  {
    name: "Mathematics",
    id: 7,
    oimg: rect7,
    iimg: a7,
  },
  {
    name: "Physics",
    id: 8,
    oimg: rect8,
    iimg: a8,
  },
  {
    name: "Chemistry",
    id: 9,
    oimg: rect9,
    iimg: a9,
  },
  {
    name: "Biology",
    id: 10,
    oimg: rect10,
    iimg: a10,
  },
  {
    name: "Chemistry",
    id: 11,
    oimg: rect11,
    iimg: a11,
  },
  {
    name: "Biology",
    id: 12,
    oimg: rect12,
    iimg: a12,
  },
];
