import React, { useEffect, useState, useContext, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import "./DialogBox.css";
import Login from "./Login";
import loginimg from "../../Assets/Goal/loginimg.png";
import manimg from "../../Assets/Goal/man.png";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AuthenticateLogin from "./AuthenticateLogin.js";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import EmailAuthentication from "./EmailAuthentication/EmailAuthentication";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  BackdropProps: {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    backgroundColor: "white",
    padding: "20px",
    zIndex: "99999999",
  },
});

export default function DialogBox({
  _open,
  _page = "homepage",
  _open_value = false,
  _callback,
  ...props
}) {
  const {
    loginData,
    updateLoginData,
    token,
    user,
    logout,
    showLogin,
    setShowLogin,
  } = useContext(UserCredsContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loginType, setLoginType] = useState("login");
  const [drawer, setDrawer] = React.useState(false);
  let history = useHistory();
  useEffect(() => {
    updateLoginData({
      code: "+91",
      phone: "9773526679",
      otp: "",
      type: "login",
    });
  }, []);
  useEffect(() => {
    setOpen(_open_value);
  }, [_open_value]);

  useEffect(() => {
  
    setDrawer(showLogin)
  }, [showLogin])

  console.log(showLogin, "showLoginshowLogin")

  const handleClickOpen = () => {
    if (token.access) {
      return;
    }
    setOpen(true);
  };

  const toggleDrawer = (open) => (event) => {
    console.log("hello");
    setDrawer(open);
  };
  const handleClose = () => {
    setOpen(false);
    setShowLogin(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleLogin = () => {
    // _callback(false)
    setDrawer(true)
    // toggleDrawer(false)
  }

  const handletoggle = () => {
    toggleDrawer(false);
    setShowLogin(false);
  }
  const redirectToProfiles = () => {
    history.push(`/dashboard/profile`);
  };
  const LoginDatas = () => {
    return (
      <div>
        <div>
          <div className="login-card-c-f">
            <div
              style={{ background: `url(${loginimg})`, overflow: 'hidden' }}
              className="card-login-c-l"
            >
              <img src={manimg} className="login-man-img" />
              {/* <img src={loginimg} style={{width:'100%'}}/> */}
            </div>
            <div>
              {loginData.type === "login" && <Login />}
              {loginData.type === "auth" && (
                <AuthenticateLogin handleClose={handleClose} />
              )}
              {loginData.type === "emailAuth" && <EmailAuthentication />}
            </div>
          </div>
        </div>
      </div>
    )
  }
  console.log(token, "token");
  return (
    <div>
      {_page == "homepage" && (
        <Fragment>
          <div className="login-nav-button" onClick={handleClickOpen}>
            {token.access ? (
              user.first_name ? (
                <Button aria-controls="simple-menu" aria-haspopup="true" style={{ color: 'rgb(191 191 191)' }} onClick={handleClick}>
                  {user.first_name}
                </Button>
              ) : (
                <p>User</p>
              )
            ) : (
              <p onClick={handleLogin}>Login</p>
            )}
          </div>
          {/* {token.access && <p onClick={logout}> logout</p>} */}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            {...props}
          >
            <MenuItem onClick={redirectToProfiles}>Profile</MenuItem>
            {/* <MenuItem onClick={handleCloseMenu}>My account</MenuItem>  */}
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Fragment>
      )}

      {window.innerWidth > 769 && <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        style={{ borderRadius: "50px" }}
      >
        {LoginDatas()}
      </Dialog>}


      {window.innerWidth < 768 && <SwipeableDrawer
        anchor={"bottom"}
        open={drawer}
        onClose={ handletoggle}
        onOpen={toggleDrawer(true)}
        classes={{ paper: classes.BackdropProps }}
        style={{ backgroundColor: "transparent" }}
      >
        {LoginDatas()}
      </SwipeableDrawer>}
    </div>
  );
}
