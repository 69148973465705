import React, { useContext, useEffect, useState } from "react";
import "./StartLearning.css";
import subjectIcon from "../../../Assets/Dashboard/mycourse/mathsubject.png";
import DefaultCourse from "../../../Assets/Contact/ic_default_course.png";
import videobg from "../../../Assets/Dashboard/videobg.png";
import { api_token } from "../../../Utils/Network";
import { Link } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import LearningNavbar from "./LearningNavbar";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import { calculateBar } from "../../../Utils/Utils";
import { Button } from "antd";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";

function StartLearning(props) {
  const { setSelectedCourse, selectedCourse, studentProfile } =
    useContext(UserCredsContext);
  const [hideGoal, sethideGoal] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState([]);
  const [mycourseData, setMycourseData] = useState([]);
  const [nonpurchased, setNonPurchased] = useState([]);
  const [purchased, setPurchased] = useState([]);
  const [pagination, setPagination] = useState({
    count: null,
    max_pages: null,
    next_page: null,
    next_page_link: null,
    page: null,
    previous_page: null,
    previous_page_link: null,
    total_count: null,
  });
  useEffect(() => {
    // getCourseData();
    getChapterVideos();
    getBoardGrade();
    // resumeVideo()
    if (props?.location?.state?.data) {
      sethideGoal(true);
      setGoalData(
        selectedCourse?.board?.id,
        selectedCourse?.grade?.id,
        selectedCourse?.language?.id
      );
    }
  }, []);

  useEffect(() => {
    if(props?.location?.state?.data) {
    sethideGoal(true);
    setGoalData(
      props?.location?.state?.board,
      props?.location?.state?.grade,
      props?.location?.state?.language,
    );
  }
  }, [
    props?.location?.state?.board,
    props?.location?.state?.grade,
    props?.location?.state?.language,
    props?.location?.state?.data,
  ]);

  // console.log(props?.location?.state, "LOcationProps");
  useEffect(() => {
    if (studentProfile.hasOwnProperty("grade")) {
      getCourseData(studentProfile.board.id, studentProfile.grade.id);
    }
  }, [studentProfile]);

  const getBoardGrade = () => {
    api_token
      .get("/cms/v1/user_purchased_grade_board")
      .then((res) => {
        console.log("res", res.data);
        setSelectedGoal(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setGoalData = (board, grade, language) => {
    api_token
      .patch(
        `auth/v1/student/account/${studentProfile.id}/update_user_detail/`,
        { languages_id: [language], board_id: board, grade_id: grade }
      )
      .then((res) => {
        sethideGoal(true);
        getMyCourse();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMyCourse = () => {
    api_token
      .get(`/cms/v2/my_courses`)
      .then((res) => {
        console.log("res", res.data);
        // setMycourseData(res.data.data);
        let { non_purchased_courses, purchased_courses } = res.data.data;
        setNonPurchased(non_purchased_courses);
        setPurchased(purchased_courses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCourseData = (_board, _grade) => {
    api_token(`/cms/v1/course/?is_combo=true&board=${_board}&grade=${_grade}`)
      .then((response) => {
        var combo1;
        var combo2;
        var comboArray = [];
        if (response.data.data.length != 0) {
          combo1 = response.data.data[0].combo;
          combo2 = response.data.data[1].combo;
          comboArray = [...combo1];
        }
        const {
          count,
          max_pages,
          next_page,
          next_page_link,
          page,
          previous_page,
          previous_page_link,
          total_count,
        } = response.data;
        setPagination({
          count,
          max_pages,
          next_page,
          next_page_link,
          page,
          previous_page,
          previous_page_link,
          total_count,
        });
        setSubjectList(comboArray);
        getVideo(subjectList[0].combo[0].item.id);
      })
      .catch((error) => {});
  };

  const getVideo = (_id) => {};

  const getChapterVideos = () => {
    api_token
      .get(`/cms/v1/chapter_videos_listing/?course=3492`)
      .then((response) => {
        console.log(response.data.data, "re");
      })
      .catch((error) => {});
  };

  const resumeVideo = () => {
    const data = {
      video: 44902,
      duration: 82983,
      title: "hello",
    };
    api_token
      .post(`/cms/v1/resume_video/`, data)
      .then((respones) => {})
      .catch((error) => {});
  };
  const redirectUrl = (_id, _item) => {
    if (_item.is_purchased && _item.is_test_series_pro) {
      if (window.location.href.indexOf("localhost") > -1) {
        window.location.href = "https://testseries.edunguru.com/my_profile";
      } else if (window.location.href.indexOf("edunguru2") > -1) {
        window.location.href = "https://testseries.edunguru.com/my_profile";
      } else if (
        window.location.href.indexOf("staging") > -1 ||
        window.location.href.indexOf("edunguru") > -1
      ) {
        window.location.href = "https://tests.edunguru.com/my_profile";
      }
    } else {
      setSelectedCourse(_item);
      props.history.push(
        `/dashboard/mycourse/${_id}/video/${_item.is_purchased}`
      );
    }
  };

  const AddGoal = () => {
    console.log("add goal");
    props.history.push(`/register/goal`);
  };
  console.log(props, selectedCourse, "PROPSLOCATION");
  console.log(purchased, "purchased", nonpurchased, "nonpurchased");
  return (
    <div className="start-container">
      <div className="start-learning-subjects">
        <LearningNavbar />
        <p className="start-learning-header">
          {hideGoal === true ? (
            <ArrowBackIosIcon
              onClick={() => sethideGoal(false)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <></>
          )}{" "}
          My Goal
        </p>
        {hideGoal === true ? (
          <div>
            {/* <Button onClick={() => sethideGoal(false)} className="mycourse_btn">Back</Button> */}
            <div style={{ margin: "0 26px" }}>
              <h3>Purchased Course</h3>
            </div>
            <div className="subject-card-fc setGoalContainer">
              {purchased &&
                purchased.map((item, index) => {
                  const {
                    video_count,
                    test_count,
                    notes_count,
                    user_notes_view_count,
                    user_test_view_count,
                    user_video_view_count,
                  } = item;
                  var total_viewed = calculateBar(
                    video_count + test_count + notes_count,
                    user_notes_view_count +
                      user_test_view_count +
                      user_video_view_count
                  );
                  // console.log("total_viewed", total_viewed);
                  return (
                    console.log(
                      isNaN(total_viewed),
                      total_viewed,
                      index,
                      "total_viewed"
                    ),
                    (
                      <div
                        className="subject-card setGoal_card"
                        key={index}
                        onClick={() => redirectUrl(item.id, item)}
                        // onClick={() => setGoalData(item?.board?.id, item?.grade?.id, item?.language?.id)}
                      >
                        <div className="subject-card-img">
                          {item.subject.length > 0 ? (
                            <img
                              src={item?.subject[0]?.icon}
                              alt="course"
                              className="setGoalimgs"
                              style={{ margin: "10px" }}
                            />
                          ) : (
                            <img
                              src={DefaultCourse}
                              alt="course"
                              className="setGoalimgs"
                            />
                          )}
                        </div>
                        <div className="subject-card-text">
                          <p className="subject-card-text-title sub_data_changes">
                            {item?.title}
                          </p>
                          {item.subject.length > 0 ? (
                            <p className="mr">{item?.subject[0]?.title}</p>
                          ) : (
                            <></>
                          )}
                          <p>{item.chapter_count} Chapters</p>
                          <div
                            className="mycourse-filler-c"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="mycourse-filler-inner-c"
                              style={{
                                background: "#5A5D9A",
                                // width: `${item.chapter_count}0%`,
                                width: `${
                                  isNaN(total_viewed) ? 0 : total_viewed
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>

            <div style={{ margin: "50px 26px 0" }}>
              <h3>Courses Available for you</h3>
            </div>
            <div className="subject-card-fc setGoalContainer">
              {nonpurchased &&
                nonpurchased.map((item, index) => {
                  return (
                    <div
                      className="subject-card setGoal_card"
                      key={index}
                      onClick={() => redirectUrl(item.id, item)}
                      // onClick={() => setGoalData(item?.board?.id, item?.grade?.id, item?.language?.id)}
                    >
                      <div className="subject-card-img">
                        {item.subject.length > 0 ? (
                          <img
                            src={item?.subject[0]?.icon}
                            alt="course"
                            className="setGoalimgs"
                            style={{ margin: "10px" }}
                          />
                        ) : (
                          <img
                            src={DefaultCourse}
                            alt="course"
                            className="setGoalimgs"
                          />
                        )}
                      </div>
                      <div className="subject-card-text">
                        <p className="subject-card-text-title sub_data_changes">
                          {item?.title}
                        </p>
                        {item.subject.length > 0 ? (
                          <p className="mr">{item?.subject[0]?.title}</p>
                        ) : (
                          <></>
                        )}
                        <p>{item.chapter_count} Chapters</p>
                        {/* <div className="mycourse-filler-c" style={{ width: "100%" }}>
                        <div
                          className="mycourse-filler-inner-c"
                          style={{
                            background: "#5A5D9A",
                            // width: `${item.chapter_count}0%`,
                            width: `${(isNaN(total_viewed)) ? 0 : total_viewed}%`,
                          }}
                        ></div>
                      </div> */}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <div className="subject-card-fc setGoalContainer">
            {selectedGoal.map((item, index) => {
              return (
                <div
                  className="subject-card setGoal_card"
                  key={index}
                  onClick={() =>
                    setGoalData(
                      item?.board?.id,
                      item?.grade?.id,
                      item?.language?.id
                    )
                  }
                >
                  <div className="subject-card-img">
                    <img
                      src={item.board.icon}
                      alt="subject"
                      className="setGoalimgs"
                    />
                  </div>
                  <div className="subject-card-text">
                    <p className="mr">{item?.board?.title}</p>
                    <p className="mr">{item?.grade?.title}</p>
                    <p style={{ marginTop: "5px" }}>{item?.language?.title}</p>
                  </div>
                </div>
              );
            })}

            <div className="Add_goal">
              {/* <Button className="btn_add" onClick={() => AddGoal()}>Add Goal</Button> */}
              <AddIcon onClick={() => AddGoal()} className="btn_add" />
              <p style={{ margin: "5px" }}>Set Goal</p>
            </div>
          </div>
        )}

        {/* <p className="start-learning-header">Start Learning</p>
        <div className="subject-card-fc">
          {subjectList.map((item, idx) => {
            const {
              video_count,
              test_count,
              notes_count,
              user_notes_view_count,
              user_test_view_count,
              user_video_view_count,
            } = item;
            var total_viewed = calculateBar(
              video_count + test_count + notes_count,
              user_notes_view_count +
              user_test_view_count +
              user_video_view_count
            );
            return (
              <React.Fragment>
                <div
                  className="subject-card-container"
                  onClick={() => redirectUrl(item.id, item)}
                >
                  <div className="subject-image-c">
                    <img src={item.subject[0].icon} />
                  </div>
                  <div className="subject-text-progress">
                    <p>{item.subject[0].title} </p>
                    <p>{item.chapter_count} Chapters</p>
                    <div className="mycourse-filler-c" style={{ width: "90%" }}>
                      <div
                        className="mycourse-filler-inner-c"
                        style={{
                          background: "#5A5D9A",
                          // width: `${item.chapter_count}0%`,
                          width: `${total_viewed}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div> */}
        {/* <div className="resume-learning-container">
          <div className="play-video-screen-resume">
            <p className="start-learning-header">Resume Learning</p>
            <img src={videobg} />
            <p>Surface Tension in Action</p>
          </div>
          <div className="rest-video-f-c">
            <div className="rest-video-cc">
              <img src={videobg} />
              <p>Surface tension</p>
            </div>
            <div className="rest-video-cc">
              <img src={videobg} />
              <p>Surface tension</p>
            </div>
            <div className="rest-video-cc">
              <img src={videobg} />
              <p>Surface tension</p>
            </div>
            <div className="rest-video-cc">
              <img src={videobg} />
              <p>Surface tension</p>
            </div>
            <div className="rest-video-cc">
              <img src={videobg} />
              <p>Surface tension</p>
            </div>
            <div className="rest-video-cc">
              <img src={videobg} />
              <p>Surface tension</p>
            </div>
          </div>
        </div> */}
        {/* <div className="Learning-progress-card-start-f">
          <div className="Learning-progress-card-start">
            <p
              className="start-learning-header"
              style={{ marginBottom: "5px" }}
            >
              Learning Progress
            </p>
            <p className="start-learning-sectext">Based On Your Performance</p>
            <div className="filler-list-c">
              <span>Mathematics</span>
              <div className="mycourse-filler-c" style={{ width: "90%" }}>
                <div
                  className="mycourse-filler-inner-c"
                  style={{ background: "#5A5D9A", width: "20%" }}
                ></div>
              </div>
              <span>2/10</span>
            </div>
            <div className="filler-list-c">
              <span>Physics</span>
              <div className="mycourse-filler-c" style={{ width: "90%" }}>
                <div
                  className="mycourse-filler-inner-c"
                  style={{ background: "#5A5D9A", width: "70%" }}
                ></div>
              </div>
              <span>7/10</span>
            </div>
            <div className="filler-list-c">
              <span>Biology</span>
              <div className="mycourse-filler-c" style={{ width: "90%" }}>
                <div
                  className="mycourse-filler-inner-c"
                  style={{ background: "#5A5D9A", width: "40%" }}
                ></div>
              </div>
              <span>4/10</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default withRouter(StartLearning);
