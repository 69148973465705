import React, { useEffect, useState, useRef } from "react";
// import "./VideoPlayer.css";
// import "./vPlayer.css";

function VideoPlayerN({ _data, ...props }) {
  const [videoKey, setVideoKey] = useState(1);
  const reference = useRef(null);
  console.log(_data, "_data");
  useEffect(() => {
    // var player =  window.videojs;
    var video_url = "";
    if (_data !== undefined && _data.hasOwnProperty("id")) {
      video_url = _data.video_listing[0].video_link.files[0].link;
    }
    var videojs = window.videojs;
    var player = videojs("player", {
      plugins: {
        videoJsResolutionSwitcher: {
          default: "high",
          dynamicLabel: true,
        },
      },
    });
    // player.updateSrc([
    //   {
    //     src: video_url,
    //     type: "video/mp4",
    //     res: 720,
    //     label: "HD",
    //   },
    // ]);
    player.on("resolutionchange", function () {
      console.info("Source changed to %s", player.src());
    });
    player.currentTime(10);
    // videojs.addClass('staticLabel', "vjs-resolution-button-staticlabel");
    // videojs.addClass('staticLabel', "vjs-icon-cog");
  }, [_data]);
  const checkVideo = (e) => {
    // console.log(e.target, "event ");
    // document.getElementsByClassName(
    //   "vjs-resolution-button-label"
    // )[0].textContent = "Resolution";
  };
  useEffect(() => {
    console.log(
      document.getElementsByClassName("vjs-resolution-button-label"),
      "player"
    );

    if (reference !== null) {
    }
  }, [reference]);
  return (
    <div>
      <div className="video-player-container-c">
        <video
          id="player"
          className="video-js vjs-default-skin vjs-big-play-centered"
          controls
          preload="none"
          autoplay
          loop
          muted
          style={{ width: "100%" }}
          height="360"
          ref={reference}
          poster=""
          key={videoKey}
          onClick={checkVideo}
        ></video>
      </div>
    </div>
  );
}

export default VideoPlayerN;
