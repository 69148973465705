import React, { useEffect, useState } from "react";
import { api_token } from "../../../Utils/Network";

function VideoPlayer() {
  const [lang, setLang] = useState([]);
  useEffect(() => {
    player();
  }, []);
  const player = () => {
    api_token
      .get(`/base/v1/language`)
      .then((response) => {
        setLang(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      {lang.map((item, idx) => {
        return <div></div>;
      })}
    </div>
  );
}

export default VideoPlayer;
