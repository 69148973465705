import React, { useContext, useEffect, useState } from "react";
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import overlayImage from "../../../Assets/Dashboard/Explore/overlayimage.png";
import "./Profile.css";
import EditIcon from "@material-ui/icons/Edit";
import profileImg from "../../../Assets/Profile/user.png";
import editicon from "../../../Assets/Profile/editicon.png";
import { api_token, base_url, formDataApi } from "../../../Utils/Network";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Select, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "45%",
    ["@media (max-width: 768px)"]: {
      width: "95%",
      margin: "10px auto",
    },
    marginTop: "20px",
  },
}));

function Profile() {
  const { user, studentProfile, setUser, setStudentProfile, token, logout } = useContext(UserCredsContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [disType, setDisType] = useState(null);
  const handleClickOpen = (open, x) => {
    setOpen(open);
    setDisType(x);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [profileData, setProfileData] = useState({});
  const [academicsData, setAcademicsData] = useState({
    grade: [],
    board: [],
    language: [],
  });
  const [personalDetails, setPersonalDetails] = useState({
    id: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    dob: "",
    avatar: "",
  });
  const [address, setAddress] = useState({});
  const [guardian, setGuardian] = useState({});
  const [filterId, setFilterId] = useState({
    boardId: null,
    gradeId: null,
    subjectId: null,
    languageId: null,
  });
  const [grade, setGrade] = useState([]);

  const [board, setBoard] = useState([]);
  const [language, setLanguage] = useState([]);
  const [indofBoard, setfilterBoard] = useState(null);
  const [indofgrade, setfilterGrade] = useState(null);
  const [indoflang, setfilterLang] = useState(null);

  useEffect(() => {
    getProfileData();
    // getGrade();
    getBoard();
    getLanguage();
  }, []);

  useEffect(() => {
    setfilterBoard(board.findIndex((el) => el.id === filterId.boardId));
    setfilterGrade(grade.findIndex((el) => el.id === filterId.gradeId));
    setfilterLang(language.findIndex((el) => el.id === filterId.languageId));
  },[filterId])

  console.log(filterId, "fffffffffff")

  // useEffect(() => {
  //   if (grade.length !== 0 && academicsData.grade && !academicsData.subject) {
  //     let index = grade.findIndex((el) => el.id == academicsData.grade);
  //     console.log(index, grade, academicsData.grade, "index in home");
  //     setFilterId({ ...filterId, gradeId: index });
  //     setAcademicsData({ ...academicsData, subject: [index] });
  //   }
  // }, [board, academicsData]);

  const getBoard = () => {
    api_token
      .get(`/base/v1/board`)
      .then((response) => {
        setBoard(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getGrade = (val) => {
    api_token
      .get(`/base/v1/board-grade-detail?board=${val}`)
      .then((response) => {
        setGrade(response.data.data[0]?.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getLanguage = () => {
    api_token
      .get(`/base/v1/language`)
      .then((response) => {
        setLanguage(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setPersonalDetails({ ...personalDetails, [name]: value });
  };

  const SubmitDatas = () => {
    let lang;
    if(academicsData.language?.id){
      lang = [academicsData.language.id]
    }else{
      lang = [];
    }
    let dats = {
      first_name: personalDetails.first_name,
      last_name: personalDetails.last_name,
      full_name: personalDetails.first_name + personalDetails.last_name,
      email: personalDetails.email,
      phone: personalDetails.phone,
      grade_id: academicsData.grade?.id,
      board_id: academicsData.board?.id,
      languages_id: lang,
      dob: personalDetails.dob,
    };
    console.log(dats, "datas")
    api_token
      .patch(`auth/v1/student/account/${profileData.id}/update_user_detail/`, dats)
      .then((response) => {
        console.log(response, "response")
        if(response?.data?.error){
            alert("Please Update all field by updating language first")
            handleClose();
        }else{
          console.log(response.data.data, "dd")
          const { id, first_name, last_name, phone, email } = response.data.data;
          const { grade, board, language:languageObject, dob } = response.data.data.studentprofile;
          
          setUser({ ...user, id, email, first_name, last_name, phone });
          let language = languageObject[0];
          setStudentProfile({
            id,
            grade,
            board,
            language,
            dob,
          })
          setFilterId({
            boardId: board?.id,
            gradeId: grade?.id,
            languageId: language?.id,
          })
          handleClose();
        }
       
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getProfileData = () => {
    api_token
      .get(`/auth/v1/student/account/me/`)
      .then((response) => {
        setProfileData(response.data.data);
        const {
          board,
          grade,
          language:languageObject,
          dob
        } = response.data.data.student_profile;
        let language = languageObject[0];
        const { id, phone, email, avatar, first_name, last_name, percentage } = response.data.data;
        setUser({...user, id,phone,email,avatar,first_name,last_name})
        setStudentProfile({
          ...studentProfile,
          grade: grade,
          board: board,
          language: language,
          dob: dob,
        })
        setPersonalDetails({
          id,
          first_name,
          last_name,
          phone,
          email,
          dob,
          avatar,
          percentage,
        });
        setAcademicsData({
          grade: grade,
          board: board,
          language: language,
          dob: dob,
        });
        setFilterId({
          boardId: board?.id,
          gradeId: grade?.id,
          languageId: language?.id,
        })
        
      })
      .catch((error) => { });
  };
  console.log(personalDetails, "prDetails")
  const handleChangeGrade = (event, values) => {
    console.log(values, "values");
    setAcademicsData({ ...academicsData, grade: values });
  };

  const handleChangeBoard = (event, values) => {
    console.log(values, "values");
    setAcademicsData({ ...academicsData, board: values });
    getGrade(values?.id);
  };

  const handleChangeLanguage = (event, values) => {
    console.log(values, "values");
    setAcademicsData({ ...academicsData, language: values });
  };

  const DeleteAccount = () => {
    api_token
    .delete(`auth/v1/student/account/${user.id}/deactivate_student/`)
    .then((res) => {
      logout()
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleChangeSubject = async (event, values) => {
    let formData = new FormData();
    let apiEnd = `${user.id}/set_avatar/`;
    formData.append("image", event.target.files[0]);
    try{
      await axios({
        method: "POST",
        url: `${base_url}/auth/v1/student/account/` + apiEnd,
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
        headers: { 'Authorization': `Bearer ${token.access}` },
    })
        .then(response => {
            // setLoading(false)
            console.log(response.data, 'response for this data');
            setPersonalDetails({...personalDetails, avatar: response.data.data.image})
        })
        .catch(error => {
            console.log(error, 'error');  
        })
    } catch (error) {
      console.log(error);
    }
  }
  
  const getAcademics = () => {
    return (
      <React.Fragment>
        <Autocomplete
          value={board[indofBoard]}
          id="Board"
          options={board}
          getOptionLabel={(option) => option.title}
          onChange={handleChangeBoard}
          name="Board"
          className={classes.textField}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Board"
              variant="outlined"
              size="small"
              
            />
          )}
        />

        <Autocomplete
          value={grade[indofgrade]}
          id="Grade"
          options={grade}
          getOptionLabel={(option) => option.title}
          onChange={handleChangeGrade}
          name="grade"
          className={classes.textField}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Grade"
              variant="outlined"
              size="small"
              value={profileData?.grade?.id}
            />
          )}
        />
        <Autocomplete
          value={language[indoflang]}
          id="Language"
          options={language}
          getOptionLabel={(option) => option.title}
          onChange={handleChangeLanguage}
          name="Language"
          className={classes.textField}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Language"
              variant="outlined"
              size="small"
              value={studentProfile?.language?.id}
            />
          )}
        />
      </React.Fragment>
    );
  };

  const getPersonalInfo = () => {
    return (
      <React.Fragment>
        <TextField
          required
          id="standard-required"
          label="First Name"
          name="first_name"
          onChange={handleChangeData}
          value={personalDetails.first_name}
          className={classes.textField}
          variant="outlined"
        />
        <TextField
          required
          id="standard-required"
          label="Last Name"
          name="last_name"
          onChange={handleChangeData}
          value={personalDetails.last_name}
          className={classes.textField}
          variant="outlined"
        />
        <TextField
          required
          id="standard-required"
          label="Email"
          name="email"
          onChange={handleChangeData}
          value={personalDetails.email}
          className={classes.textField}
          variant="outlined"
          disabled={true}
        />
        <TextField
          required
          id="standard-required"
          label="Phone"
          name="phone"
          onChange={handleChangeData}
          className={classes.textField}
          value={personalDetails.phone}
          variant="outlined"
          type="number"
          disabled={true}
        />
        <TextField
          id="date"
          label="Birthday"
          type="date"
          name="dob"
          onChange={handleChangeData}
          defaultValue="2017-05-24"
          className={classes.textField}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={personalDetails.dob}
        />
      </React.Fragment>
    );
  };

  console.log(user, "user")
  // console.log(academicsData, "academicsData")
  // console.log(studentProfile, "studentProfile")
  console.log(personalDetails, "personalDetails")
  return (
    <div>
      <div className="profile-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        <img src={overlayImage} className="rect-explore-overlay-c" />

        <div className="profile-hero-text-c">
          <div>
            <div className="profile-image-c">
              {!personalDetails.avatar && (
                <img src={profileImg} className="profile-image-tag" />
              )}
              {personalDetails.avatar && (
                <img
                  src={personalDetails.avatar}
                  className="profile-image-tag"
                />
              )}
              <div className="edit-container-p">
                <div className="upload-btn-wrapper">
                  <img src={editicon} />
                  <input type="file" name="myfile" accept="image/*" onChange={handleChangeSubject} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="profile-name-c-npf">
              {user.first_name} {user.last_name}
              {/* {personalDetails.full_name} */}
            </p>
            <div className="mycourse-filler-c">
              <div
                className="mycourse-filler-inner-c"
                style={{ background: "#5A5D9A", width: `${personalDetails?.percentage}%` }}
              ></div>
            </div>
            <p className="percent-completed-profile">{personalDetails?.percentage}% Complete</p>
          </div>
        </div>
      </div>
      <div className="profile-section-details-c">
        <div className="profile_update">
          {/* {window.innerWidth > 769 && ( */}
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              style={{ width: "600px", margin: "0 auto" }}
            >
              <DialogTitle id="alert-dialog-slide-title">
                Edit Details
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <div className="user-profile-if-c">
                    {disType === 2 && getPersonalInfo()}
                    {disType === 1 && getAcademics()}
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  cancel
                </Button>
                <Button onClick={SubmitDatas} color="primary">
                  update
                </Button>
              </DialogActions>
            </Dialog>
          {/* )} */}
        </div>

        {/* {window.innerWidth < 768 && <div className="profile_drawer">
          <SwipeableDrawer
            anchor={"bottom"}
            open={open}
            onClose={setState(false)}
            onOpen={setState(true)}
            classes={{ paper: classes.BackdropProps }}
            style={{ backgroundColor: "transparent" }}
          >
            {getPersonalInfo()}
            {getAcademics()}
          </SwipeableDrawer>
        </div>} */}

<div>
  <Button variant="outlined" color="secondary" onClick={() => DeleteAccount()} style={{marginBottom: '20px'}}>Delete Account</Button>
</div>
        <div className="profile-section-f-c">
          <div className="profile-card-c">
            <div className="acad-head-c">
              <h3>Academics</h3>
              <div style={{ textAlign: "right" }}>
                <img
                  src={editicon}
                  className="profile-edit0icon"
                  onClick={() => handleClickOpen(true, 1)}
                />
              </div>
            </div>
            <div className="acad-body-c">
              <div className="acad-body-item">
                <p>Grade</p>
                <p>{studentProfile?.grade?.title}</p>
              </div>
              <div className="acad-body-item">
                <p>Board</p>
                <p>{studentProfile?.board?.title}</p>
              </div>
              <div className="acad-body-item">
                <p>Language</p>
                <p>{studentProfile?.language?.title}</p>
              </div>
            </div>
          </div>
          <div className="profile-card-c">
            <div className="acad-head-c">
              <h3>Personal Details</h3>
              <div style={{ textAlign: "right" }}>
                <img
                  src={editicon}
                  className="profile-edit0icon"
                  onClick={() => handleClickOpen(true, 2)}
                />
              </div>
            </div>
            <div className="acad-body-c">
              <div className="acad-body-item">
                <p>Full Name</p>
                <p style={{ textTransform: "capitalize" }}>
                  {user?.first_name} {user?.last_name}
                </p>
              </div>
              <div className="acad-body-item">
                <p>Phone</p>
                <p>{user?.phone}</p>
              </div>
              <div className="acad-body-item">
                <p>Date of birth</p>
                <p>{studentProfile?.dob}</p>
              </div>
              <div className="acad-body-item">
                <p>Email</p>
                <p className="acad_email">{user?.email}</p>
              </div>
            </div>
          </div>
          {/* <div className="profile-card-c">
            <div className="acad-head-c">
              <h3>Personal Details</h3>
              <div style={{ textAlign: "right" }}>
                <img
                  src={editicon}
                  className="profile-edit0icon"
                  onClick={handleClickOpen}
                />
              </div>
            </div>
            <div className="acad-body-c">
              <div className="acad-body-item">
                <p>Full Name</p>
                <p>lorem</p>
              </div>
              <div className="acad-body-item">
                <p>Phone</p>
                <p>lorem</p>
              </div>
              <div className="acad-body-item">
                <p>Date of birth</p>
                <p>lorem</p>
              </div>
              <div className="acad-body-item">
                <p>Email</p>
                <p>lorem</p>
              </div>
            </div>
          </div> */}
          {/* <div className="profile-card-c">
            <div className="acad-head-c">
              <h3>Address</h3>
              <div style={{ textAlign: "right" }}>
                <img
                  src={editicon}
                  className="profile-edit0icon"
                  onClick={handleClickOpen}
                />
              </div>
            </div>
            <div className="acad-body-c">
              <div className="acad-body-item">
                <p>Full Name</p>
                <p>lorem</p>
              </div>
              <div className="acad-body-item">
                <p>Phone</p>
                <p>lorem</p>
              </div>
              <div className="acad-body-item">
                <p>Date of birth</p>
                <p>lorem</p>
              </div>
              <div className="acad-body-item">
                <p>Email</p>
                <p>lorem</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Profile;
