import React, { Fragment, useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import dlogo from "../../Assets/Dashboard/dlogo.png";
import arrow from "../../Assets/Dashboard/arrow.png";
import mycourseicon from "../../Assets/Dashboard/mycoursehat.png";
import exploreicon from "../../Assets/Dashboard/exploreicon.png";
import { Switch, Route, Redirect, withRouter, Link } from "react-router-dom";
import menuactive from "../../Assets/Dashboard/menuactive.png";
import Explore from "../../Panel/Dashboard/Explore/Explore";
import MyCourse from "../../Panel/Dashboard/MyCourse/MyCourse";
// import Live from "../../Panel/Dashboard/Live/Live";
// import Analysis from "../../Panel/Dashboard/Analysis/Analysis";
import ExploreCourses from "../../Panel/Dashboard/ExploreCourses/ExploreCourses";
import TeacherProfile from "../../Panel/Dashboard/TeacherProfile/TeacherProfle";
import PageNotFound from "../PageNotFound/PageNotFound";
import { api_token } from "../../Utils/Network";
import Profile from "../../Panel/Dashboard/Profile/Profile.js";
import StartLearning from "../../Panel/Dashboard/StartLearning/StartLearning";
import Professor from "../../Panel/Dashboard/Professor/Professor";
import Video from "../../Panel/Dashboard/Video/Video";
import VideoPlayer from "../../Panel/Dashboard/VideoPlayer/VideoPlayer";
import CourseDetails from "../../Panel/Dashboard/CourseDetails/CourseDetails";
import Billing from "../../Panel/Dashboard/Billing/Billing";
import PlayVideo from "../../Panel/Dashboard/PlayVideo/PlayVideo";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import ChartDemo from "../../Panel/Dashboard/ChartDemo/ChartDemo";
import DialogBox from "../../Components/DialogBox/DialogBox";
import TotalOrder from "../../Panel/Dashboard/TotalOrder/TotalOrder";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import avatar from "../../Assets/Navbar/avatar.png";
import Button from "@material-ui/core/Button";
import VimeoPlayer from "../../Components/Vimeo/VimeoPlayer";
import Success from "../Success";
import Failure from "../Failure";
import Testimonials from "../../Panel/Dashboard/Testimonials/Testimonials";
import DemoVideo from "../../Panel/Dashboard/DemoVideo/DemoVideo";
import subs from "../../Assets/Dashboard/subscription90X90.png";
import doubt from "../../Assets/Dashboard/doubts90X90.png";
import lived from "../../Assets/Dashboard/liveclass90X90.png";
import testicon from "../../Assets/Dashboard/testimonial90X90.png";
import expicon from "../../Assets/Dashboard/explore90X90.png";
// import testsicon "../../Assets/Dashboard/testimonial90X90.png";
import courseicon from "../../Assets/Dashboard/cap90X90.png";
import satelliteImg from "../../Assets/Home/satellite.png";
import overlayImage from "../../Assets/Dashboard/Explore/overlayimage.png";
import SahajResponse from "../../Panel/Dashboard/SahajResponse/SahajResponse";
import SahajVerifyUser from "../../Panel/Dashboard/SahajVerifyUser/SahajVerifyUser";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import AnalysisMain from "../../Panel/Dashboard/AnalysisMAin/AnalysisMain";
import Livemain from "../../Panel/Dashboard/LiveMain/Livemain";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
function Dashboard(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [active, setActive] = useState(0);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    var location = props.location.pathname;
    if (location.includes("explore")) {
      setActive(1);
    }
    if (location.includes("start") || location.includes("mycourse")) {
      setActive(2);
    }
    if (location.includes("live")) {
      setActive(3);
    }
    if (location.includes("analysis")) {
      setActive(4);
    }
    if (location.includes("billing")) {
      setActive(5);
    }
    if (location.includes("testimonials")) {
      setActive(6);
    }
    if (location.includes("demo")) {
      setActive(7);
    }
  }, [props.location]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [currentView, setCurrentView] = useState("desktop");
  const { user, showLogin, logout, setShowLogin } =
    useContext(UserCredsContext);
  console.log(user, "user from dash");
  const changeView = () => {
    const _status = currentView === "desktop" ? "mobile" : "desktop";
    setCurrentView(_status);
  };
  const [homeData, setHomeData] = useState([]);
  useEffect(() => {
    api_token
      .get(`/base/v1/homescreen`)
      .then((response) => {
        setHomeData(response.data.data);
      })
      .catch((error) => {});
  }, []);
  const checkLogin = (url) => {
    console.log(user, "userURL");
    if (url !== "explore" && url !== "demo") {
      if (user.hasOwnProperty("is_active") && user.is_active) {
        props.history.push(`${props.match.url}/${url}`);
      } else {
        setShowLogin(true);
      }
    } else if (url === "explore" || url === "demo") {
      props.history.push(`${props.match.url}/${url}`);
    }
  };
  console.log(user, "user");

  const backToHome = () => {
    props.history.push("/home");
  };
  const redirectToProfile = () => {
    props.history.push(`/dashboard/profile`);
  };

  console.log(props, "ppp");
  return (
    <div>
      <nav
        className={`${
          currentView === "desktop" ? "navbar-desktop" : "navbar-mobile"
        }`}
        style={{ background: "#3E4289" }}
      >
        {/* explore
my courses
live classes
doubt solving
my subscriptions
testimonials
my bookmarks */}
        <ul className="navbar-nav" style={{ position: "relative" }}>
          <li className="logo">
            <div className="nav-link">
              <div className="link-text logo-text">
                <div className="dashboard-logo-img" onClick={backToHome}>
                  <img src={dlogo} style={{ cursor: "pointer" }} />
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item" style={{ marginTop: "10px" }}>
            <div onClick={() => checkLogin("explore")}>
              <div
                className={`nav-link ${active == 1 ? "setactive-nav-cb" : ""}`}
              >
                <img src={expicon} />
                <span
                  className="link-text"
                  style={{ fontWeight: `${active == 1 ? "900" : "400"}` }}
                >
                  Explore
                </span>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={() => checkLogin("start")}>
              <div
                className={`nav-link ${active == 2 ? "setactive-nav-cb" : ""}`}
              >
                <img src={courseicon} />
                <span
                  className="link-text"
                  style={{ fontWeight: `${active == 2 ? "900" : "400"}` }}
                >
                  My Courses
                </span>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={() => checkLogin("live")}>
              <div
                className={`nav-link ${active == 3 ? "setactive-nav-cb" : ""}`}
              >
                <img src={lived} />
                <span
                  className="link-text"
                  style={{ fontWeight: `${active == 3 ? "900" : "400"}` }}
                >
                  Live
                </span>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={() => checkLogin("analysis")}>
              <div
                className={`nav-link ${active == 4 ? "setactive-nav-cb" : ""}`}
              >
                <img src={doubt} />
                <span
                  className="link-text"
                  style={{ fontWeight: `${active == 4 ? "900" : "400"}` }}
                >
                  Doubt Solving
                </span>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={() => checkLogin("billing")}>
              <div
                className={`nav-link ${active == 5 ? "setactive-nav-cb" : ""}`}
              >
                <img src={subs} />
                <span
                  className="link-text"
                  style={{ fontWeight: `${active == 5 ? "900" : "400"}` }}
                >
                  My Subscriptions
                </span>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={() => checkLogin("testimonials")}>
              <div
                className={`nav-link ${active == 6 ? "setactive-nav-cb" : ""}`}
              >
                <img src={testicon} />
                <span
                  className="link-text"
                  style={{ fontWeight: `${active == 6 ? "900" : "400"}` }}
                >
                  Testimonials
                </span>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <div onClick={() => checkLogin("demo")}>
              <div
                className={`nav-link ${active == 7 ? "setactive-nav-cb" : ""}`}
              >
                <img src={courseicon} />
                <span
                  className="link-text"
                  style={{ fontWeight: `${active == 7 ? "900" : "400"}` }}
                >
                  Demo Video
                </span>
              </div>
            </div>
          </li>
          <li></li>
          <li className="nav-item">
            <div className="need_help">
              <p className="need_hp_content">Need help?</p>
              <div className="data_nhp_container">
                {" "}
                <EmailOutlinedIcon />{" "}
                <p className="need_hp_content">customercare@edunguru.com</p>
              </div>
              <div className="data_nhp_container">
                {" "}
                <CallOutlinedIcon />
                <p className="need_hp_content">18008912868</p>
              </div>
            </div>
            <div className="footer-logout-dba">
              {user.hasOwnProperty("first_name") && (
                <div className="fldba-fc" style={{ color: "white" }}>
                  <Avatar
                    alt="Remy Sharp"
                    style={{ marginTop: "5px" }}
                    onClick={handleClick}
                  >
                    <span className="first-letter-i">
                      <img src={avatar} style={{ width: "100%" }} />
                    </span>
                  </Avatar>
                  <p
                    className="logoText"
                    style={{ textTransform: "capitalize", cursor: "pointer" }}
                    onClick={handleClick}
                  >
                    &nbsp; {user.first_name} {user.last_name}
                  </p>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    style={{ width: "100%" }}
                  >
                    <Typography
                      className={classes.typography}
                      onClick={redirectToProfile}
                      style={{ cursor: "pointer" }}
                    >
                      Profile
                    </Typography>
                    <Typography
                      className={classes.typography}
                      onClick={logout}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </Typography>
                  </Popover>
                </div>
              )}
            </div>
          </li>
          <div className="float-image-d-b">
            {/* <img src={overlayImage} style={{height:'100px'}}/> */}
          </div>
          {/* <div className="arrow-dashboard-btn" onClick={changeView}>
            <img src={arrow} />
          </div> */}
        </ul>
      </nav>

      <main
        className={`${
          currentView === "desktop" ? "main-desktop" : "main-mobile"
        }`}
      >
        <DialogBox _page="dashboard" _open_value={showLogin} />

        <Switch>
          <Redirect from="/dashboard" to="/dashboard/explore" exact strict />
          <Redirect from="/dashboard/" to="/dashboard/explore" exact strict />
          <Route
            strict
            exact
            path={`${props.match.url}/explore`}
            render={(_props) => <Explore {..._props} _data={homeData} />}
          />
          <Route
            path={`${props.match.url}/mycourse/:id`}
            render={(_props) => <MyCourse {..._props} _data="" />}
          />
          <Route
            strict
            // exact
            path={`${props.match.url}/live`}
            render={(_props) => <Livemain {..._props} _data="" />}
          />
          {/* <Route
            strict
            exact
            path={`${props.match.url}/analysis`}
            render={(_props) => <Analysis {..._props} _data="" />}
          /> */}
          <Route
            strict
            exact
            path={`${props.match.url}/testimonials`}
            render={(_props) => <Testimonials />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/demo`}
            render={(_props) => <DemoVideo />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/explore-courses/:id`}
            render={(_props) => <ExploreCourses {..._props} _data="" />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/teacher-profile/:id`}
            render={(_props) => <TeacherProfile {..._props} _data="" />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/profile`}
            render={(_props) => <Profile {..._props} _data="" />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/start`}
            render={(_props) => <StartLearning {..._props} _data="" />}
          />
          {/* <Route
            strict
            exact
            path={`${props.match.url}/analysis`}
            render={(_props) => <Analysis {..._props} _data="" />}
          /> */}
          <Route
            strict
            path={`${props.match.url}/analysis`}
            render={(_props) => <AnalysisMain />}
          />

          <Route
            strict
            exact
            path={`${props.match.url}/video-player`}
            render={(_props) => <VideoPlayer {..._props} _data="" />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/course-details/:id`}
            render={(_props) => <CourseDetails {..._props} _data="" />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/billing`}
            render={(_props) => <Billing {..._props} _data="" />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/course/video/:id`}
            render={(...props) => <PlayVideo {...props} _data="" />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/chart`}
            render={(...props) => <ChartDemo />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/totalorder/:id/:boolean`}
            render={(...props) => <TotalOrder />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/vimeo`}
            render={(...props) => <VimeoPlayer />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/success`}
            render={(...props) => <Success />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/failure`}
            render={(...props) => <Failure />}
          />
          <Route
            strict
            exact
            path={`${props.match.url}/sahaj-payment-response/:id`}
            render={(...props) => <SahajResponse />}
          ></Route>
          <Route
            strict
            exact
            path={`${props.match.url}/sahaj-user-verify/:phone`}
            render={(...props) => <SahajVerifyUser />}
          ></Route>
          <Route component={PageNotFound} />
        </Switch>
      </main>
    </div>
  );
}

export default withRouter(Dashboard);

export const ProtectedRoutes = ({ component: CFomponent, ...rest }) => {
  const { token } = useContext(UserCredsContext);
  return (
    <Fragment>
      <Route
        {...rest}
        render={(props) => {
          return token.access ? (
            <CFomponent {...props} />
          ) : (
            <Redirect to="/dashboard" />
          );
        }}
      />
    </Fragment>
  );
};
