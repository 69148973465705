import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withRouter } from "react-router-dom";
import { api_token } from "../../Utils/Network";
import { calculateBar } from "../../Utils/Utils";
import "./ComboPopUp.css";
import insticap from "../../Assets/Navbar/insticap.png";

function ComboPopUp({ _open, _handleOpen, _id, ...props }) {
  const [open, setOpen] = React.useState(false);
  const [courseData, setCourseData] = useState(null);
  console.log(_id, "_id_id");
  useEffect(() => {
    if (_open) {
      setOpen(true);
      if (courseData.is_combo === false) {
        props.history.push(`/dashboard/mycourse/${courseData.id}/video/true`)
      }
    }
  }, [_open]);

  const handleClose = () => {
    setOpen(false);
    _handleOpen(false);
  };

  useEffect(() => {
    if (_id != null) {
      getData(_id);
    }
  }, [_id]);

  const getData = (paramId) => {
    api_token
      .get(`/cms/v1/course/${paramId}`)
      .then((response) => {
        setCourseData(response.data.data);
        console.log(response, 'responseresponse')
      })
      .catch((error) => { });
  };
  const myCourse = (courseitem) => {
    props.history.push(`/dashboard/mycourse/${courseitem.id}/video/true`)
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            
            {courseData != null && (
              <h4 style={{ color: "black" }}>{courseData.title}hello</h4>
            )}
            <div className="combo-listing-c">
              {courseData !== null &&
                courseData.combo.map((item, id) => {
                  const {
                    video_count,
                    test_count,
                    notes_count,
                    user_notes_view_count,
                    user_test_view_count,
                    user_video_view_count,
                  } = item;
                  var total_viewed = calculateBar(
                    video_count + test_count + notes_count,
                    user_notes_view_count +
                    user_test_view_count +
                    user_video_view_count
                  );
                  return (
                    <div
                      className="show-combo-listing-tabi"
                      onClick={() => myCourse(item)}
                    >
                      <div style={{ width: "10%" }}>
                        <img src={insticap} style={{ width: "80%" }} />
                      </div>
                      <div style={{ width: "90%" }}>
                        <p style={{ marginTop: "0px" }}>{item.title}</p>
                        <div
                          className="mycourse-filler-c"
                          style={{ width: "70%" }}
                        >
                          <div
                            className="mycourse-filler-inner-c"
                            style={{
                              background: "#5A5D9A",
                              // width: `${item.chapter_count}0%`,
                              width: `${total_viewed}%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default withRouter(ComboPopUp);
