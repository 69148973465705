import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#00851b", "#bfbaba"];

export default class PieLarge extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      counter: 0, data:[], loading:false,
    };
  }
  
  static getDerivedStateFromProps(props, state) {
    var myData = [
      { name: "Correct", value: props._type.correct },
      { name: "Incorrect", value: props._type.wrong },
    ];
    return {data:myData}
  }

  render() {
    console.log(this.props._type, "PPPP");
    return (
      <PieChart width={460} height={500} onMouseEnter={this.onPieEnter} className="Pie_demo">
        <Pie
          data={this.state.data}
          cx={220}
          cy={240}
          innerRadius={175}
          outerRadius={200}
          fill="#8884d8"
          paddingAngle={-2}
          dataKey="value"
          display="block"
        >
          {this.state.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  }
}
