import React, { useContext, useEffect } from 'react';
import { api_open, setEdunguruApi } from "../../../Utils/Network";
import {
    useParams, withRouter
} from "react-router-dom/cjs/react-router-dom.min";
import { UserCredsContext } from '../../../ContextApi/UserCredsContext/UserCredsContext';

function SahajVerifyUser({ ...props }) {
    // var url = '';
    // if (window.location.href.indexOf("localhost") > -1) {
    //   url = " https://api-edunguru2.g10consultancy.com/";
    //   return url
    // } else if (window.location.href.indexOf("edunguru2") > -1) {
    //   url = " https://api-edunguru2.g10consultancy.com/";
    //   return url
  
    // } else if ((window.location.href.indexOf("staging") > -1) || (window.location.href.indexOf("edunguru") > -1)) {
    //   url = 'https://prod-api.edunguru.com/';
    //   return url
    // }
    const { phone } = useParams()
    const {
        setToken,
        setStudentProfile,
        setUser,
    } = useContext(UserCredsContext);
    useEffect(() => {
        getSahajUserData(phone)
    }, [phone])
    const getSahajUserData = (_phoneNumber) => {
        api_open.get(`/auth/v1/student_auto_login/sahaj_reverification/?phone=${_phoneNumber}`)
            .then(response => {
                const { token, user, studentprofile } =
                    response.data.data;
                setToken(token);
                setStudentProfile(studentprofile);
                setUser(user);
                setEdunguruApi(token.access);
                if (user.first_name == "") {
                    props.history.push(`/register/introduce`);
                } else if (
                    studentprofile.hasOwnProperty("id") &&
                    studentprofile.grade == null
                ) {
                    props.history.push(`/register/goal`);
                } else {
                    props.history.push(`/dashboard`);
                }
            })
            .catch(error => {
                console.log(error, 'error')
            })
    }
    return (
        <div>

        </div>
    )
}

export default withRouter(SahajVerifyUser);