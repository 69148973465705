import React from 'react';

function Failure(){
    return(
        <div>
            Failure
        </div>
    )
}

export default Failure;