import React, { useState, useEffect } from 'react'
import { login_api } from '../../Utils/Network';
import styles from './index.module.css';

function Cancelation() {
    const [cancel, setCancel] = useState({});

    useEffect(() => { getCancel() }, [])
    useEffect(() => window.scrollTo(0, 0), []);

    const getCancel = () => {
        login_api
            .get('base/v1/terms_and_policies/3')
            .then((resp) => {
                console.log(resp, "ddsss")
                setCancel(resp.data.data)
            })
            .catch(err => console.log(err));
    }
  return (
    <div className={styles.terms_head}>

            <div>
                <h1 className={styles.title_terms}>{cancel?.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: cancel?.description }}>
                </div>
            </div>
            <div>

                {cancel?.conditions?.map((item, index) => (
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '20px', marginTop: '20px' }}>{index + 1}</div>
                        < div key={index} dangerouslySetInnerHTML={{ __html: item.description }}>
                        </div>
                    </div>

                )
                )}
            </div>
        </div>
  )
}

export default Cancelation