import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { api_token } from "../../Utils/Network";
import "./ValiditiyAccordion.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function ValidityAccordion({ _data, _item, _callbackData, setActiveBlock, activeBlock, ...props }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('0');
  const [courseValidity, setCourseValidity] = useState([]);
  const [comboValidity, setComboValidity] = useState([]);
  // const [activeBlock, setActiveBlock] = useState(-1);
  const [selectedValidity, setSelectedValidity] = useState({});
  const [currentId, setCurrentId] = useState(-1);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  console.log(_data, "_item");
  console.log(expanded, "Expanded");
  useEffect(() => {
    getValidity(_data.id, "course", 0, -2);
  }, [_data]);
  const getValidity = (_id, type, position, _idx) => {
    setSelectedValidity({});
    _callbackData({});
    setActiveBlock(-1);
    setCurrentId(_idx);
    console.log(currentId, _idx, "new id");
    if (currentId != _idx) {
      api_token.get(`/cms/v1/validity/?course=${_id}`).then((response) => {
        console.log(response.data.data, "validity");
        if (type === "course") {
          setCourseValidity(response.data.data);
        } else if (type === "combo") {
          setComboValidity(response.data.data[0]);
        }
      });
    }
  };
  console.log(courseValidity, "courseValidity");
  const setPurchaseData = (_item, e, id) => {
    e.stopPropagation();
    setSelectedValidity(_item);
    console.log(_item, "item from here");
    setActiveBlock(id);
    _callbackData(_item);
  };
  console.log(activeBlock, "activeBlock");
  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <div className={classes.root}>
        <Accordion expanded={expanded === `0`} onChange={handleChange(`0`)} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              {_data.board.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="course-validity-price-c">
              {courseValidity.map((item, id) => {
                console.log(item, "item from here");
                return (
                  <div
                    className={`course-validity-price-ci ${activeBlock == id ? "active-valid" : ""
                      }`}
                    onClick={(e) => setPurchaseData(item, e, id)}
                  >
                    {item.hasOwnProperty("validity_period") && (
                      <>
                        <p>
                          {item.mode.title} {item.validity_period.title}
                        </p>
                        <p>&#8377; {item.total_price}</p>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
        {/* {_data.hasOwnProperty("combo") &&
          _data.combo.length !== 0 &&
          _data.combo.map((item, idx) => {
            console.log(item, "item in");
            return (
              <Accordion
                expanded={expanded === `${idx + 1}`}
                onChange={handleChange(`${idx + 1}`)}
                onClick={() => getValidity(item.id, "combo", 0, idx)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="course-validity-price-c">
                    <div
                      className={`course-validity-price-ci ${
                        activeBlock == 0 ? "active-valid" : ""
                      }`}
                      onClick={(e) => setPurchaseData(comboValidity, e)}
                    >
                      {comboValidity.hasOwnProperty("validity_period") && (
                        <>
                          <p>{comboValidity.validity_period.days}</p>
                          <p>&#8377; {comboValidity.price}</p>
                          <p>Days</p>
                        </>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })} */}
      </div>
    </div>
  );
}

export default ValidityAccordion;
