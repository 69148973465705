import { Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../Utils/Network";
import StopWatch from "../StopWatch/StopWatch";
import "./TestComponent.css";

function TestComponent({ _data, _datas, setFinalResult = () => {}, ...props }) {
  const { user, setTestData, testData, resetTestData } =
    useContext(UserCredsContext);
  const [questionList, setQuestionList] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answeredQuestion, setAnsweredQuestion] = useState([]);
  const [activeAnswer, setActiveAnswer] = useState(-1);
  const [disableButton, setDisableButton] = useState(false);
  const [answerSelected, setAnsweredSelected] = useState({});
  const [arrayList, setArrayList] = useState([]);
  const [prevAnswers, setPrevAnswers] = useState({
    attempted: 0,
    not_attempted: 0,
  });
  const [summaryList, setSummaryList] = useState([]);
  useEffect(() => {
    var insertType = [...questionArray];
    insertType = insertType.map((obj) => ({ ...obj, type: 0 }));
    console.log(insertType, "insertType");
    setQuestionList(insertType);
    setCurrentQuestion(insertType[0]);
    setCurrentIndex(0);
    resetTestData();
    
    return () => {
      setTestData([]);
    };
  }, []);

  useEffect(() => {
    setQuestionList(_data.questions);
    setCurrentQuestion(_data.questions[0]);
    var newArray = Array.from({ length: _data.questions.length }, (i) => (i = 3));
    setSummaryList(newArray);
  }, [_data]);

  console.log(currentQuestion, _data, "currentQuestion");
  const clickAnswer = (_id, _item) => {
    setActiveAnswer(_id);
    console.log(_item, _data, "_item");
    setAnsweredSelected(_item);
  };

  useEffect(() => {
    if (arrayList.length > 0) {
      console.log(arrayList[currentIndex - 1].answer_data.length, "testData s");
      if (!arrayList[currentIndex - 1].answer_data.length) {
        setPrevAnswers({
          ...prevAnswers,
          not_attempted: prevAnswers.not_attempted + 1,
        });
        var newArray = [...summaryList];
        newArray[currentIndex - 1] = 2;
        setSummaryList([...newArray]);
      } else {
        var newArray = [...summaryList];
        newArray[currentIndex - 1] = 1;
        setSummaryList([...newArray]);

        setPrevAnswers({
          ...prevAnswers,
          attempted: prevAnswers.attempted + 1,
        });
      }
    }
  }, [arrayList]);
  console.log(answerSelected, summaryList, "prevAnswers");
  const changeQuestion = () => {
    if (currentIndex < questionList.length - 1) {
      setCurrentIndex((_index) => _index + 1);
      setCurrentQuestion(questionList[currentIndex + 1]);
      setActiveAnswer(-1);
    } else {
      setDisableButton(true);
    }
    const _answer_data = answerSelected.hasOwnProperty("id")
      ? [
          {
            answer_choice: answerSelected.id,
          },
        ]
      : [];
    setTestData({
      question: questionList[currentIndex].id,
      answer_data: _answer_data,
      avg_time_spend: 0,
    });
    setArrayList([
      ...arrayList,
      {
        question: questionList[currentIndex].id,
        answer_data: _answer_data,
        avg_time_spend: 0,
      },
    ]);
    //   {
    //     "question": 1,
    //     "answer_data": [
    //         {
    //             "answer_choice": 1
    //         }
    //     ],
    //     "avg_time_spend": 180
    // }
    setAnsweredSelected({});
  };
  console.log(currentIndex, questionList[2], "_data");
  const submitData = () => {
    const _answer_data = answerSelected.hasOwnProperty("id")
      ? [
          {
            answer_choice: answerSelected.id,
          },
        ]
      : [];
    setTestData({
      question: questionList[currentIndex].id,
      answer_data: _answer_data,
      avg_time_spend: 0,
    });
    setArrayList([
      ...arrayList,
      {
        question: questionList[currentIndex].id,
        answer_data: _answer_data,
        avg_time_spend: 0,
      },
    ]);
    const _testData = [
      ...testData,
      {
        question: questionList[currentIndex].id,
        answer_data: _answer_data,
        avg_time_spend: 0,
      },
    ];
    const submit_data = {
      data: _testData,
    };
    api_token
      .post(`/cms/v1/test/${_data.id}/save_test_answers/`, submit_data)
      .then((response) => {
        console.log(response.data, "response for test");
        _datas(1);
        setFinalResult(response.data.data);
        
      })
      .catch((error) => {});
    setTestData([]);

    // alert("submit");
  };
  console.log(_datas, "summaryList[idx]");
  return (
    <div className="testcomponent-c">
      <div className="test-component-left-side">
        <div style={{ height: "60px" }} className="test_space">&nbsp;</div>
        <p className="test-summary-c">Test Summary</p>
        <table id="customers" style={{ width: "100%" }}>
          <tr style={{ width: "100%" }}>
            <th style={{ width: "80%" }}></th>
            <th style={{ width: "20%" }}></th>
          </tr>
          <tr>
            <td>Attempted</td>
            <td>{prevAnswers.attempted}</td>
          </tr>
          <tr>
            <td>Not Attempted</td>
            <td>{prevAnswers.not_attempted}</td>
          </tr>
        </table>
        <div className="questionlist-summary">
          {questionList.map((item, idx) => {
            return (
              <div className="questionlist-summary-i">
                <div
                  className={`${
                    summaryList[idx] === 2 ? "false-dot" : "true-dot"
                  }`}
                >
                  .
                </div>
                {summaryList[idx] === 3 && currentIndex !== idx ? (
                  <p
                    className={`grey-class-num ${
                      currentIndex === idx ? "current-question-list" : ""
                    }`}
                  >
                    {idx + 1}
                  </p>
                ) : (
                  <p
                    className={`${
                      currentIndex === idx ? "current-question-list" : ""
                    }`}
                  >
                    {idx + 1}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="test-component-right-side">
        <div className="test-component-c-top">
          <div className="test-stopwatch-c">
            <StopWatch reset={currentIndex} data={_data.test_duration} sub={_datas}/>
          </div>
          <div className="test-submit-c">
            <Button variant="contained" color="primary" onClick={submitData}>
              Submit
            </Button>
          </div>
        </div>
        {currentQuestion.hasOwnProperty("title") && (
          <React.Fragment>
            <div
              dangerouslySetInnerHTML={{ __html: `${currentQuestion.title}` }}
            ></div>
            <div className="answer-options-cf">
              {currentQuestion.choices.map((item, idx) => {
                return (
                  <div
                    className={`answer-options-c ${
                      activeAnswer === idx ? "activeAnswer" : ""
                    }`}
                    onClick={() => clickAnswer(idx, item)}
                  >
                    <p>{alphabet[idx]}.</p>&nbsp;
                    <div
                      dangerouslySetInnerHTML={{ __html: `${item.title}` }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
        {currentIndex !== questionList.length - 1 && (
          <Button
            style={{ width: "100%" }}
            color="primary"
            variant="contained"
            onClick={() => changeQuestion()}
            disabled={disableButton}
          >
            Next
          </Button>
        )}
        {currentIndex == questionList.length - 1 && (
          <Button
            style={{ width: "100%" }}
            color="primary"
            variant="contained"
            onClick={() => changeQuestion()}
            disabled={true}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
}

export default TestComponent;

const questionArray = [
  {
    title: "Which one of the following is an integer",
    options: [
      {
        answer: "5",
        id: 1,
      },
      {
        answer: "6.23",
        id: 1,
      },
      {
        answer: "7.23",
        id: 1,
      },
      {
        answer: "8.23",
        id: 1,
      },
    ],
  },
  {
    title: "Which one of the following is an alphabet",
    options: [
      {
        answer: "a",
        id: 1,
      },
      {
        answer: "1",
        id: 1,
      },
      {
        answer: "3",
        id: 1,
      },
      {
        answer: "$",
        id: 1,
      },
    ],
  },
];

const alphabet = ["A", "B", "C", "D"];
