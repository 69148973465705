import React, { useContext, useEffect, useState } from "react";
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import overlayImage from "../../../Assets/Dashboard/Explore/overlayimage.png";
import arrowpath from "../../../Assets/Dashboard/arrowpath.png";
import "./CourseDetails.css";
import VideoPlayer from "../../../Components/VideoPlayer/VideoPlayer";
import TestVideo from "../../../Components/VideoPlayer/TestVideo";
import { api_token } from "../../../Utils/Network";
import { useParams, withRouter } from "react-router";
import correct from "../../../Assets/Dashboard/mycourse/correct.png";
import clock from "../../../Assets/Dashboard/mycourse/clock.png";
import instavatar from "../../../Assets/Dashboard/mycourse/instavatar.png";
import onlineimg from "../../../Assets/Dashboard/mycourse/online.png";
import pendriveimg from "../../../Assets/Dashboard/mycourse/pendrive.png";
import ChapterAccordion from "../../../Components/ChapterAccordion/ChapterAccordion.js";
import CustomDialog from "../../../Components/CustomDialog/CustomDialog";
import ValidityAccordion from "../../../Components/ValidityAccordion/ValidityAccordion";
import { Button } from "@material-ui/core";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import { useHistory } from "react-router-dom";
import defaultCourse from "../../../Assets/Dashboard/mycourse/defaultcourse.png";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    console.log(script, "script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    // script.onload = display.Razorpay;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function CourseDetails(props) {
  const {
    user,
    checkLogin,
    setShowLogin,
    purchaseResponse,
    purchaseData,
    setPurchaseData,
  } = useContext(UserCredsContext);
  let history = useHistory()
  const { id: param_id } = useParams();
  const [courseDetails, setCourseDetails] = useState({});
  const [value, setValue] = useState(false);
  const [validityData, setValidityData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [chapterList, setChapterList] = useState([]);
  const [chapterId, setChapterId] = useState(0);
  const [activeBlock, setActiveBlock] = useState(-1);
  const [videoFile, setVideoFile] = useState({
    videoObjects: {
      autoplay: true,
      controls: true,
      sources: [
        {
          src: "https://player.vimeo.com/external/395104858.hd.mp4?s=91a9d5d5e7afe723a1458269c41382ee9a3b2764&profile_id=174&oauth2_token_id=1400759996",
          type: "video/mp4",
          label: "sd",
        },
        {
          src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          type: "video/mp4",
          label: "hd",
        },
      ],
    },
  });
  const checkValue = (e) => {
    console.log(e.target.value, "value in target");
    setValue(!value);
  };

  useEffect(() => {
    getData();
    // getCourseChapter();
  }, [param_id]);

  const getData = () => {
    api_token
      .get(`/cms/v1/course/${param_id}`)
      .then((response) => {
        setCourseDetails(response.data.data);
        console.log(response, "getCourseChapter");
        getCourseChapter(response.data.data.combo[0].id);
        setChapterId(response.data.data.combo[0].id);
      })
      .catch((error) => { });
  };

  const getCourseChapter = (_id) => {
    api_token
      .get(`/cms/v1/chapter/?course=${_id}`)
      .then((response) => {
        setChapterList(response.data.data);
        setChapterId(_id);
      })
      .catch((error) => { });
  };

  const redirectUrl = (_id) => {
    // props.history.push(`/dashboard/course-details/${_id}`);
    getCourseChapter(_id);
  };

  const getValidity = () => {
    setOpenDialog(true);
  };

  const placeOrder = (_id) => {
    const _data = {
      validity_id: 9605,
      course: 1234,
    };
    api_token
      .post(`/payment/v1/order/`, _data)
      .then((response) => {
        console.log(response.data.data, "response for order");
        const { amount, id, order_id } = response.data.data;
        displayRazorPay(id, amount, order_id, 7477);
      })
      .catch((error) => { });
  };

  async function displayRazorPay(
    dataId,
    amountData,
    apiCourseidData,
    validityId
  ) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    console.log(dataId, "data id passed");
    console.log(amountData, "amountData");
    console.log(apiCourseidData, "apiCourseidData");
    console.log(validityId, "validityId");
    if (!res) {
      alert("razorpay sdk failed to load. Are you online?");
      return;
    }

    let current_url = window.location.href;
    if (
      current_url.includes("localhost") ||
      current_url.includes("165.22.214.137")
    ) {
      var razorpay_key = "rzp_test_auECWNj3vbIA10";
    } else {
      var razorpay_key = "rzp_live_lDScQvs1epmNca";
    }
    var courseprice = amountData;
    console.log(courseprice, "coursepricecourseprice");
    const options = {
      key: razorpay_key,
      amount: courseprice,
      currency: "INR",
      name: "Edunguru",
      description: "Course payment",
      image: "",
      order_id: `${apiCourseidData}`,
      // "callback_url":history.push("/my-space"),
      // "redirect": true,
      handler: function (response) {
        // alert(response);
        console.log(response.razorpay_payment_id, "hello");
        console.log(response.razorpay_order_id, "hello1");
        console.log(response.razorpay_signature, "hello2");
        // setPaymentId(response.razorpay_payment_id);
        // var intValue = parseInt(courseId)
        getpurchase(
          response.razorpay_order_id,
          response.razorpay_payment_id,
          apiCourseidData,
          validityId
        );
      },
      prefill: {
        name: `${user.first_name}`,
        email: `${user.email}`,
        contact: `${user.phone}`,
      },
    };

    var paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  const getpurchase = (orderId, rpid, apiCourseidData, validityId) => {
    var data = {
      transaction_id: rpid,
      course: param_id,
      validity_id: validityId,
      order_id: apiCourseidData,
    };
    console.log(data, "value to pass in purchase");
    api_token.post(`/purchase/course/`, data).then((response) => {
      console.log("The response for purchase course is ", response.data.data);
      // setOpen(true);
      // setSuccess(true);
    });
  };
  const setModalData = (_booleanValue) => {
    setOpenDialog(_booleanValue);
  };
  const checkIfLogin = () => {
    // debugger;
    const _boolean = checkLogin();
    if (_boolean) {
      setShowLogin(true);
    } else {
      setShowLogin(false);
      getValidity();
    }
  };

  const getValidityData = (_data) => {
    setValidityData(_data);
    setPurchaseData(_data, "purchase");
  };

  console.log(validityData, courseDetails, "purchaseData");

  const grandTotal = () => {
    const new_Data = {
      validity: validityData.id,
      course: courseDetails.id,
    };
    api_token
      .post(`payment/v1/grand_total_price/`, new_Data)
      .then((response) => {
        console.log(response, "response data click");
        setPurchaseData(response.data.data, "response");
        history.push({
          pathname:  `/dashboard/totalorder/${param_id}/${courseDetails.is_combo}`,
          state: validityData,
        });
      });
  };
  const orderPurchase = () => {
    grandTotal();
  };
  const backButton = () => {
    props.history.goBack();
  };
  return (
    <div style={{ marginBottom: "100px" }}>
      <div className="course-details-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        <img src={overlayImage} className="rect-explore-overlay-c" />
        <div className="course-dt-hero-text-c" onClick={backButton}>
          <div>
            <img src={arrowpath} className="course-dt-img" />
          </div>
          <div>
            <p>Explore Courses</p>
          </div>
        </div>

        <div className="course-details-info-section">
          <div className="course-details-info-c">
            <div style={{ width: "80%" }}>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                {/* {courseDetails.hasOwnProperty("board") &&
              courseDetails.board !== null &&
              courseDetails.board.title} */}
                {courseDetails.title}
              </p>
            </div>
            <div style={{ width: "auto" }}>
              <p className="buy-now-explore-b" onClick={checkIfLogin}>
                Buy Now
              </p>
            </div>
          </div>
          {/* <VideoPlayer {...videoFile.videoObjects} /> */}
          {courseDetails.hasOwnProperty("subject") &&
            courseDetails.subject.length != 0 && (
              <div className="subject-details-course">
                <div className="sub-listing-c">
                  <h3>Subjects Covered</h3>
                  {courseDetails.hasOwnProperty("subject") ? (
                    <React.Fragment>
                      {courseDetails.subject.map((item) => {
                        return (
                          <div className="subject-covered-list">
                            <span>
                              <img
                                src={correct}
                                style={{ width: "15px", marginRight: "10px" }}
                              />
                            </span>
                            <span>{item.title}</span>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>-</React.Fragment>
                  )}
                </div>

                {/* <div className="feature-listing-c">
            <h3>Features</h3>
            {courseDetails.hasOwnProperty("subject") ? (
              <React.Fragment>
                {courseDetails.subject.map((item) => {
                  return (
                    <div className="subject-featured-list">
                      <div>
                        <img
                          src={clock}
                          style={{ width: "18px", marginRight: "10px" }}
                        />
                      </div>
                      <div className="feature-list-ct">
                        <p>215 mins</p>
                        <p>Of Video Content</p>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <React.Fragment>-</React.Fragment>
            )}
          </div> */}
              </div>
            )}
        </div>

        <div className="toggle-btn-c-f">
          <input
            id="toggle-on"
            class="toggle toggle-left"
            name="toggle"
            value="false"
            type="radio"
            checked
          />
          <label for="toggle-on" class="btn">
            {courseDetails.hasOwnProperty("language") &&
              courseDetails.language.title}
          </label>
          {/* <input
            id="toggle-off"
            class="toggle toggle-right"
            name="toggle"
            value="true"
            type="radio"
          />
          <label for="toggle-off" class="btn">
            Hindi
          </label> */}
        </div>
        {/* <div className="switch-button">
          <input
            name="switch"
            value={value}
            className="switch-button-checkbox"
            type="checkbox"
            onChange={checkValue}
          ></input>
          <label className="switch-button-label" for="">
            <span className="switch-button-label-span">Hindi</span>
          </label>
        </div> */}
      </div>
      {/* {courseDetails.hasOwnProperty("instructors") &&
        courseDetails.instructors.length !== 0 && (
          <div className="guru-for-courses-c">
            <h3>Course Guru's</h3>
            <div className="guru-card-cf">
              {courseDetails.hasOwnProperty("instructors") &&
                courseDetails.instructors.map((item, idx) => {
                  return (
                    <div className="guru-card-c">
                      <img src={instavatar} />
                      <p>Professor</p>
                      <p>Lorem ipsum</p>
                    </div>
                  );
                })}
            </div>
          </div>
        )} */}
      <div className="mode-duration-cf">
        {courseDetails.hasOwnProperty("mode") &&
          courseDetails.mode.length != 0 && (
            <div className="selection-mode-cf">
              <h3>Mode</h3>
              <div className="md-select-c active-md-c">
                {courseDetails.hasOwnProperty("mode") &&
                  courseDetails.mode.map((item, id) => {
                    return (
                      <div className="cd-mode-f">
                        <div>
                          <img src={item.icon} style={{ width: "30px" }} />
                        </div>
                        <div>
                          <p>{item.title}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        {/* <div className="selection-duration-cf">
          <h3>Duration</h3>
          <div className="md-select-c active-md-c">
            <div>
              <p>7 Day Free Trial</p>
            </div>
            <div>
              <p>
                3 Months <span className="cd-duration-sp">Most Popular</span>
              </p>
            </div>
            <div>
              <p>6 Months</p>
            </div>
          </div>
        </div> */}
      </div>
      {courseDetails.hasOwnProperty("combo") &&
        courseDetails.combo.length !== 0 && (
          <div className="subjects-related-d-c">
            <h3>Course Subjects</h3>
            <div className="subject-r-d-c-f">
              {courseDetails.combo.map((item, idx) => {
                console.log(chapterId, item.id, "chapterId");
                return (
                  <div>
                    {item.subject.length == 0 ? (
                      <div
                        className={`subject-card-i-dc  course-subbox`}
                        onClick={() => redirectUrl(item.id)}
                        style={{
                          border: `${chapterId == item.id
                            ? "1px solid green"
                            : "1px solid white"
                            }`,
                        }}
                      >
                        <div>
                          <img src={defaultCourse} />
                          <p>{item.title}</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="subject-card-i-dc"
                        onClick={() => redirectUrl(item.id)}
                        style={{
                          border: `${chapterId == item.id
                            ? "1px solid green"
                            : "1px solid white"
                            }`,
                        }}
                      >
                        <div>
                          <img src={item.subject[0].icon} />
                          <p>{item.subject[0].title}</p>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      {chapterList.length !== 0 && (
        <React.Fragment>
          <div style={{ width: "85%", margin: "auto" }}>
            <h2 style={{ color: "black" }}>
              Chapters 1 - {chapterList.length}
            </h2>
          </div>
          <ChapterAccordion _data={chapterList} />
        </React.Fragment>
      )}
      {/* <TestVideo /> */}
      <div className="buybutton-b-c">
        <p className="buy-now-explore-b" onClick={checkIfLogin}>
          Buy Now
        </p>
      </div>
      <CustomDialog
        placeOrderHandle={placeOrder}
        openModal={openDialog}
        handleModal={setModalData}
        _purchase={orderPurchase}
        activeBlock={activeBlock}
      >
        <p style={{ fontWeight: "bold", fontSize: "20px" }}>
          {courseDetails.hasOwnProperty("board") &&
            courseDetails.board !== null &&
            courseDetails.board.title}
        </p>
        <ValidityAccordion
          _data={courseDetails}
          _callbackData={getValidityData}
          setActiveBlock={setActiveBlock}
          activeBlock={activeBlock}
        />
      </CustomDialog>
    </div>
  );
}

export default withRouter(CourseDetails);
