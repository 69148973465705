import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { setEdunguruApi, base_url, test_url } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));
function RedirectCookie(props) {
  const { setToken, setStudentProfile, setUser } = useContext(UserCredsContext);
  const [messageData, setMessageData] = useState("");
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  useEffect(() => {
    var url_token = props.history.location.pathname.split("/").pop();
    LoginUser(url_token);
  }, []);

  const LoginUser = async (url_token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${url_token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${base_url}auth/v1/app_to_web_autologin`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var response = JSON.parse(result);
        const { token, user, studentprofile, message } = response.data;
        setLoading(false);
        setToken(token);
        setStudentProfile(studentprofile);
        setUser(user);
        setEdunguruApi(token.access);
        setLoading(false);
        setMessageData(message);
        console.log(test_url, "test_url");
        cookies.set('edutech', token.access, { domain: '.edunguru.com', path: '/' });
        window.location = test_url;
      })
      .catch((error) => {
        console.error('Error', error)
        setLoading(false);
        setMessageData('Something went wrong');
      });
  };
  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundImage: "linear-gradient(to right, #161654 , #787cb9)",
          height: "100px",
        }}
      ></div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "15%" }}>
          <CircularProgress />
          <br />
          <br />
          Loading please wait ...
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "15%" }}>
          {messageData}
        </div>
      )}
    </div>
  );
}

export default withRouter(RedirectCookie);
