import React, {useState, useEffect} from 'react'
import styles from './index.module.css';
import girls from '../../Assets/courseware/girl_imagelisting.png';
import { login_api } from '../../Utils/Network';

function CourseWare() {
    const [datas, setDatas] = useState({});
    useEffect(() => getData(), [])
    useEffect(() => window.scrollTo(0,0), []);

    const getData = () => {
        login_api
        .get('edunguru_footer/v1/courseware_and_methodolgy/')
        .then((resp) => setDatas(resp.data.data))
        .catch(err => console.log(err));
    }
    console.log(datas ,"DDD")
    return (
        <div style={{ marginTop: '130px' }}>
            <div className={styles.max_widths}>
                <div className={styles.course_cont}>
                    <div>
                        <img src={girls} alt='girls' />
                    </div>
                    <div>
                        <div>
                            <h1>{datas[0]?.title}</h1>
                        </div>
                        <div className={styles.margin_btm}>
                            <div dangerouslySetInnerHTML={{__html: datas[0]?.description}} className={styles.description}></div>
                        </div>
                        {/* <div className={styles.lists}>
                            <ul>
                            <li><span className={styles.lists_txt}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                            <li><span className={styles.lists_txt}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                            <li><span className={styles.lists_txt}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                            <li><span className={styles.lists_txt}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                            </ul>
                        </div> */}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CourseWare