import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    [theme.breakpoints.down('md')]: {
      flexBasis: "70%",
    },
    flexShrink: 0,
  },
  
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function ChapterAccordion({ _data, props }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    // setExpanded(isExpanded ? panel : false);
  };
  console.log(_data, "_data_data");
  return (
    <div style={{ width: "85%", margin: "auto" }}>
      <div className={classes.root}>
        {_data.length !== 0 &&
          _data.map((item, idx) => {
            return (
              <Accordion
                expanded={expanded === `${idx}`}
                onChange={handleChange(`${idx}`)}
                style={{marginBottom:'10px'}}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    {idx + 1} .{item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.description}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
}

export default ChapterAccordion;
