import React, { useState, useEffect, useContext } from 'react'
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import styles from './index.module.css';
import { api_token } from '../../../Utils/Network';
import { useParams } from 'react-router-dom';
import PopOverModel from '../../../Components/PopOver/PopOverModel';
import { useHistory } from "react-router-dom";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        console.log(script, "script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        // script.onload = display.Razorpay;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}
function PayLive(props) {
    const {
        updateLoginData,
        loginData,
        setToken,
        setStudentProfile,
        setUser,
        studentProfile,
        user,
        token,
    } = useContext(UserCredsContext);
    const [grandTotals, setGrandTotal] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const [couponData, setCouponData] = useState({
        live_course: '',
    });
    const [isCoupon, setIsCoupon] = useState([]);
    const [userAddress, setUserAddress] = useState([]);
    const [dataToPopup, setDataToPopup] = useState(null);
    const [addid, setAddID] = useState(null);
    const { id } = useParams();
    let history = useHistory();
    
    useEffect(() => {
        grandTotalAPi();
        getUserAddress();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    //Razorpay

    async function displayRazorPay(amountData, apiCourseidData, validityId) {
        console.log(amountData, "amountData");
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("razorpay sdk failed to load. Are you online?");
            return;
        }
        let current_url = window.location.href;
        if (
            current_url.includes("localhost") ||
            current_url.includes("165.22.214.137") ||
            current_url.includes("edunguru2.g10consultancy.com")
        ) {
            var razorpay_key = "rzp_test_auECWNj3vbIA10";
        } else {
            var razorpay_key = "rzp_live_gdFNhp4ajqBhYo";
        }
        var courseprice = amountData.order.amount;
        const options = {
            key: razorpay_key,
            amount: courseprice,
            currency: "INR",
            name: "Edunguru",
            // description: `${course.title}`,
            image: "",
            order_id: `${amountData.order.order_id}`,
            handler: function (response) {
                console.log(response, "responsess");
                getpurchase(
                    amountData,
                    response.razorpay_order_id,
                    response.razorpay_payment_id,
                    apiCourseidData,
                    validityId
                );
            },
            prefill: {
                name: `${user.first_name}`,
                email: `${user.email}`,
                contact: `${user.phone}`,
            },
        };
        var paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const getUserAddress = () => {
        api_token
          .get(`/auth/v1/student/account/me/`)
          .then((response) => {
            if (response.data.data.address.length > 0) {
              setUserAddress(response.data.data.address);
              setAddID(response.data.data.address[0].id);
            } else {
              setUserAddress([]);
            }
          })
          .catch((error) => { });
      };

    const getpurchase = (Orders, orderId, rpid, apiCourseidData, validityId) => {
        console.log(Orders, orderId, rpid, apiCourseidData, validityId, "orrpapi");
        api_token
        .patch(`administration/v2/payment/${Orders.id}/update_purchased_course/`, { transaction_id: rpid })
        .then((r) => {
            if(r.data.data){
                alert("Successfull");
                
            }
        })
        .catch(err => console.log(err))
    }
    const grandTotalAPi = () => {
        const data = {
            ...couponData,
            live_course: id,
        }
        api_token
            .post('payment/v1/grand_total_price/', data)
            .then((res) => {
                console.log(res.data.data, "rddd")
                setGrandTotal(res.data.data)
            })
            .catch(err => console.log(err));
    }

    const applyCoupon = (value) => {
        setCouponData({ ...couponData, coupon: value })
        grandTotalAPi();
        setIsVisible(false)
    }


    const viewData = (x) => {
        setIsVisible(true);
        setDataToPopup(x);
    }

    const openData = (x) => {
        setDataToPopup(x)
        setIsVisible(true)
        api_token
            .get(`base/v1/coupon/?live_courses=${id}`)
            .then((res) => {
                setIsCoupon(res.data.data);
            })
            .catch(err => console.log(err))
    }

    const getData = () => {
        return (
            <>
                {isCoupon && isCoupon.map((v, i) => (
                    <div>
                        <div>
                            <div>Title: {v.title}</div>
                            <div>Coupon Code: {v.code}</div>
                        </div>
                        <div>
                            <button onClick={() => applyCoupon(v.id)}>Apply</button>
                        </div>

                    </div>))}
                    {!isCoupon.length > 0 && <div>No Coupons Available</div>}
            </>)
    }

    const getAddress = () => {
        return (
            <>
                {userAddress && userAddress.map((v,i) => (<div className={`${styles.addBox} ${(addid == v.id)? styles.addbx: ''}`} onClick={() => setAddID(v.id)} >
                    <div  style={{marginBottom: '10px'}}>{v?.line_1} {v?.line_2} {v?.landmark}</div>
                    <div  style={{marginBottom: '10px'}}>{v?.zipcode}</div>
                    <div  style={{marginBottom: '10px'}}>{v?.state?.title}</div>
                    <div style={{display: 'flex'}}>{v?.city?.title}, {v?.country?.title}</div>
                </div>))}
            </>
        )
    }

    const PayNow = () => {
        const values = {
            amount: grandTotals?.course_amt,
            live_course_id: id,
            shipping_address_id: addid,
            user_device: `${user?.first_name} web`,
            is_coupon_applied: grandTotals?.is_coupon_applied,
            user_os: 'web',
            course_amt: grandTotals?.course_amt,
            coupon_used_amt: grandTotals?.coupon_used_amt,
            platform: 1,
            tax: grandTotals?.tax,
            user_version: 6.1,
            discount_amt: grandTotals?.discount_amt
        }
        api_token
            .post(`payment/v2/purchase/`, values)
            .then((res) => {
                displayRazorPay(res.data.data);
            })
            .catch(err => console.log(err))
    }
    const goBack = () => {
        history.push(`/dashboard/live/description/${id}`)
      }
    console.log(userAddress, "userAddress")
    console.log(grandTotals, "grandTotals")
    return (
        <div>
                    <div className={`${styles.btns} ${styles.btnCommon}`} style={{textAlign: 'center'}} onClick={() => goBack()}>Back to List</div>
            <div className={`${styles.mainList} ${styles.mainListPay}`}>
                <div className='start-learning-subjects'>Order</div>
                <div className={styles.titlePay}>{props?.props?.location?.state?.detail?.title}</div>
                <div className={styles.flex}>
                    <div>
                        <div>Online</div>
                        <div className={styles.live}>Live</div>
                    </div>
                    <div className={`${styles.fontData}`}>₹ {props?.props?.location?.state?.detail?.price}</div>

                </div>
                <div className={`${styles.flex} ${styles.dataAdd}`}>
                    <div>
                    {userAddress && userAddress.map((v,i) => (
                    (addid == v.id)?
                    <div>
                    <div  style={{marginBottom: '10px'}}>{v?.line_1} {v?.line_2} {v?.landmark}</div>
                    <div  style={{marginBottom: '10px'}}>{v?.zipcode}</div>
                    <div  style={{marginBottom: '10px'}}>{v?.state?.title}</div>
                    <div style={{display: 'flex'}}>{v?.city?.title}, {v?.country?.title}</div>
                </div>: <></>))}
                    </div>
                    <div>
                        <button onClick={() => viewData(0)} className={`${styles.btnCommon}`}>Change Address</button>
                    </div>
                </div>
                <div className={styles.flex} style={{margin: '10px 0'}}>
                    <div>Select Coupon</div>
                    <button onClick={() => openData(1)} className={`${styles.btnCommon}`}>view coupon</button>
                </div>
                <div>
                    <div className={`${styles.fontData}`}>Price Details</div>
                    <div className={styles.flex}>
                        <div className={`${styles.fontData}`}>Total Price</div>
                        <div className={`${styles.fontData}`}>₹ {grandTotals.course_amt}</div>
                    </div>
                    <div className={styles.flex}>
                        <div className={`${styles.fontData}`}>Tax</div>
                        <div className={`${styles.fontData}`}>₹ {grandTotals.tax}</div>
                    </div>
                    <div className={styles.flex}>
                        <div className={`${styles.fontData}`}>Discount</div>
                        <div className={`${styles.fontData}`}>₹ {grandTotals.discount_amt}</div>
                    </div>
                    <div className={styles.flex}>
                        <div className={`${styles.fontData}`}>Offer</div>
                        <div className={`${styles.fontData}`}>₹ 0</div>
                    </div>
                    <div className={styles.flex}>
                        <div className={`${styles.fontData}`}>Grand Total</div>
                        <div className={`${styles.fontData}`}>₹ {grandTotals.grand_total}</div>
                    </div>
                </div>
                <div style={{textAlign: 'right', marginBottom: '10px'}}>
                    <button onClick={() => PayNow()} className={`${styles.btnCommon} ${styles.paybtn}`}>Pay</button>
                </div>
            </div>

            <PopOverModel visible={isVisible} onClose={() => setIsVisible(false)} >
                <div>
                <>{dataToPopup === 1 && getData()}</>
                <>{dataToPopup === 0 && getAddress()}</>
                </div>
            </PopOverModel>
        </div>
    )
}

export default PayLive