import React, { useEffect, useState } from "react";
import live_btn from "../../../Assets/LiveSession/live_btn.png";
import card_background from "../../../Assets/LiveSession/live_class_background.png";
import omgoingBg from "../../../Assets/LiveSession/omgoingBg.svg";
import female_teacher from "../../../Assets/LiveSession/portrait-female-teacher-white.png";
import styles from "./index.module.css";

import { api_open, api_token } from "../../../Utils/Network";
import calender_Icon from "../../../Assets/LiveSession/calender_Icon.svg";
import { Box, Button, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import live from "../../../Assets/LiveSession/live_btn.png";
import females from "../../../Assets/LiveSession/female.png";
import females2 from "../../../Assets/LiveSession/female2.png";
import clock from "../../../Assets/LiveSession/clock.png";
import day from "../../../Assets/LiveSession/day.png";
import session from "../../../Assets/LiveSession/session.png";
import lives from "../../../Assets/LiveSession/live.png";
import { VscCalendar } from "react-icons/vsc";
import moment from "moment";
import { useHistory } from "react-router-dom";

function LiveList(props) {
  var today = new Date();
  const [liveVideo, setLiveVideo] = useState({});
  const [instructor, setInstructor] = useState([]);
  const [inst, setInst] = useState({});
  const [sessions, setSession] = useState([]);
  let history = useHistory();

  let { id } = useParams();
  const cal = [{
    title: "Monday",
    data: "Mon"
  }, {
    title: "Tuesday",
    data: "Tue"
  }, {
    title: "Wednesday",
    data: "Wed"
  }, {
    title: "Thursday",
    data: "Thur"
  }, {
    title: "Friday",
    data: "Fri"
  }, {
    title: "Saturday",
    data: "Sat"
  }];


  useEffect(() => {
    getLiveVideo();
    getSessions();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getLiveVideo = () => {
    api_token
      .get(`/live/v1/live_courses/${id}`)
      .then((response) => {
        setLiveVideo(response.data.data);
        getInstructor(response.data.data.instructor[0])
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSessions = () => {
    api_token
      .get(`/live/v1/sessions/?live_course=${id}`)
      .then((res) => {
        setSession(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const goBack = () => {
    history.push("/dashboard/live/live")
  }

  const getInstructor = (x) => {
    api_token
      .get(`/profile/v1/instructor/${x}`)
      .then((response) => {
        setInst(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const redirectData = () => {
    history.push({
      pathname: `${id}/pay`,
      state: { detail: liveVideo }
    });
  }
  console.log(liveVideo, "liveVideo")
  return (
    <div>
      <div className={styles.mainList}>
        <div className={`${styles.btns} ${styles.btnCommon}`} style={{textAlign: 'center'}} onClick={() => goBack()}>Back to List</div>
        <div className={styles.headContent} style={{backgroundImage: `url(${liveVideo?.image})`}}>
          <div>
            <img src={live} alt="image" style={{ width: '100px' }} />
            <p className={styles.para_Image} style={{ margin: '0', marginBottom: '60px' }}>Live Interactive Sessions</p>
            {liveVideo?.subject?.map((v, i) => (<div
              className={styles.subjectIcon}
              style={{
                padding: "10px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                height: "50px",
                width: "50px",
                boxShadow: "0px 4px 10px #00000040",
              }}
            >
              <img
                src={v.icon}
                alt={v.title}
                style={{ height: "auto", width: "100%" }}
              />
            </div>))}
          </div>
          {/* <div>
            <img src={females} alt="images" className={styles.girl_img} />
          </div> */}
        </div>
        <div className={styles.flex}>
          <div className={`${styles.flex} ${styles.sessionDate}`}>
            <div className={styles.flex}>
              <VscCalendar style={{ color: 'white' }} />
              <div>
                <p className={styles.sDate}>Start Date</p>
                <p className={styles.DateField}>{moment(liveVideo.start, "YYYY-MM-DD").format(
                  "DD MMM YYYY "
                )}</p>
              </div>
            </div>
            <div className={styles.flex}>
              <VscCalendar style={{ color: 'white' }} />
              <div>
                <p className={styles.sDate}>End Date</p>
                <p className={styles.DateField}>{moment(liveVideo.end, "YYYY-MM-DD").format(
                  "DD MMM YYYY "
                )}</p>
              </div>
            </div>
          </div>
          <div className={styles.enrollment}>
            {liveVideo.enrollments}<br /><br />
            Enrollment
          </div>
        </div>
        <div className={styles.box_data}>
          <div className={styles.flex_bx}>
            <div>
              <img src={clock} alt="clock" />
            </div>
            <div>
              <p style={{ margin: '0 10px' }}>Batch Time</p>
              <p style={{ margin: '10px 10px' }}>{moment(liveVideo?.commence, "hh:mm:ss").format("hh:mm A")}</p>
            </div>
          </div>

          <div className={styles.flex_bx}>
            <div>
              <img src={day} alt="clock" />
            </div>
            <div>
              <p style={{ margin: '0 10px' }}>Days Covered</p>
              {Object.keys(liveVideo).length > 0 && <div style={{ display: 'flex' }} className={styles.flex}>
                {cal.map((v, i) => (
                  <div className={styles.box_}
                    // style={{ backgroundImage: (v?.title == liveVideo?.days[i]?.title) ? 'linear-gradient(to right, #8284BD , #4B4B7E )' : '' }}
                    style={{ backgroundImage: (liveVideo?.days.find(calItem => calItem.title == v.title)?.title == v.title) ? 'linear-gradient(to right, #8284BD , #4B4B7E )' : '' }}

                    
                  >{v.data}</div>
                ))}
              </div>}
            </div>
          </div>

          <div className={styles.flex_bx}>
            <div>
              <img src={session} alt="clock" />
            </div>
            <div>
              <p style={{ margin: '0 10px' }}>Sessions Completed</p>
              <p style={{ margin: '10px 10px' }}>{liveVideo?.total_sessions_completed}/{liveVideo?.total_sessions}</p>
            </div>
          </div>

          <div className={styles.flex_bx}>
            <div>
              <img src={lives} alt="clock" />
            </div>
            <div>
              <p style={{ margin: '0 10px' }}>Live Classes</p>
              <p style={{ margin: '10px 10px' }}>{liveVideo?.total_sessions} session</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.fonts}>Guru</div>
          <div className={`${styles.guruBg} ${styles.headContent}`}>
            {Object.keys(inst).length > 0 && <div>
              <div className={styles.data}>{inst?.user?.first_name} {inst?.user?.last_name}</div>
              <div className={styles.flex}>
                <div style={{ padding: '0 10px', borderRight: '2px solid grey' }}>{inst?.education[0]?.degree?.title}</div>
                <div style={{ padding: '0 10px' }}>{inst?.education[0]?.stream?.title}</div>
              </div>
              <div className={styles.flex_bx}>
                {liveVideo?.subject?.map((v, i) => (
                  <div className={styles.btns}>{v.title}</div>
                ))}
              </div>
            </div>}
            <div>
              <img src={females2} alt="images" className={styles.girl_img} style={{ width: '200px' }} />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '50px' }}>
          <div className={styles.fonts}>Subject Covered</div>
          <div className={styles.subjectContainer}>
            {Object.keys(liveVideo).length > 0 && liveVideo?.subject?.map((v, i) => (
              <div className={styles.subCont}>
                <img src={v.icon} alt={v.title} className={styles.contSingleBx} />
                <p>{v.title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.purchaseNow}>
              {(liveVideo?.is_purchased) ?
              <div className={`${styles.purchaseBtn} ${styles.green}`}>You have already enrolled for this batch</div> :
             <>{(today > liveVideo.start) ? <div className={`${styles.purchaseBtn} ${styles.reds}`}>Classes starts on {moment(liveVideo.start).format('LL')}</div>: <></>}</>}
        </div>

        {(liveVideo?.is_purchased) ?<></> : <div className={styles.purchaseNow}>
          <button className={styles.purchaseBtn} onClick={() => redirectData()}>Purchase Now</button>
        </div>}
      </div>
    </div>
  );
}

export default LiveList;

