import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import mainimg from "../../../Assets/FreeTrailLanding/testimon.png";
import Quate from "../../../Assets/FreeTrailLanding/Qute.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card } from "antd";
import { api_token } from "../../../Utils/Network";
import { log } from "video.js";
import testimonialsDp from '../../../Assets/testimonialsDp.png'
import edunguru from '../../../Assets/edunguru.png'
import comma from '../../../Assets/comma.png'

function TestimonialSlider({
  partial = false,
  item = 1.5,
  next = 1,
  // data,
  // setSelectCardId,
}) {
  const [testimonials, setTestimonials] = useState([])
  const [responsive, setResponsive] = useState({
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 40,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 993 },
      items: 3,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1100, min: 768 },
      items: 2,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
  });
  useEffect(() => {
    getTestimonial();
  }, [])

  const getTestimonial = () => {
    api_token
      .get(`profile/v1/testimonials/`)
      .then((res) => {
        console.log(res?.data?.data);
        setTestimonials(res?.data?.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }
  return (
    <div>
      <Carousel
        partialVisible={partial}
        responsive={responsive}
        // customButtonGroup={<CustomButtonGroup />}
        arrows={false} // Hide default arrows
        showDots={true}
      >
        {
          testimonials?.map((v) => {
            return (
              <div className={styles.testimonialContainer}>
                <div className={styles.logo}>
                  <img src={edunguru}></img>
                </div>
                <div className={styles.comma}>
                  <img src={comma}></img>
                </div>
                <p>{v.description}</p>
                <hr />
                <div className={styles.bottom}>
                  <div className={styles.dp}>
                    <img src={v.user.avatar}></img>


                  </div>
                  <p>{v.user.first_name} {v.user.last_name}</p>
                </div>
              </div>
            )
          })
        }
      </Carousel>
    </div>
  );
}

export default TestimonialSlider;

const testidata = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
];
