import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#00C49F", "#bfbaba"];

export default class Donut extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      counter: 0, data:[], COLORS: [], loading:false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    var myData = [
      { name: "Correct", value: props._type.correct },
      { name: "Incorrect", value: props._type.wrong },
    ];

    var colors = [props._type.color1, props._type.color2];
    return {data:myData, COLORS: colors}
  }

  render() {
    console.log(this.props._type, "PPPP");
    return (
      <PieChart width={220} height={230} onMouseEnter={this.onPieEnter}>
        <Pie
          data={this.state.data}
          cx={100}
          cy={120}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={-1}
          dataKey="value"
        >
          {this.state.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={this.state.COLORS[index % this.state.COLORS.length]} />
          ))}
        </Pie>
        {/* <Pie
          data={data}
          cx={420}
          cy={200}
          startAngle={180}
          endAngle={0}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie> */}
      </PieChart>
    );
  }
}
