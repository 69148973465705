import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "./index.module.css";

function CustomDialog({
  placeOrderHandle,
  handleModal,
  openModal,
  _purchase,
  activeBlock,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (openModal) {
      setOpen(openModal);
    }
  }, [openModal]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleModal(false);
  };

  console.log(activeBlock, "DIalogProps")
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="md"
      >
        <DialogContent style={{ height: "80vh", maxHeight: "80vh" }}>
          <DialogContentText id="alert-dialog-description">
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={_purchase} disabled={(activeBlock === -1) ? true : false} className={(activeBlock === -1) ? styles.btn_buy : styles.btn_actives}>Buy Now</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomDialog;
