import React, { useState, useEffect, useContext } from "react";
import "./ExploreCourses.css";
import courseCard1 from "../../../Assets/Dashboard/Explore/coursecardt.png";
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import overlayImage from "../../../Assets/Dashboard/Explore/overlayimage.png";
import { api_open, api_token } from "../../../Utils/Network";
import filterIcon from "../../../Assets/Dashboard/CourseFilter/filter.png";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import cardImg from "../../../Assets/Dashboard/CourseFilter/Comprehensive.png";
import { useParams } from "react-router";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import SubjectDialog from "../../../Components/SubjectDialog/SubjectDialog";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    justifyContent: "center",
  },
  autoWidth: {
    width: "265px",
    [theme.breakpoints.up("sm")]: {
      width: "280px",
    },
  },
}));

function ExploreCourses(props) {
  const { id: param_id } = useParams();
  const { filterTabs } = useContext(UserCredsContext);
  const [typeSelect, setTypeSelect] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [search, setSearch] = useState(false);
  const [grade, setGrade] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [board, setBoard] = useState([]);
  const [subject, setSubject] = useState([]);
  const [showDialog, setShowDialog] = useState();
  const [language, setLanguage] = useState([]);
  const [filterActive, setFilterActive] = useState(-1);
  console.log(filterActive, "filterActive");
  const [filterId, setFilterId] = useState({
    boardId: null,
    gradeId: null,
    subjectId: null,
    languageId: null,
  });
  const [filterState, setFilterState] = useState({
    board: [],
    grade: [],
    subject: [],
    language: [],
  });
  const [paginationData, setPaginationData] = useState({
    count: null,
    max_pages: null,
    next_page: null,
    page: null,
    previous_page: null,
    total_count: null,
  });
  const [subjectData, setSubjectData] = useState([]);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterData, setFilterData] = useState({
    page: 1,
    subject: [],
    grade: [],
    board: [param_id],
    language: [],
    is_bookmarked: false,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  console.log(props.location.state, 'props.location.state')
  useEffect(() => {
    var type =
      (props.location.state && props.location.state.hasOwnProperty('fromType')) != undefined ? props.location.state.fromType : null;
    // var subjectType = props.location.state.fromSubject 
    var subjectType = (props.location.state && props.location.state.hasOwnProperty('fromSubject')) != undefined ? props.location.state.fromSubject : null;
    if (param_id == "null" && subjectType == null) {
      getData(1, [], [], [], [], type);
      setFilterActive(type);
    } else if (type == null && subjectType == null) {
      getData(1, [], [], [param_id], [], type);
    } else if (subjectType != null) {
      getData(1, [subjectType], [], [], [], type);
    }
    // setFilterId({
    //   boardId: null,
    //   gradeId: null,
    //   subjectId: null,
    //   languageId: null,
    // });
  }, [param_id, props]);
  useEffect(() => {
    if (search == false) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      getData(
        1,
        filterState.subject,
        filterState.grade,
        filterState.board,
        filterState.language
      );
      setSearch(true);
      // Send Axios request here
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);
  const getData = (
    page_no,
    _subject,
    _grade,
    _board,
    _language,
    _pt = typeSelect
  ) => {
    setTypeSelect(_pt);
    if (page_no === 1) {
      setSubjectData([]);
    }
    setLoading(true);
    const data = {
      page: page_no,
      subject: _subject,
      grade: _grade,
      board: _board,
      language: _language,
      is_bookmarked: false,
      q: `${searchParam}`,
      product_type: _pt,
    };
    api_open
      .post(`/cms/v1/custom_filter`, data)
      .then((response) => {
        console.log(response.data, "response data");
        setLoading(false);
        setIsFetching(false);
        const {
          count,
          max_pages,
          next_page,
          page,
          previous_page,
          total_count,
        } = response.data;
        if (page === 1) {
          setSubjectData(response.data.data);
        } else {
          setSubjectData([...subjectData, ...response.data.data]);
        }
        setPaginationData({
          count,
          max_pages,
          next_page,
          page,
          previous_page,
          total_count,
        });
        setCurrentPage(page);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!isFetching) return;
    if (currentPage === paginationData.max_pages) {
      return;
    }
    getData(
      currentPage + 1,
      filterState.subject,
      filterState.grade,
      filterState.board,
      filterState.language
    );
  }, [isFetching]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight ||
      isFetching
    )
      return;
    setIsFetching(true);
  }
  useEffect(() => {
    // getGrade();
    getBoard();
    getSubject();
    getLanguage();
    window.scrollTo(0, 0);
  }, []);
  const getBoard = () => {
    api_open
      .get(`/base/v1/board`)
      .then((response) => {
        setBoard(response.data.data);
        let index = response.data.data.findIndex((el) => el.id == param_id);
        setFilterId({ ...filterId, boardId: index });
        if (param_id != "null") {
          setFilterState({ ...filterState, board: [param_id] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getGrade = (v) => {
    api_open
      .get(`/base/v1/board-grade-detail?board=${v}`)
      .then((response) => {
        setGrade(response.data.data[0].grade);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSubject = () => {
    api_open
      .get(`/base/v1/subject`)
      .then((response) => {
        setSubject(response.data.data);
        if (props.location.state.hasOwnProperty('fromSubject')) {
          console.log(props.location.state, 'props.location.state')
          let index = response.data.data.findIndex((el) => el.id == props.location.state.fromSubject);
          setFilterId({ ...filterId, subjectId: index });
          setFilterState({ ...filterState, subject: [response.data.data[index].id] })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getLanguage = () => {
    api_open
      .get(`/base/v1/language`)
      .then((response) => {
        setLanguage(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChangeLanguage = (event, values) => {
    if (!values) {
      getData(1, filterState.subject, filterState.grade, filterState.board, []);
      setFilterState({ ...filterState, language: [] });
      setFilterId({ ...filterId, languageId: null });
      return;
    }
    let index = grade.findIndex((el) => el.id === values.id);
    setFilterId({ ...filterId, languageId: index });
    setFilterState({ ...filterState, language: [values.id] });
    getData(1, filterState.subject, filterState.grade, filterState.board, [
      values.id,
    ]);
  };

  const handleChangeBoard = (event, values) => {
    if (!values) {
      getData(
        1,
        filterState.subject,
        filterState.grade,
        [],
        filterState.language
      );
      setFilterState({ ...filterState, board: [] });
      setFilterId({ ...filterId, boardId: null });
      return;
    }
    getGrade(values.id);
    let index = board.findIndex((el) => el.id === values.id);
    setFilterId({ ...filterId, boardId: index });
    setFilterState({ ...filterState, board: [values.id] });
    getData(
      1,
      filterState.subject,
      filterState.grade,
      [values.id],
      filterState.language
    );
  };

  const handleChangeGrade = (event, values) => {
    if (!values) {
      getData(
        1,
        filterState.subject,
        [],
        filterState.board,
        filterState.language
      );
      setFilterState({ ...filterState, grade: [] });
      setFilterId({ ...filterId, gradeId: null });
      return;
    }
    let index = grade.findIndex((el) => el.id === values.id);
    setFilterId({ ...filterId, gradeId: index });
    setFilterState({ ...filterState, grade: [values.id] });
    getData(
      1,
      filterState.subject,
      [values.id],
      filterState.board,
      filterState.language
    );
  };

  const handleChangeSubject = (event, values) => {
    // console.log(values.id, "values id");
    if (!values) {
      getData(
        1,
        [],
        filterState.grade,
        filterState.board,
        filterState.language
      );
      setFilterState({ ...filterState, subject: [] });
      setFilterId({ ...filterId, subjectId: null });
      return;
    }
    let index = subject.findIndex((el) => el.id === values.id);
    setFilterId({ ...filterId, subjectId: index });
    setFilterState({ ...filterState, subject: [values.id] });
    getData(
      1,
      [values.id],
      filterState.grade,
      filterState.board,
      filterState.language
    );
  };

  const redirectUrl = (_id) => {
    props.history.push(`/dashboard/course-details/${_id}`);
  };
  const checkFilterId = (_item, _index) => {
    if (_item.id === 3) {
      if (filterActive === 3) {
        setFilterActive(-1);
        setFilterId({
          boardId: null,
          gradeId: null,
          subjectId: null,
          languageId: null,
        })
        setFilterState({
          board: [],
          grade: [],
          subject: [],
          language: [],
        })
      } else {
        setShowDialog(true)
      }
      return
    }
    setFilterId({
      boardId: null,
      gradeId: null,
      subjectId: null,
      languageId: null,
    })
    setFilterState({
      board: [],
      grade: [],
      subject: [],
      language: [],
    })
    // setFilterActive()
    if (filterActive == _item.id) {
      setFilterActive(-1);
      // getData(
      //   currentPage,
      //   filterState.subject,
      //   filterState.grade,
      //   filterState.board,
      //   filterState.language,
      //   null
      // );
      getData(
        1,
        [],
        [],
        [],
        [],
        null
      );
    } else {
      setFilterActive(_item.id);
      getData(
        1,
        [],
        [],
        [],
        [],
        _item.id
      );
    }
  };
  const handleChangeSearch = (e) => {
    setSearchParam(e.target.value);
    setSearch(true);
  };

  const handleShowDialog = (_id) => {
    
    setShowDialog(false);
    let index = subject.findIndex((el) => el.id == _id);
    // setTimeout(()=>{
    setFilterState({ ...filterState, subject: [subject[index].id] })
    setFilterId({ ...filterId, subjectId: index });
    setFilterActive(3);

    // },1000)
  };
  console.log(filterId, 'filter id data')
  console.log(filterActive, "FILTER")
  return (
    <div>
      <div className="explore-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        <img src={overlayImage} className="rect-explore-overlay-c" />
        <div className="explore-hero-text-c">
          <p>Start Your Journey Today</p>
          <p>Learn from India’s most experienced Gurus.</p>
        </div>
      </div>
      <div className="filter-list-dashboard">
        <input
          type="text"
          placeholder="Search"
          className="search-bar0"
          onChange={(e) => handleChangeSearch(e)}
        />

        <div>
          <div className="filter-list-if">
            {typeData.map((item, idx) => {
              return (
                <div
                  onClick={() => {
                    checkFilterId(item, idx);
                  }}
                  key={idx}
                >
                  <p
                    className={`${filterActive == item.id
                      ? "setitemactive0"
                      : "setitemactive1"
                      }`}
                  >
                    {item.title}
                  </p>
                </div>
              );
            })}
            <div
              className="filter-f-lc"
              aria-describedby={id}
              onClick={handleClick}
            >
              <img src={filterIcon} />
              <p>Filters</p>
            </div>
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography className={classes.typography}>
              <div className="course-filterpopover-c">
                <p style={{ textAlign: "center" }}>
                  {paginationData.total_count} Courses Found
                </p>
                <div className="fields-filter-courses">
                  <Autocomplete
                    disabled={loading}
                    value={board[filterId.boardId]}
                    id="Board"
                    options={board}
                    getOptionLabel={(option) => option.title}
                    className={classes.autoWidth}
                    onChange={handleChangeBoard}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Board"
                        variant="outlined"
                        size="small"
                        name="board"
                      />
                    )}
                  />
                </div>
                <div className="fields-filter-courses">
                  <Autocomplete
                    disabled={loading}
                    value={grade[filterId.gradeId]}
                    id="Grade"
                    options={grade}
                    getOptionLabel={(option) => option.title}
                    className={classes.autoWidth}
                    onChange={handleChangeGrade}
                    name="grade"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grade"
                        variant="outlined"
                        size="small"
                        value={filterState.grade}
                      />
                    )}
                  />
                </div>


                <div className="fields-filter-courses">
                  <Autocomplete
                    disabled={loading}
                    value={subject[filterId.subjectId]}
                    id="Subject"
                    options={subject}
                    getOptionLabel={(option) => option.title}
                    className={classes.autoWidth}
                    onChange={handleChangeSubject}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Subject"
                        variant="outlined"
                        size="small"
                        name="subject"
                      />
                    )}
                  />
                </div>
                <div className="fields-filter-courses">
                  <Autocomplete
                    disabled={loading}
                    value={language[filterId.languageId]}
                    id="Language"
                    options={language}
                    getOptionLabel={(option) => option.title}
                    className={classes.autoWidth}
                    onChange={handleChangeLanguage}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Language"
                        variant="outlined"
                        size="small"
                        name="language"
                      />
                    )}
                  />
                </div>
              </div>
            </Typography>
          </Popover>
        </div>
      </div>
      {/* <div className="card-course-ec">
        <div className="card-course-i-cf">
          <div className="card-course-ic">
            <img src={courseCard1} />
          </div>
        </div>
      </div> */}
      <div className="card-course-ec">
        <div className="card-course-i-cf">
          {subjectData.length === 0 && !loading && <p>No Courses Found</p>}
          {subjectData.map((item, id) => {
            return (
              <div className="card-course-ic-o">
                <div onClick={() => redirectUrl(item.id)}>
                  <div className="card-course-ic-u">
                    {item.is_free_trial ? (
                      <div className="card_free_trail">
                        Free trial available
                      </div>
                    ) : (
                      <></>
                    )}

                    {item.image == null ? (
                      <img src={cardImg} />
                    ) : (
                      <img src={item.image} />
                    )}

                    {/* {item.banner.length === 0 && <img src={cardImg} />} */}
                    <div className="card-subject-ci">
                      {item.subject.length !== 0 &&
                        item.subject.map((sub_item, sub_index) => {
                          return (
                            <div className="card-subject-i">
                              <img src={sub_item.icon} />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="card-course-ic-l">
                    <div className="first-div-ic">
                      <p className="course-title-card-p">
                        {item.title}  { (item.subject.length) ? `: ${item.subject.length} Subjects` : ''}
                      </p>
                      <p className="course-sub-title-card-p">
                        {item.chapter_count} Chapters | {item.video_count}{" "}
                        Videos | {item.test_count} Tests
                      </p>
                    </div>
                    <div className="second-div-ic">
                      <p>
                        <span
                          style={{
                            fontSize: "17px",
                            color: "green",
                            fontWeight: "600",
                          }}
                        >
                          &#x20b9; {item.min_price}
                        </span>
                        {/* --/ */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {loading && (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}
      <SubjectDialog
        _data={subject}
        _handleShowDialog={handleShowDialog}
        _showDialog={showDialog}
        _target={'_self'}
      />
    </div>
  );
}

export default withRouter(ExploreCourses);

const top100Films = [
  {
    title: "Mathematics",
    id: 1,
  },
  {
    title: "Physics",
    id: 1,
  },
  {
    title: "Science",
    id: 1,
  },
  {
    title: "History",
    id: 1,
  },
  {
    title: "Geography",
    id: 1,
  },
  {
    title: "EVS",
    id: 1,
  },
];

const top100Filmss = [
  {
    title: "hello",
    id: 1,
  },
  {
    title: "js",
    id: 2,
  },
];

const typeData = [
  {
    id: 3,
    title: "School Courses",
    status: true,
    icon: "http://134.209.145.234:8001/files/main_category_media/school_courses_group_920.35x796.67px.png",
    text_color: "#be6016",
  },
  {
    id: 2,
    title: "Test Preparation",
    status: true,
    icon: "http://134.209.145.234:8001/files/main_category_media/testpreparation_group_920.35x796.67px.png",
    text_color: "#b01b64",
  },
  {
    id: 4,
    title: "Spoken English",
    status: true,
    icon: "http://134.209.145.234:8001/files/main_category_media/Spokenenglish_group_920.35x796.67px.png",
    text_color: "#bc080c",
  },
  {
    id: 5,
    title: "Test Series",
    status: true,
    icon: "http://134.209.145.234:8001/files/main_category_media/Testseries_group.png",
    text_color: "#06669f",
  },
];
