import React, { Fragment, useEffect, useReducer, useState } from "react";
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import overlayImage from "../../../Assets/Dashboard/Explore/overlayimage.png";
import "./Testimonials.css";
import { api_open } from "../../../Utils/Network";
import likeHeart from "../../../Assets/heartl.png";
import heart from "../../../Assets/heart.png";
import share from "../../../Assets/share.png";
function Testimonials() {
  const [testimonialsData, setTestimonialsData] = useState([]);

  useEffect(() => {
    getTestimonials();
  }, []);

  const getTestimonials = () => {
    api_open
      //   .get(`/cms/v1/demo_videos/`)
      .get(`/profile/v1/testimonials/`)
      .then((response) => {
        setTestimonialsData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="explore-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        <img src={overlayImage} className="rect-explore-overlay-c" />
        <div className="explore-hero-text-c" style={{ left: "40px" }}>
          <p>Testimonials</p>
        </div>
      </div>
      <div className="testimonials-c">
        {testimonialsData.map((item, idx) => {
          return (
            <div style={{ marginBottom: "40px" }}>
              <div className="testimonials-container">
                <div className="testimonials-user-avatar">
                  <img src={item.user.avatar} />
                </div>
                <div className="testimonials-user-name">
                  <p>
                    <b>
                      {item.user.first_name}
                      {item.user.last_name}
                    </b>
                  </p>
                  <span>2 days ago</span>
                </div>
              </div>
              <div className="user-testimonials">
                <p>{item.description}</p>
              </div>
              <div className="testimonials-share">
                <div className="testimonials-l">
                  {/* {item.is_like ? (<img src={likeHeart} style={{ width: "20px" }} />):(<img src={heart} style={{ width: "20px" }} />)} */}
                  {idx == 1 ? (
                    <img src={likeHeart} style={{ width: "20px" }} />
                  ) : (
                    <img src={heart} style={{ width: "20px" }} />
                  )}

                  <span>Like</span>
                </div>
                <div className="testimonials-s">
                  <img src={share} style={{ width: "20px" }} />{" "}
                  <span>Share</span>
                </div>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Testimonials;
