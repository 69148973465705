import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { setEdunguruApi, base_url, test_url } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function LeadWinds(props) {
    const { setToken, setStudentProfile, setUser } = useContext(UserCredsContext);
    const [messageData, setMessageData] = useState("");
    const [loading, setLoading] = useState(true);
    const [leadUrl, setLeadsUrl] = useState([]);
    const { thirdUrl } = useParams();


    useEffect(() => {
        console.log(thirdUrl, "ThirdUrl")

        let leadSplit = thirdUrl.split('&');
        setLeadsUrl(leadSplit);
        // let url_token = localStorage.getItem('edunguru_access_token')
        // LoginUser(url_token);
    }, [thirdUrl])

    console.log(leadUrl, "LEADSPLIT")

    // let leads = leadUrl[0]?.split('=')
    // console.log(leads, "llllll")
    // localStorage.setItem('lead', leads[1]);
    // props.history.push('/')

    return (
        <div>
            <div style={{ marginTop: '100px' }}>
                <p>Please Login to </p>
            </div>
        </div>
    )
}

export default LeadWinds