import React, { useContext, useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import "./LearningNavbar.css";
import avatar from "../../../Assets/Navbar/avatar.png";
import { greetUser } from "../../../Utils/Utils";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import insticap from "../../../Assets/Navbar/insticap.png";
import { api_open } from "../../../Utils/Network";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // boxShadow: "0 1px 2px 1px rgb(0 0 0 / 20%)",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function LearningNavbar() {
  const { user, studentProfile, setStudentGB } = useContext(UserCredsContext);
  console.log(studentProfile, "studentProfile");
  const [greetingMessage, setGreetingMessage] = useState("");
  const classes = useStyles();
  const [selectBoard, setSelectBoard] = React.useState();
  const [selectGrade, setSelectGrade] = React.useState();
  const [grade, setGrade] = useState([]);
  const [board, setBoard] = useState([]);
  console.log(selectBoard, "selectBoard");
  useEffect(() => {
    setSelectBoard(studentProfile?.board?.id);
    setSelectGrade(studentProfile?.grade?.id);
  }, [studentProfile]);
  const handleChange = (event, type) => {
    if (type == "grade") {
      setSelectGrade(event.target.value);
      var myObject = grade.find((x) => x.id == event.target.value);
      setStudentGB("grade", myObject);
    } else if (type == "board") {
      setSelectBoard(event.target.value);
      var myObject = board.find((x) => x.id == event.target.value);
      setStudentGB("board", myObject);
    }
  };

  useEffect(() => {
    getBoard();
    // getGrade();
  }, []);

  const getBoard = () => {
    api_open
      .get(`/base/v1/board`)
      .then((response) => {
        setBoard(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getGrade = (e) => {
    api_open
      .get(`/base/v1/board-grade-detail?board=${e}`)
      .then((response) => {
        setGrade(response.data.data[0].grade);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setGreetingMessage(greetUser);
  }, []);
  return (
    <div className="startlearning-navbar">
      <div className="user-greeting-head">
        <div>
          <img src={avatar} />
        </div>
        <div>
          <p>{greetingMessage}</p>
          <p style={{ textTransform: "capitalize" }}>{user.first_name}</p>
        </div>
      </div>
      {/* <div className="course-gb-c">
        <img src={insticap} />
        <div>
          <div className="input-field-navbar">
            <NativeSelect
              id="demo-customized-select-native"
              value={selectBoard}
              onChange={(e) => { handleChange(e, "board"); getGrade(e.target.value) }}
              style={{ width: "150px", marginRight: '10px' }}
              input={<BootstrapInput />}
            >
              {board.length > 0 && board.map((item, idx) => {
                return <option value={item?.id}>{item?.title}</option>;
              })}
            </NativeSelect>
            <NativeSelect
              id="demo-customized-select-native"
              value={selectGrade}
              onChange={(e) => handleChange(e, "grade")}
              style={{ width: "150px" }}
              input={<BootstrapInput />}
              placeholder="Select Grade"
            >
              {grade.length > 0 && grade.map((item, idx) => {
                return <option value={item.id}>{item.title}</option>;
              })}
            </NativeSelect>
          </div>
        </div> 
      </div>*/}
    </div>
  );
}

export default LearningNavbar;
