import React, { useEffect } from "react";
import { Chart } from "react-google-charts";
import "./ChartDemo.css";
import Donut from "./Donut";
import { Pie } from "@ant-design/charts";
import PieLarge from "./PieLarge";

function ChartDemo({ finalResult, datas, ...props }) {
  useEffect(() => {
  }, []);

  const demo = {
    correct: finalResult.correct_answer,
    wrong: finalResult.wrong_answer,
    total: finalResult.total_questions,
  }

  const demo1 = {
    correct: finalResult.correct_answer,
    wrong: finalResult.wrong_answer,
    total: finalResult.total_questions,
    color1: "#00851b",
    color2: "#bfbaba",
  }

  const demo2 = {
    correct: finalResult.wrong_answer,
    wrong: finalResult.correct_answer,
    total: finalResult.total_questions,
    color1: "#cc0026",
    color2: "#bfbaba",
  }

    const demo3 = {
      correct: finalResult.questions_not_attempted,
      wrong: finalResult.correct_answer,
      total: finalResult.total_questions,
      color1: "#e3dd32",
      color2: "#bfbaba",
    }

  const correct = (finalResult.correct_answer / finalResult.total_questions) * 100;
  const wrong = (finalResult.wrong_answer / finalResult.total_questions) * 100;
  const notattempt = (finalResult.questions_not_attempted / finalResult.total_questions) * 100;
  console.log(datas, finalResult, "FinalResult")
  return (
    <div>
      <div><h2 className="Your" style={{marginBottom: '0'}}>Your Score</h2></div>
      <div style={{ width: '62%', margin: 'auto' }}>

      <div className="Main_chart">
        <div>
        <p className="main_test clss">2/3 </p><p>Total Score</p>
        </div>
        
        <PieLarge _type={demo} final={finalResult} />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button className='btn_backStats'>Solution</button>
          <button onClick={() => datas(2)} className='btn_backStats'>Statistics</button>
        </div>
      </div>
        

        <div className="chart_timer">
          <div style={{textAlign: 'center'}}>
            <p className="clss bold_texts">00:00:10</p>
            <p className="clss">Total Time Taken</p>
          </div>
          <div style={{textAlign: 'center'}}>
            <p className="clss bold_texts">00:00:10</p>
            <p className="clss">Time per question</p>
          </div>
        </div>
      </div>
      <div className="top-circle-data">

      </div>

      <div className="correct-incorrect-cirlce">
        <div className="ci_main">
          <div className="ci-ci-c-f">
            <div className="ci_c_circle">
              <Donut _type={demo1} />
            </div>
            <div>
              <p className="clss">{correct.toFixed(2)} %</p>
            </div>
            <div>
              <p className="clss">Correct Answer</p>
            </div>
          </div>
          <div className="ci-ci-c-f">
            <div className="ci_c_circle">
              <Donut _type={demo3} />
            </div>
            <div>
              <p className="clss">{notattempt.toFixed(2)} %</p>
            </div>
            <div>
              <p className="clss">Not attempted</p>
            </div>
          </div>
          <div className="ci-ci-c-f">
            <div className="ci_c_circle">
              <Donut _type={demo2} />
            </div>
            <div>
              <p className="clss">{wrong.toFixed(2)}%</p>
            </div>
            <div>
              <p className="clss">Incorrect Answer</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{ display: "flex" }}>
        <Chart
          width={"500px"}
          height={"500px"}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["Task", "Hours per Day"],
            ["Type 1", 11],
            ["Type 2", 2],
          ]}
          options={{
            title: "Test Summary",
            // Just add this option
            pieHole: 0.4,
            animation: {
              startup: true,
              easing: "linear",
              duration: 1500,
            },
          }}
          rootProps={{ "data-testid": "3" }}
        />{" "}
        <Chart
          width={"500px"}
          height={"500px"}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["Task", "Hours per Day"],
            ["Type 1", 20],
            ["Type 2", 10],
          ]}
          options={{
            title: "Test Summary",
            // Just add this option
            pieHole: 0.4,
          }}
          rootProps={{ "data-testid": "3" }}
        />{" "}
      </div> */}

      {/* <div className="Learning-progress-card-start-f">
        <div
          className="Learning-progress-card-start"
          style={{ margin: "15px auto" }}
        >
          <p className="start-learning-header" style={{ marginBottom: "25px" }}>
            Time Taken Per Question
          </p>
          <div className="filler-list-c">
            <span>1-E</span>
            <div className="mycourse-filler-c" style={{ width: "90%" }}>
              <div
                className="mycourse-filler-inner-c"
                style={{ background: "#5A5D9A", width: "20%" }}
              ></div>
            </div>
            <span>1 sec</span>
          </div>
          <div className="filler-list-c">
            <span>2-E</span>
            <div className="mycourse-filler-c" style={{ width: "90%" }}>
              <div
                className="mycourse-filler-inner-c"
                style={{ background: "#5A5D9A", width: "70%" }}
              ></div>
            </div>
            <span>2 sec</span>
          </div>
          <div className="filler-list-c">
            <span>3-E</span>
            <div className="mycourse-filler-c" style={{ width: "90%" }}>
              <div
                className="mycourse-filler-inner-c"
                style={{ background: "#5A5D9A", width: "40%" }}
              ></div>
            </div>
            <span>5 sec</span>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ChartDemo;
