import React from "react";
import { useStopwatch } from "react-timer-hook";
import { useTimer } from 'react-timer-hook';

function MyStopwatch({ sub,expiryTimestamp, ...props }) {  
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } = useTimer({ expiryTimestamp, onExpire: () => sub(2) });
    // useStopwatch({ autoStart: true });
  console.log(sub, expiryTimestamp, "reset")
  return (
    <div style={{ textAlign: "center" }}>
      {/* <h1>react-timer-hook</h1>
      <p>Stopwatch Demo</p> */}
      <div style={{ fontSize: "30px" }}>
        {/* <span>{days}</span>: */}
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
      {/* <p>{isRunning ? "Running" : "Not running"}</p> */}
      {/* <button onClick={start}>Start</button>
      <button onClick={pause}>Pause</button>
      <button onClick={reset}>Reset</button> */}
    </div>
  );
}

export default function StopWatch({data, sub, ...props}) {
  console.log(data, sub, "SUBDATA")
  // const time = data;
  let time_to_test = data;
  time_to_test = time_to_test* 60;
  const time = new Date();
  time.setSeconds(time.getSeconds() + time_to_test);
  return (
    <div>
      <MyStopwatch expiryTimestamp={time} sub={sub}/>
    </div>
  );
}
