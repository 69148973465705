import React, { useEffect, useState } from 'react'
import { Snackbar, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styles from './index.module.css'
import phone from '../../Assets/Contact/phone.png'
import email from '../../Assets/Contact/email.png'
import location from '../../Assets/Contact/location.png'
import girl from '../../Assets/Contact/girl_shadowofcycle.png'
import Wave1 from '../../Assets/Contact/rect1.png'
import Wave2 from '../../Assets/Contact/rect0.png'
import face from "../../Assets/footer/fb.png"
import insta from "../../Assets/footer/insta.png"
import link from "../../Assets/footer/link.png"
import yt from "../../Assets/footer/yt.png"
import twit from "../../Assets/footer/twit.png"
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core'
import { api_open } from '../../Utils/Network';

function Contact() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [errors, setErrors] = useState({ name: "" });
    const [msg, setMsg] = useState("");
    const [userData, setUserData] = useState({
        c_fname: "",
        c_lname: "",
        c_phone: "",
        c_email: "",
        c_message: "",
    });
    var temp = {};

    const validate = () => {
        let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        var validEmail =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let returnValue = true;

        temp.c_phone = num.test(userData.c_phone)
            ? ""
            : "Please Enter valid number";
        temp.c_email = userData.c_email.match(validEmail)
            ? ""
            : "Please Enter valid email";
        temp.c_message =
            userData.c_message === "" ? "Please enter the message" : "";

        setErrors({
            ...temp,
        });
        var found = Object.keys(temp).filter(function (key) {
            return temp[key].includes("Please");
        });
        if (found.length) {
            console.log(temp);
            returnValue = false;
        }
        return returnValue;
    };

    const SubmitData = () => {
        if (!validate()) return null;
        var payload = {
            first_name: userData.c_fname,
            last_name: userData.c_lname,
            mobile: userData.c_phone,
            email: userData.c_email,
            message: userData.c_message,
        };
        api_open
            .post("edunguru_footer/v1/contact_us_register/", payload)
            .then((x) => {
                setMsg("Thank You! Your submission has been sent.");
                setUserData({
                    c_fname: "",
                    c_lname: "",
                    c_phone: "",
                    c_email: "",
                    c_message: "",
                });
                document.getElementById("outlined-search").value = "";
                document.getElementById("outlined-searchs").value = "";
                document.getElementById("outlined-multiline-static").value = "";
            })
            .catch((error) => console.log(error));
    };

    const handle = (event) => {
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    return (
        <>
            <div>
                <div>
                    <div className={styles.headers}>
                        <h1 style={{ color: '#FFFFFF' }} className={styles.head}>Contact Us</h1>
                        <p style={{ color: '#FFFFFF' }} className={styles.pars}>Get in touch with us and we will get back to you as soon as possible!</p>
                    </div>
                    <div className={styles.main_contact}>
                        <div className={styles.contact_info}>
                            <div>
                                <h3 style={{ color: '#FFFFFF' }}>Contact Information</h3>
                                <p style={{ color: '#FFFFFF', maxWidth: '200px' }}>Fill up the form and our team wil get back to you within 24 hours</p>
                            </div>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <img src={phone} alt='email' style={{ marginRight: '40px' }} />
                                    <div>
                                        <p style={{ color: '#FFFFFF', margin: '3px' }}>1800 891 2868</p>
                                        <p style={{ color: '#FFFFFF', margin: '3px' }}>Tollfree number</p>
                                        <p style={{ color: '#FFFFFF', margin: '3px' }}>Time - 9:30 AM  to 6  PM</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <img src={email} alt='email' style={{ marginRight: '40px' }} />
                                    <div>
                                        <p style={{ color: '#FFFFFF', margin: '3px' }}>customercare@edunguru.com</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <img src={location} alt='email' style={{ marginRight: '40px' }} />
                                    <div>
                                        <p style={{ color: '#FFFFFF', margin: '3px' }}>Edunguru Inc. , UPDC Office, Sahara
                                            Shaher, Vipul Khand - 1 Gomti Nagar,
                                            Behind - Ambedkar Park
                                            Lucknow- 226010 (UP)</p>
                                    </div>
                                </div>
                                <div className='follow_us'>
                                    <div><h3 className='white_col'>Follow us</h3></div>
                                    <div className='ft_icons'>
                                        <ul>
                                            {/* <li><img src={link} /></li>
                                            <li><img src={insta} /></li>
                                            <li><img src={twit} /></li>
                                            <li><img src={face} /></li>
                                            <li><img src={yt} /></li> */}
                                            <li><a href='https://www.linkedin.com/company/edunguruonline/' target='_blank'><img src={link} /></a></li>
                                            <li><a href='https://www.instagram.com/edunguru/' target='_blank'><img src={insta} /></a></li>
                                            <li><a href='https://twitter.com/edunguru' target='blank'><img src={twit} /></a></li>
                                            <li><a href='https://www.facebook.com/edunguruindia' target='blank'><img src={face} /></a></li>
                                            <li><a href='https://www.youtube.com/c/Edunguru' target='blank'><img src={yt} /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.forms}>
                            <div className={styles.form_content_fields}>
                                <div className={styles.form_boxes}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="First Name"
                                        variant="outlined"
                                        style={{ width: '100%', marginRight: '10px', marginBottom: '20px' }}
                                        onChange={handle}
                                        name="c_fname"
                                    />
                                    <p style={{ margin: "0", color: "red" }}>{errors.c_fname}</p>
                                </div>
                                <div className={styles.form_boxes}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Last Name"
                                        variant="outlined"
                                        style={{ width: '100%', marginBottom: '20px' }}
                                        onChange={handle}
                                        name="c_lname"
                                    />
                                    <p style={{ margin: "0", color: "red" }}>{errors.c_lname}</p>
                                </div>
                            </div>
                            <div className={styles.form_content_fields}>
                                <div className={styles.form_boxes}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="E-mail"
                                        variant="outlined"
                                        style={{ width: '100%', marginRight: '10px', marginBottom: '20px' }}
                                        onChange={handle}
                                        name="c_email"
                                    />
                                    <p style={{ margin: "0", color: "red" }}>{errors.c_email}</p>
                                </div>
                                <div className={styles.form_boxes}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Mobile"
                                        variant="outlined"
                                        style={{ width: '100%', marginBottom: '20px' }}
                                        onChange={handle}
                                        name="c_phone"
                                    />
                                    <p style={{ margin: "0", color: "red" }}>{errors.c_phone}</p>
                                </div>
                            </div>

                            <span style={{ color: '#272766', fontWeight: 'bold' }}>Message *</span><br /><br />
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                variant="outlined"
                                style={{ width: '100%' }}
                                onChange={handle}
                                name="c_message"
                            />
                            <p style={{ margin: "0", color: "red" }}>{errors.c_message}</p>

                            <div style={{ margin: '10px', textAlign: 'center' }}>
                                <Button variant="contained" className={styles.btns} onClick={SubmitData}>Send Message</Button>
                            </div>

                        </div>
                    </div>
                    <div>

                        <div className={styles.waves}>
                            <img src={girl} alt='girl' className={styles.girls} />
                            <img src={Wave2} alt="Wave2" className={styles.waveOut} />
                            <img src={Wave1} alt="Wave1" className={styles.waveIn} />
                        </div>
                    </div>

                </div>
            </div>
            {msg && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={() => setMsg("")}
                    autoHideDuration={5000}
                    message={msg}
                >
                    <Alert severity="success">{msg}</Alert>
                </Snackbar>
            )}
        </>
    )
}

export default Contact