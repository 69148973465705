export const greetUser = () => {
  var myDate = new Date();
  var hrs = myDate.getHours();
  var greet;
  if (hrs < 12) greet = "Good Morning";
  else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
  else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
  return greet;
};

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

export function secondsToMinutes(sec) {
  var minutes = Math.floor(sec / 60);
  var seconds = sec - minutes * 60;
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

export function calculateBar(total, viewed) {
  var percentage = (viewed / total) * 100;
  return percentage;
}

export function getPaymentMethod(_id, _array) {
  console.log(_id, _array, "mmm");
  var getObject = _array.findIndex((x) => x.id === _id);
  return getObject;
}

export function getDateFormat(date) {
  console.log(date,'expiry date')
  if (date === null) {
    return null
  }
  var str = date;
  var Index = str.indexOf('T');
  str = str.substring(0, Index);
  var array = str.split('-');
  var reversedArray = array.reverse()
  var newArray = array.join('-')
  return newArray
}

export const getDateString = (_date) => {
  var myDate = _date.split(" ");
  var getDateListing = myDate[0].split("-");
  return getDateListing;
};