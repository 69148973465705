import React, { useContext, useEffect, useState, useRef } from "react";
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import overlayImage from "../../../Assets/Dashboard/Explore/overlayimage.png";
import "./TotalOrder.css";
import { Button, TextField } from "@material-ui/core";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import {
  useParams,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import { api_open, api_token } from "../../../Utils/Network";
import axios from "axios";
import RedirectDialog from "./RedirectDialog";
import _ from 'lodash';
import OrderPopUp from "./OrderPopUp";
import SearchIcon from '@material-ui/icons/Search';

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    // script.onload = display.Razorpay;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function TotalOrder(props) {
  const {
    user,
    purchaseResponse,
    studentProfile,
    purchaseData,
    setPurchaseData,
    setSahajLink,
  } = useContext(UserCredsContext);
  const [razorpayData, setRazorPayData] = useState(null);
  const { id: param_id, boolean: is_purchased } = useParams();
  const [sahajEncode, setSahajEncode] = useState("");
  const [newAddress, setNewAddress] = useState({
    add_one: "",
    add_two: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
  });
  const [showDialog, setShowDialog] = useState(false);
  const [sahajData, setSahajData] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [courses, setCourse] = useState({});
  const [open, setOpen] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [uatRetail, setUatRetail] = useState('');
  const [coupons, setCoupon] = useState([]);
  const [couponId, setCouponID] = useState({});
  const [afterCouponData, setAfterCouponData] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [addressObject, setAddressObject] = useState({
    city: null,
    country: null,
    state: null,
  });

  //Search Imput
  const [input, setInput] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    // initialize debounce function to search once user has stopped typing every half second
    inputRef.current = _.debounce(onSearchText, 1000);
  }, []);

  useEffect(() => {
    getcourse();
    getCoupon(param_id);
    let code = '';
    if (window.location.href.indexOf("localhost") > -1) {
      code = "uat2";

    } else if (window.location.href.indexOf("edunguru2") > -1) {
      code = "uat2";

    } else if ((window.location.href.indexOf("staging") > -1) || (window.location.href.indexOf("edunguru") > -1)) {
      code = 'retail';

    }
    setUatRetail(code)
  }, [])

  const getcourse = () => {
    api_token.get(`/cms/v1/course/${param_id}/`)
      .then(response => {
        setCourse(response.data.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  // console.log(props.location.state.id, "propsprops")
  const getCoupon = (ids) => {
    api_token
      .get(`base/v1/coupon/?courses=${ids}`)
      .then((res) => {
        setCoupon(res.data.data);
      })
      .catch(err => console.log(err))
  }
  const handleOpenC = (_boolean) => {
    setOpenC(_boolean);
  };
  const handleOpen = (_boolean) => {
    setOpen(_boolean);
    setNewAddress({
      add_one: "",
      add_two: "",
      landmark: "",
      pincode: "",
      city: "",
      state: "",
    });
    setAddressObject({
      city: null,
      country: null,
      state: null,
    });
  };
  const handleShowDialog = (_value) => {
    setShowDialog(_value);
  };
  async function displayRazorPay(amountData, apiCourseidData, validityId) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("razorpay sdk failed to load. Are you online?");
      return;
    }

    let current_url = window.location.href;
    if (
      current_url.includes("localhost") ||
      current_url.includes("165.22.214.137") ||
      current_url.includes("edunguru2.g10consultancy.com")
    ) {
      var razorpay_key = "rzp_test_auECWNj3vbIA10";
    } else {
      var razorpay_key = "rzp_live_gdFNhp4ajqBhYo";
    }
    var courseprice = amountData.order.amount;
    const options = {
      key: razorpay_key,
      amount: courseprice,
      currency: "INR",
      name: "Edunguru",
      description: `${purchaseData.title}`,
      image: "",
      order_id: `${amountData.order.order_id}`,
      // "callback_url":history.push("/my-space"),
      // "redirect": true,
      handler: function (response) {
        // alert(response);
        // setPaymentId(response.razorpay_payment_id);
        // var intValue = parseInt(courseId)
        console.log(response, "response");
        getpurchase(
          response.razorpay_order_id,
          response.razorpay_payment_id,
          apiCourseidData,
          validityId,
          amountData,
        );
      },
      prefill: {
        name: `${user.first_name}`,
        email: `${user.email}`,
        contact: `${user.phone}`,
      },
    };

    var paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const getpurchase = (orderId, rpid, apiCourseidData, validityId, amt) => {
    console.log(orderId, rpid, apiCourseidData, validityId, "orderIdsss");
    let leads = localStorage.getItem('lead')
    let leadPlat = 1;
    if (leads === undefined) {
      leads = '';
      leadPlat = null;
    };

    api_token
      .patch(`administration/v2/payment/${amt.id}/update_purchased_course/`, { transaction_id: rpid })
      .then((r) => {
        if (r.data.data.id) {
          setShowDialog(true);
        } else {
          alert("Please Check the details")
        }
      })
      .catch((e) => {
        console.log(e, "e");
      });

    // let leads = localStorage.getItem('leads')
    // let leadPlat = 1;
    // if(!leads){
    //   leads = '';
    //   leadPlat = null;
    // };
    // let cUsedamt;
    // if(afterCouponData?.coupon_used_amt){
    //   cUsedamt = afterCouponData.coupon_used_amt;
    // }else{
    //   cUsedamt = 0;
    // }

    // var data = {
    //   course: param_id,
    //   validity_id: purchaseData.id,
    //   transaction_id: rpid,
    //   order_id: razorpayData.id,
    //   shipping_address_id: 121017,
    //   user_device: `${user.first_name} web`,
    //   is_coupon_applied: afterCouponData?.is_coupon_applied || false,
    //   user_os: "web",
    //   course_amt: afterCouponData?.course_amt || 0,
    //   lead_id: leads,
    //   lead_patform: leadPlat,
    //   coupon_used_amt: cUsedamt,

    //   platform: 1,
    //   tax: purchaseResponse.tax,

    //   user_version: 6.1,
    //   discount_amt: afterCouponData?.discount_amt || 0,
    // };
    // api_token.post(`payment/v1/purchase/`, data).then((response) => {
    //   if (response.status === 201) {
    //     setShowDialog(true);
    //   }
    //   // setOpen(true);
    //   // setSuccess(true);
    // });
  };

  useEffect(() => {
    order();
  }, [purchaseData]);

  useEffect(() => {
    getUserAddress();
    // getLocation();
  }, []);

  const getLocation = (_pin) => {
    api_token(`/profile/v1/geolocator/?zipcode=${_pin}`)
      .then((response) => {
        const { city, country, state } = response.data.data;
        setNewAddress({
          ...newAddress,
          city: city.title,
          country: country.title,
          state: state.title,
          pincode: _pin,
        });
        setAddressObject({ city, country, state });
      })
      .catch((error) => { });
  };

  const getUserAddress = () => {
    api_token
      .get(`/auth/v1/student/account/me/`)
      .then((response) => {
        if (response.data.data.address.length > 0) {
          setUserAddress(response.data.data.address[0]);
        } else {
          setUserAddress(null);
        }
      })
      .catch((error) => { });
  };
  const order = () => {
    if (user.is_tp_user) {
      api_token
        .get(`/payment/v1/tp_gateway?amount=${purchaseResponse.grand_total}`)
        .then((response) => {
          const { enCode } = response.data.data;
          setSahajEncode(enCode);
        })
        .catch((error) => { });
    }
    // else {
    //   const api_data = {
    //     validity_id: purchaseData.id,
    //     amount: purchaseResponse.grand_total,
    //   };
    //   api_token.post("/payment/v1/order/", api_data).then((response) => {
    //     setRazorPayData(response.data.data);
    //   });
    // }
  };

  const getFreePay = () => {
    let coupon;
    if (couponId?.id) {
      coupon = couponId.id;
    } else {
      coupon = null;
    }

    let data = {
      
      user_device: `${user.first_name} web`,
      is_coupon_applied: 0,
      user_os: "",
      course_amt: 0,
      validity: purchaseData.id,
      coupon_used_amt: purchaseResponse?.coupon_used_amt,
      grand_total: purchaseResponse?.grand_total,
      platform: 1,
      tax: 0,
      user: user.id,
      course: param_id,
      coupon: coupon,
      coupon_used: afterCouponData?.is_coupon_applied,
      user_version: 6.1,
      discount_amt: 0,
      payment_method: 2,
      payment_status: 4,
      shipping_address_id: userAddress?.id,
    };
    api_token
      .post("payment/v1/purchase_free_course/", data)
      .then((res) => {
        setShowDialog(true);
      })
      .catch((err) => alert("Oppps something went wrong"));
  }

  const paymentGateway = () => {
    if (purchaseData.final_price == 0) {
      freeApiCall();
      return;
    }

    if(purchaseResponse?.grand_total <= 0){
      getFreePay();
      return;
    }
    if (user.is_tp_user) {
      displaySahaj();
    } else {
      payRazor()
      // displayRazorPay();
    }
  };

  const payRazor = () => {
    // debugger;
    let coupon;
    if (couponId?.id) {
      coupon = couponId.id;
    } else {
      coupon = null;
    }

    let leads = localStorage.getItem('lead')
    let lead_plat = localStorage.getItem('lead_platform');
    let leadPlat;
    if(lead_plat){
      leadPlat = 0;
    }else{
      leadPlat = 1;
    }
    
    if (!leads) {
      leads = '';
      leadPlat = null;
    };

    let datas = {
      lead_id: leads,
      lead_patform: leadPlat,
      amount: purchaseResponse.grand_total,
      course: param_id,
      validity_id: props.location.state.id,
      shipping_address_id: userAddress?.id,
      user_device: `${user?.first_name} web`,
      coupon_used: afterCouponData?.is_coupon_applied,
      user_os: "web",
      course_amt: afterCouponData?.course_amt,
      coupon_used_amt: afterCouponData?.coupon_used_amt,
      platform: 1,
      tax: afterCouponData?.tax,
      user_version: 6.1,
      discount_amt: afterCouponData?.discount_amt,
      user: user.id,
      coupon: coupon,
    };
    api_token
      .post("administration/v2/payment/", datas)
      .then((res) => {
        console.log(res.data, "payment");
        displayRazorPay(res.data.data);
      })
      .catch((err) => console.log(err));
  }
  const freeApiCall = () => {
    const data = {
      user_device: `${user.first_name} web`,
      is_coupon_applied: 0,
      user_os: "",
      course_amt: 0,
      validity: purchaseData.id,
      coupon_used_amt: 0,
      grand_total: 0,
      platform: 1,
      tax: 0,
      user: user.id,
      course: param_id,
      user_version: 6.1,
      discount_amt: 0,
      payment_method: 2,
      payment_status: 4,
      shipping_address_id: userAddress.id,
    };
    api_token
      .post(`payment/v1/purchase_free_course/`, data)
      .then((response) => {
        setShowDialog(true);
      })
      .catch((error) => { });
  };
  const displaySahaj = () => {

    let code = '';
    if (window.location.href.indexOf("localhost") > -1) {
      code = "uat2";

    } else if (window.location.href.indexOf("edunguru2") > -1) {
      code = "uat2";

    } else if ((window.location.href.indexOf("staging") > -1) || (window.location.href.indexOf("edunguru") > -1)) {
      code = 'retail';

    }
    // window.open(
    //   `https://uat2.sahaj.co.in/web/guest/commonthirdpartyurl?actionType=payment_edunguru&sp_code=edunguru&${sahajEncode}`
    // );
    // var payload = {
    //   enCode: `thirdParty_unique_id=FFQ3GBCXUFG7&txn_amount=2000&sm_id=1919010100002661&service_provider_id=707`
    // }
    // fetch('https://uat2.sahaj.co.in/web/guest/commonthirdpartyurl?actionType=payment_edunguru&sp_code=edunguru', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(payload)
    // }).then(res => res.json())
    //   .then(res => console.log(res));
    var bodyFormData = new FormData();
    bodyFormData.append("enCode", `${sahajEncode}`);
    axios({
      method: "post",
      url: `https://${code}.sahaj.co.in/web/guest/commonthirdpartyurl?actionType=payment_edunguru&sp_code=edunguru`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        setSahajData(response.data);
        // window.open(response.data,'name','width=600,height=400')
        // var newWin = window.open(``);
        // newWin.document.write(response.data);
        var newWin = window.open(`https://uat2.sahaj.co.in`);
        newWin.document.write(response.data);
        setSahajLink(response.data);
        // props.history.push('/sahaj')
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };
  const handleChange = (e, _name) => {
    var name = e.target.name;
    var value = e.target.value;
    setNewAddress({ ...newAddress, [name]: value });
    if (name == "pincode") {
      setTimeout(() => {
        if (value.length == 6) {
          getLocation(value);
        }
      }, 200);
    }
  };

  const searchFilter = (e) => {
    const input = e.target.value;
    setInput(input);
    inputRef.current(input);
  }

  const onSearchText = (input) => {
    setIsLoading(true);
    console.log(input, "inps")
    if(input == ''){
      getCoupon(param_id);
    }else{
      api_token
      .get(`/base/v1/search_coupons/?code=${input}`)
      .then((res) => {
        setIsLoading(false);
        setCoupon(res.data.data);
      })
      .catch(err => console.log(err));
    }
  };

  const handleSubmitC = () => {
    let data = {
      coupon: couponId.id,
      validity: +props.location.state.id,
      course: param_id,
    }
    api_token
      .post(`payment/v1/grand_total_price/`, data)
      .then((res) => {
        // debugger;
        if (res.data.message) {
          alert(res.data.message)
        }
        setAfterCouponData(res.data.data);
        setPurchaseData(res.data.data, "response");
      })
      .catch(err => console.log(err))
  };
  const handleSubmit = () => {
    postAddress();
  };
  const postAddress = () => {
    const data = {
      zipcode: newAddress.pincode,
      landmark: newAddress.landmark,
      line_2: newAddress.add_two,
      state_id: addressObject.state.id,
      line_1: newAddress.add_one,
      country_id: addressObject.country.id,
      city_id: addressObject.city.id,
    };
    api_token
      .post(`/profile/v1/student/address/`, data)
      .then((response) => {
        setUserAddress(response.data.data);
        setOpen(false);
        setNewAddress({
          add_one: "",
          add_two: "",
          landmark: "",
          pincode: "",
          city: "",
          state: "",
        });
        setAddressObject({
          city: null,
          country: null,
          state: null,
        });
      })
      .catch((error) => { });
  };
  console.log(razorpayData, "razorpayData");
  console.log(userAddress, "userAddress");
  console.log(purchaseData, "purchaseData");
  console.log(coupons, "coupons");

  console.log(couponId, "couponID")
  // console.log(savetoDB, "savetoDB")
  return (
    <div style={{ position: "relative" }}>
      <div className="explore-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        <img src={overlayImage} className="rect-explore-overlay-c" />
        <div className="explore-hero-text-c">
          <p>Order</p>
        </div>
      </div>
      <RedirectDialog
        showDialog={showDialog}
        handleShowDialog={handleShowDialog}
        param_id={param_id}
        descp={purchaseData}
      />
      <div className="order-details-ui-coup ">
        <div className="order-subject-name-title">
          <h3 style={{ width: "60%" }}>{purchaseData.title}</h3>
          <p className="order-highlight-c">{purchaseData.mode.title}</p>
          <div className="order-flex-div-c grey-spacing-order-c">
            <p>{purchaseData.validity_period.title}</p>
            <p>{purchaseData.final_price}</p>
          </div>
        </div>
        <div className="order-add-c">
          <p className="order-highlight-c">Delivery Address</p>
          <div className="order-flex-div-c grey-spacing-order-c">
            <div>
              <p>Deliver To:</p>

              {(userAddress != undefined && userAddress.hasOwnProperty("id")) ? (
                <p>
                  {" "}
                  {userAddress.line_1} {userAddress.line_2}{" "}
                  {userAddress.landmark} {userAddress.city.title}{" "}
                  {userAddress.state.title} {userAddress.country.title}{" "}
                  {userAddress.zipcode}
                </p>
              ) : (
                <p>-</p>
              )}
              {userAddress === null && <p style={{ color: "red" }}> Please provide Address</p>}
            </div>
            <div>
              <Button variant="outlined" onClick={() => setOpen(true)}>
                Change
              </Button>
            </div>
          </div>
        </div>
        <div className="order-add-c">
          <p className="order-highlight-c">Apply coupon code</p>
          <div className="order-flex-div-c grey-spacing-order-c">
            <div>
              <p>Select Coupon code</p>
              {couponId?.id && <p style={{color: 'red'}}>Coupon Applied : {couponId?.title}</p>}
            </div>
            <div>
              <Button variant="outlined" onClick={() => setOpenC(true)}>
                View Available Coupon
              </Button>
            </div>
          </div>
        </div>
        <div className="order-price-c">
          <p className="order-highlight-c">Price Details</p>
          <div className="order-flex-div-c grey-spacing-order-c">
            <div>
              <p>Total Price</p>
              <p>Tax</p>
              <p>Discount</p>
              {/* <p>Offer</p> */}
              <p>
                <b>Grand Total</b>
              </p>
            </div>
            <div>
              <p>&#8377; {purchaseResponse.course_amt}</p>
              <p>&#8377; {purchaseResponse.tax}</p>
              <p>&#8377; {purchaseResponse.coupon_used_amt}</p>
              {/* <p>&#8377; 0.0</p> */}
              <p>
                <b>&#8377; {purchaseResponse.grand_total}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="order-bottom-footer">
        <div className="order-flex-div-c">
          <div>
            <p>
              <b>&#8377; {purchaseResponse.grand_total}</b>
            </p>
          </div>
          <div style={{ margin: "8px 0px" }}>
            {user.is_tp_user && (
              <>
                {/* <form
                target="_blank"
                action="https://uat2.sahaj.co.in/web/guest/commonthirdpartyurl?actionType=payment_edunguru&sp_code=edunguru"
                method="POST"
                id="form"
                enctype="multipart/form-data"
              >
                <input type="hidden"  name="encode" value={sahajEncode} />
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={paymentGateway}
                  type="submit"
                  value="Submit request"
                  value="submit"
                >
                  Pay Now
                </Button>
              </form> */}
                <form
                  action={`https://${uatRetail}.sahaj.co.in/web/guest/commonthirdpartyurl?actionType=payment_edunguru&sp_code=edunguru`}
                  method="POST"
                  target="_blank"
                >
                  {userAddress !== null && <input
                    type="hidden"
                    id="enCode"
                    name="enCode"
                    value={`${sahajEncode}&param3=${param_id}&param4=${purchaseData.id}&param5=${userAddress.id}&param6=${purchaseResponse.grand_total}&param7=chrome&param8=linux&param9=11&param10=200&param11=false&param12=false&param13=false&param14=${courses?.title}`}
                  />}

                  {/* <input type="submit" value="Submit" /> */}
                  {userAddress !== null && <Button
                    variant="contained"
                    color="primary"
                    type="submit"

                  >
                    Submit
                  </Button>}
                  {userAddress === null && <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={true}
                  >
                    Submit
                  </Button>}
                </form>
              </>
            )}
            {!user.is_tp_user && (
              <Button
                variant="contained"
                color="primary"
                onClick={paymentGateway}
              >
                Pay Now
              </Button>
            )}
          </div>
        </div>
      </div>
      {/* <div className="abcd">
        <div dangerouslySetInnerHTML={{ __html: sahajData }} style={{ height: '10000px' }}></div>
      </div> */}
      <OrderPopUp
        _open={openC}
        _handleOpen={handleOpenC}
        formSubmit={handleSubmitC}
        form_title="Available Coupons"
      >
        <div className="coupon-search">
          <SearchIcon style={{marginLeft: '10px'}} />
          <input onChange={(e) => searchFilter(e)} className="inputSearch" />
        </div>
        {(coupons.length > 0) ?
          coupons && coupons.map((val, index) => (
            <div className={(couponId.id === val.id) ? "coupon_container backSelected" : "coupon_container"} onClick={() => setCouponID(val)}>
              <p style={{ margin: '2px' }}>Title: {val?.title}</p>
              <p style={{ margin: '2px' }}>Coupon Code: {val?.code}</p>
              <p style={{ margin: '2px' }}>Description: {val?.description}</p>
            </div>
          )) : <p>No Coupon Available</p>}

          {/* {!coupons && <p>Loading...</p>} */}
      </OrderPopUp>
      <OrderPopUp
        form_title="Shipping Address"
        _open={open}
        _handleOpen={handleOpen}
        formSubmit={handleSubmit}
      >
        <div>
          <TextField
            id="outlined-password-input"
            label="Address Line 1"
            type="text"
            autoComplete=""
            variant="outlined"
            value={newAddress.add_one}
            style={{ width: "100%", marginBottom: "10px" }}
            name="add_one"
            onChange={(e) => handleChange(e, "add_one")}
          />
          <TextField
            id="outlined-password-input"
            label="Address Line 2"
            type="text"
            autoComplete=""
            name="add_two"
            value={newAddress.add_two}
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => handleChange(e, "add_two")}
          />
          <TextField
            id="outlined-password-input"
            label="Nearest Bus stop/ landmark"
            type="text"
            name="landmark"
            autoComplete=""
            value={newAddress.landmark}
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => handleChange(e, "landmark")}
          />
          <TextField
            id="outlined-password-input"
            label="Pincode"
            type="number"
            autoComplete=""
            value={newAddress.pincode}
            name="pincode"
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => handleChange(e, "pincode")}
          />
          <TextField
            id="outlined-password-input"
            type="text"
            disabled
            autoComplete=""
            placeholder="city"
            value={newAddress.city}
            name="city"
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <TextField
            id="outlined-password-input"
            type="text"
            name="state"
            value={newAddress.state}
            disabled
            placeholder="state"
            autoComplete=""
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <TextField
            id="outlined-password-input"
            type="text"
            name="country"
            placeholder="country"
            value={newAddress.country}
            disabled
            autoComplete=""
            variant="outlined"
            style={{ width: "100%", marginBottom: "10px" }}
          />
        </div>
      </OrderPopUp>
    </div>
  );
}

export default withRouter(TotalOrder);


// value={`${sahajEncode}&param3=${param_id}&param4=${purchaseData.id}&param5=${userAddress}&param6=${purchaseResponse.grand_total}&param7=chrome&param8=linux&param9=11&param10=200&param11=false`}
// value={`${sahajEncode}&param3=22&param4=235&param5=125324&param6=2220&param7=chrome&param8=linux&param9=11&param10=200&param11=false`}
