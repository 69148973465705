import React, { useEffect, useState } from "react";
import "./Note.css";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CardCarousel from "../../../Components/CardCarousel/CardCarousel";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { useParams, withRouter } from "react-router";
import { api_token } from "../../../Utils/Network";
import { Document, Page, pdfjs } from "react-pdf";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { CircularProgress } from "@material-ui/core";

const url =
  "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Note({ params_id, _data, ...props }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [notesListing, setNotesListing] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [datas, setDatas] = useState([]);
  const [distype, setDisType] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsloading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    count: null,
    max_pages: null,
    next_page: null,
    page: null,
    previous_page: null,
    total_count: null,
  });

  const handleClickOpen = (vals) => {
    setOpen(true);
    setDatas(vals)
    setDisType(1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (_data) {
  //     setNotesListing(_data);
  //   }
  // }, [_data]);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  useEffect(() => {
    getChapterVideos(1);
  }, [])

  const getChapterVideos = (_page_no) => {
    // setIsloading(true);
    api_token
      .get(
        `/cms/v1/chapter_notes_listing/?course=${params_id}&page=${_page_no}&page_size=10`
      )
      .then((response) => {
        const {
          count,
          max_pages,
          next_page,
          page,
          previous_page,
          total_count,
        } = response.data;
        setPaginationData({
          count,
          max_pages,
          next_page,
          page,
          previous_page,
          total_count,
        });
        setCurrentPage(page);
        setIsFetching(false);
        setIsloading(false);

        var chapter_list = response.data.data;
        for (var i = 0; i <= chapter_list.length - 1; i++) {
          var video_listing = [];
          for (var j = 0; j <= chapter_list[i].topic.length - 1; j++) {
            var video_in = [...chapter_list[i].topic[j].notes];
            video_listing = [...video_listing, ...video_in];
          }
          chapter_list[i].video_listing = video_listing;
        }
        setNotesListing([...notesListing, ...chapter_list]);
      })
      .catch((error) => { });
  };
  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // scroller()
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  function handleScroll() {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight ||
      isFetching
    )
      return;
    setIsFetching(true);
  }

  useEffect(() => {
    if (!isFetching) return;
    if (currentPage == paginationData.max_pages) {
      return;
    }
    var x = Number(currentPage);
    getChapterVideos(x + 1);
  }, [isFetching]);

  function getdata() {
    return (
      <div className="main">
        <Document file={datas.file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <div style={{textAlign: 'center'}}>
          <div className="pagec">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </div>
          <div className="buttonc">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className="Pre"
              style={{padding: '5px 10px', fontSize: '17px', width: '100px', borderRadius: '7px', margin: '6px 9px 0 0', cursor: 'pointer'}}
            >
              Previous
            </button>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              style={{padding: '5px 10px', fontSize: '17px', width: '100px', borderRadius: '7px', cursor: 'pointer'}}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }

  console.log(notesListing, "notesListing");
  return (
    <div className="notes-mycourse-c">
      {notesListing.map((item, idx) => {
        return (
          <div className="notes-caorousel-c" >
            <div style={{display: 'flex'}}>
            <span style={{ fontWeight: "bold", marginTop: '22px', marginRight: '10px',  }}>{idx + 1}.</span>{" "}

            <div className="notes-carousel-title-c">
              <p>{item.title}</p>
              <p>{item.video_listing.length} Topic</p>
            </div>
            </div>
            <div className="notes-cc-h">
              <CardCarousel partial={true} item={4}>
                  {item.video_listing.map((_item, _idx) => {
                    return (
                      <div
                        className="notes-carousel-card"
                        onClick={() => handleClickOpen(_item)}
                      >
                        <p>{_item.title}</p>
                        <AccessTimeIcon
                          style={{ fontSize: "13px", marginRight: "5px" }}
                        />
                        <span>5 min Read</span>
                        <div className="notes-double-check">
                          <DoneAllIcon />
                        </div>
                      </div>
                    );
                  })}
              </CardCarousel>
            </div>

            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {item.title}
                  </Typography>
                </Toolbar>
              </AppBar>
              <List>
                {distype === 1 && getdata()}
              </List>
            </Dialog>
          </div>
        );
      })}
{isLoading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}

    </div>
  );
}

export default withRouter(Note);
