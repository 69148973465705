import React, { useRef, useState, useContext } from "react";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token, login_api, setEdunguruApi, api_call_token } from "../../Utils/Network";
import EditIcon from "@material-ui/icons/Edit";
import { withRouter } from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function AuthenticateLogin({ handleClose, ...props }) {
  const {
    updateLoginData,
    loginData,
    setToken,
    setStudentProfile,
    setUser,
    studentProfile,
    user,
    token,
  } = useContext(UserCredsContext);

  const [otp, setOtp] = useState([]);
  const list = [...Array(4).keys()];
  const inputRef = useRef([]);
  const [errors, setErrors] = useState({});

  const handler = (e, idx) => {
    setErrors({});
    const next = inputRef;
    const { value } = inputRef.current[idx];
    var otp_number = [...otp];
    otp_number[idx] = value;
    setOtp(otp_number);

    if (value.length != 0 && idx < 3 && next) {
      next.current[idx + 1].focus();
    }
  };

  const handleKeyChange = (e, idx) => {
    const next = inputRef;
    if (idx == 4) {
      return null;
    }
    if (idx == 0 && e.key !== "Backspace" && e.key !== "Delete") {
      return null;
    }
    if (e.key == "Delete" || e.key == "Backspace") {
      if (idx == 0) {
      } else {
        next.current[idx - 1].focus();
      }
    }
  };

  const sendOtp = () => {
    if (otp.length < 4) {
      setErrors({ otp: "please enter otp" });
      return null;
    }
    var otp_number = otp.join("");
    var otp_url =
      loginData.s_type === "phone"
        ? `/auth/v1/student/account/validate_otp/?isd_code=91&phone=${loginData.phone}&otp=${otp_number}`
        : `/auth/v1/student/account/validate_email_otp/?otp=${otp_number}&phone=${loginData.phone}&email=${loginData.email}&isd_code=91`;
    var api_type = loginData.s_type === "phone" ? "post" : "get";
    if (loginData.s_type === "phone") {
      login_api
        .post(`${otp_url}`)
        .then((response) => {
          console.log(response.data.data, "response.data.data");
          const { message } = response.data.data;
          var redirect = message.includes("try again");
          var redirect = message.includes("expired");
          if (redirect) {
            setErrors({ message });
          } else {
            const { email_validation_required, token, user, studentprofile } =
              response.data.data;
            if (email_validation_required) {
              updateLoginData({
                type: "emailAuth",
              });
            } else {
              handleClose();
              setToken(token);
              // if (window.location.href.indexOf("edunguru.com") > -1) {
              //   document.cookie = `edtech=${token.access}; expires=Sun, 1 Jan 2023 00:00:00 UTC; path=/;domain:.edunguru.com;`
              // } else if (window.location.href.indexOf("localhost") > -1) {
              //   document.cookie = `edtech=${token.access}; expires=Sun, 1 Jan 2023 00:00:00 UTC; path=/;domain:.localhost;`
              // } else if (window.location.href.indexOf("g10consultancy.com") > -1) {
              //   document.cookie = `edtech=${token.access}; expires=Sun, 1 Jan 2023 00:00:00 UTC; path=/;domain:.g10consultancy.com;`
              // }

              setStudentProfile(studentprofile);
              setUser(user);
              setEdunguruApi(token.access);
              // api_token.get(`/auth/v1/redirection`).then((res) => { console.log(res.data.data, "datasad") }).catch((err) => { console.log(err) });
              cookies.set('edutech', token.access, { domain: '.edunguru.com', path: '/' });
              if (user.first_name == "") {
                props.history.push(`/register/introduce`);
              } else if (
                studentprofile.hasOwnProperty("id") &&
                studentprofile.grade == null
              ) {
                props.history.push(`/register/goal`);
              } else {
                props.history.push(`/dashboard/start`);
              }
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            setErrors({ otp: error.response.data.data.message });
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    } else {
      login_api
        .get(`${otp_url}`)
        .then((response) => {
          console.log(response.data.data, "response.data.data");
          const { message } = response.data.data;
          var redirect = message.includes("retry");
          if (redirect) {
            setErrors({ message });
          } else {
            const { email_validation_required, token, user, studentprofile } =
              response.data.data;
            if (email_validation_required) {
              updateLoginData({
                type: "emailAuth",
              });
            } else {
              handleClose();
              setToken(token);
              setStudentProfile(studentprofile);
              setUser(user);
              setEdunguruApi(token.access);
              if (user.first_name == "") {
                props.history.push(`/register/introduce`);
              } else if (
                studentprofile.hasOwnProperty("id") &&
                studentprofile.grade == null
              ) {
                props.history.push(`/register/goal`);
              } else {
                props.history.push(`/dashboard/start`);
              }
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            setErrors({ otp: error.response.data.data.message });
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };

  const backToLogin = () => {
    updateLoginData({
      otp: "",
      type: "login",
    });
  };
  console.log(loginData, "loginData");
  return (
    <div>
      <div className="enter-number-login-c">
        {loginData.s_type === "phone" && (
          <>
            <p className="login-num-title">Enter OTP sent to</p>
            <p className="login-num-fields">Phone Number</p>
          </>
        )}
        {loginData.s_type === "email" && (
          <>
            <p className="login-num-title">Enter OTP sent to</p>
            <p className="login-num-fields">Email</p>
          </>
        )}
        <div style={{ textAlign: "center" }}>
          <div className="phone-num-otp">
            {loginData.s_type === "phone" && (
              <>
                <p>
                  {loginData.code} &nbsp;{loginData.phone}
                </p>
                <div className="edit-icon-float" onClick={backToLogin}>
                  <EditIcon fontSize="small" />
                </div>
              </>
            )}
            {loginData.s_type === "email" && (
              <>
                <p style={{ lineBreak: "break-all" }}>
                  &nbsp;{loginData.email}
                </p>
                <div className="edit-icon-float" onClick={backToLogin}>
                  <EditIcon fontSize="small" />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="otp-input-field">
          {list.map((x, id) => (
            <div>
              <input
                key={x}
                ref={(el) => (inputRef.current[x] = el)}
                onChange={(e) => handler(e, x)}
                onKeyUp={(e) => handleKeyChange(e, x)}
                type="text"
                className="otp_box"
                name={`otp_box${id}`}
                maxLength="1"
              />
            </div>
          ))}
        </div>
        <p className="error-message-text">{errors.otp}</p>
        <p className="error-message-text">{errors.message}</p>
        <div className="button-login-c">
          <button className="button-login-cb" onClick={() => sendOtp()}>
            VERIFY OTP{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AuthenticateLogin);
