import React, { useEffect, useState } from "react";
import navLogo from "../../Assets/Navbar/navlogo.png";
import "./NavBar.css";
import DialogBox from "../../Components/DialogBox/DialogBox.js";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import SubjectDialog from "../SubjectDialog/SubjectDialog";
import { api_open } from "../../Utils/Network";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  BackdropProps: {
    backgroundColor: "white",
    padding: "20px",
    zIndex: "99999999",
  },
});
function NavBar() {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const [displayType, setDisplayType] = useState(null);
  const [subjectArray, setSubjectArray] = useState([]);
  const [showDialog, setShowDialog] = useState();

  useEffect(() => {
    getSubject();
  }, []);

  const getSubject = () => {
    api_open
      .get(`/base/v1/subject/`)
      .then((response) => {
        console.log(response.data.data, " from ");
        setSubjectArray(response.data.data);
      })
      .catch((error) => {});
  };

  const toggleDrawer = (open) => (event) => {
    setState(open);
    setDisplayType(1);
  };

  const handleToggle = (_bools) => {
    setState(_bools);
  };

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  console.log(colorChange, "cc");
  const getData = () => {
    return (
      <div className="navbar_mobile">
        <div>
          <Link to="/">
            <div className="navbar-logo-b-c">
              <img src={navLogo} className="navbar-logo-b-c-img" />
            </div>
          </Link>
        </div>
        <div>
          <p className="navbar-text-item">Subject Tutorials</p>
        </div>
        <div>
          <p className="navbar-text-item">Test Preparation</p>
        </div>
        <div>
          <p className="navbar-text-item">Language</p>
        </div>
        <div>
          <DialogBox
            _page="homepage"
            _open_value={false}
            _callback={handleToggle}
          />
        </div>
      </div>
    );
  };

  const handleShowDialog = () => {
    setShowDialog(false);
  };
  return (
    <div>
      <div
        className={colorChange ? "navbar-home colorChange " : "navbar-home "}
      >
        {" "}
        <Link to="/">
          <div className="navbar-logo-b-c">
            <img src={navLogo} className="navbar-logo-b-c-img" />
          </div>
        </Link>
        <div>
          {/* <Link
            to={{
              pathname: `/dashboard/explore-courses/${null}`,
              state: { fromType: 3 },
            }}
          > */}
          <div onClick={() => setShowDialog(true)}>
            <p className="navbar-text-item">School Courses</p>
          </div>
          {/* </Link> */}
        </div>
        <div>
          <Link
            to={{
              pathname: `/dashboard/explore-courses/${null}`,
              state: { fromType: 2 },
            }}
          >
            <p className="navbar-text-item">Test Preparation</p>
          </Link>
        </div>
        <div>
          <Link
            to={{
              pathname: `/dashboard/explore-courses/${null}`,
              state: { fromType: 4 },
            }}
          >
            <p className="navbar-text-item">Spoken English</p>
          </Link>
        </div>
        <div>
          <Link
            to={{
              pathname: `/dashboard/explore-courses/${null}`,
              state: { fromType: 5 },
            }}
          >
            <p className="navbar-text-item">Test Series</p>
          </Link>
        </div>
        <div>
          <DialogBox _page="homepage" _open_value={false} />
        </div>
      </div>

      <div>
        <div className="hamburger">
          <MenuIcon
            onClick={toggleDrawer(true)}
            style={{ fontSize: "30px", color: "white" }}
          />
        </div>
        <SwipeableDrawer
          anchor={"left"}
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          classes={{ paper: classes.BackdropProps }}
          style={{ backgroundColor: "transparent" }}
        >
          {displayType === 1 && getData()}
        </SwipeableDrawer>
      </div>
      <SubjectDialog
        _data={subjectArray}
        _handleShowDialog={handleShowDialog}
        _showDialog={showDialog}
      />
    </div>
  );
}

export default NavBar;
