import React, { Component, createContext } from "react";
import Cookies from 'universal-cookie';
export const UserCredsContext = createContext();
const localstorage_state = localStorage.getItem("user_creds_context");


const cookies = new Cookies();

function getLocalData(keyname) {
  let object = undefined;

  try {
    object = JSON.parse(localstorage_state);
  } catch {
    console.error("There was error while parsing data from localstorage.");
  }

  if (object) {
    if (object[keyname]) {
      return object[keyname];
    }
  }

  if (keyname === "authenticated") return false;
  if (keyname === "group") return [];
  if (keyname === "user") return {};
  if (keyname === "filterTabs") return [];
  if (keyname === "token")
    return {
      access: "",
      refresh: "",
    };
  if (keyname === "studentProfile") return {};
  if (keyname === "otpLogin")
    return {
      code: "+91",
      phone: "9773526679",
      otp: "",
      type: "phone",
      email: "",
      e_otp: "",
      s_type: "phone",
    };
  if (keyname === "testData") return [];
  if (keyname === "selectedCourse") return {};

  return "";
}

class UserCredsContextProvider extends Component {
  state = {
    otpLogin: getLocalData("otpLogin"),
    otpLogin: {
      code: "+91",
      phone: "",
      otp: "",
      type: "phone",
      s_type: "phone",
    },
    filterTabs: getLocalData("filterTabs"),
    testData: getLocalData("testData"),
    studentProfile: getLocalData("studentProfile"),
    token: getLocalData("token"),
    user: getLocalData("user"),
    selectedCourse: getLocalData("selectedCourse"),
    showLogin: false,
    startParams: { board: null, grade: null },
    purchaseData: null,
    purchaseResponse: null,
    sahajLink: "",
    joinNumber: null,
  };

  setSahajLink(_data) {
    this.setState({ sahajLink: _data });
  }

  setPurchaseData(_data, type) {
    if (type == "purchase") {
      this.setState({ purchaseData: _data });
    } else if (type == "response") {
      this.setState({ purchaseResponse: _data });
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("user_creds_context", JSON.stringify(this.state));
    });
  }

  setFilterTabs(_array) {
    this.setState({ filterTabs: _array });
  }

  setJoinNumber(_number) {
    this.setState({ joinNumber: _number });
  }

  setStartParams(name, value) {
    var myData = { ...this.state.startParams, [name]: value };
    this.setState({ startParams: myData });
  }

  setShowLogin(_boolean) {
    this.setState({ showLogin: _boolean });
  }

  setSelectedCourse(_data) {
    this.setState({ selectedCourse: _data });
  }

  setTestData(_data) {
    console.log(_data, this.state, "context data");
    const update_data = [...this.state.testData, _data];
    this.setState({ testData: update_data });
  }

  checkLogin() {
    const { user } = this.state;
    if (user.hasOwnProperty("is_active") && user.is_active) {
      this.setState({ showLogin: true });
      return false;
    } else {
      return true;
    }
  }

  resetTestData() {
    this.setState({ testData: [] });
  }

  setOtpLogin(_data) {
    const update_data = { ...this.state.otpLogin, ..._data };
    this.setState({ otpLogin: update_data });
  }

  setToken(_token) {
    const { access, refresh } = _token;
    this.setState({ token: { access, refresh } });
  }

  setStudentProfile(_profile) {
    this.setState({ studentProfile: _profile });
  }

  setStudentGB(_name, _object) {
    this.setState((prevState) => ({
      studentProfile: {
        ...prevState.studentProfile,
        [_name]: _object,
      },
    }));
  }

  setUser(_user) {
    this.setState({ user: _user });
  }

  logout = () => {
    this.setState(
      {
        otpLogin: {
          code: "+91",
          phone: "9773526679",
          otp: "",
          type: "login",
        },
        studentProfile: {},
        token: {
          access: "",
          refresh: "",
        },
        user: {},
        testData: [],
      },
      () => {
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
        localStorage.clear();
        cookies.remove("edutech")
      }
    );
  };
  render() {
    const { studentProfile, user, token, testData } = this.state;
    console.log(token, "testData");
    return (
      <UserCredsContext.Provider
        value={{
          loginData: this.state.otpLogin,
          updateLoginData: this.setOtpLogin.bind(this),
          token: this.state.token,
          testData: this.state.testData,
          setTestData: this.setTestData.bind(this),
          resetTestData: this.resetTestData.bind(this),
          setToken: this.setToken.bind(this),
          studentProfile: this.state.studentProfile,
          setStudentProfile: this.setStudentProfile.bind(this),
          user: this.state.user,
          setUser: this.setUser.bind(this),
          logout: this.logout.bind(this),
          showLogin: this.state.showLogin,
          setShowLogin: this.setShowLogin.bind(this),
          checkLogin: this.checkLogin.bind(this),
          setStartParams: this.setStartParams.bind(this),
          startParams: this.state.startParams,
          purchaseResponse: this.state.purchaseResponse,
          purchaseData: this.state.purchaseData,
          setPurchaseData: this.setPurchaseData.bind(this),
          setSahajLink: this.setSahajLink.bind(this),
          sahajLink: this.state.sahajLink,
          setSelectedCourse: this.setSelectedCourse.bind(this),
          selectedCourse: this.state.selectedCourse,
          setStudentGB: this.setStudentGB.bind(this),
          setFilterTabs: this.setFilterTabs.bind(this),
          filterTabs: this.state.filterTabs,
          setJoinNumber: this.setJoinNumber.bind(this),
          joinNumber: this.state.joinNumber,
        }}
      >
        {this.props.children}
      </UserCredsContext.Provider>
    );
  }
}

export default UserCredsContextProvider;
