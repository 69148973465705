import React, { useEffect, useState } from "react";
import rectangleExplore from "../../../Assets/Dashboard/Explore/rectangleExplore.png";
import overlayImage from "../../../Assets/Dashboard/Explore/overlayimage.png";
import { api_open } from "../../../Utils/Network";
import "./DemoVideo.css";
import { millisToMinutesAndSeconds } from "../../../Utils/Utils";

function DemoVideo() {
  const [demoVideo, setDemoVideo] = useState([]);

  useEffect(() => {
    getDemoVideo();
  }, []);

  const getDemoVideo = () => {
    api_open
      .get(`/cms/v1/demo_videos/`)
      .then((response) => {
        setDemoVideo(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(demoVideo, "demoVideo");
  return (
    <div>
      <div className="explore-hero-section">
        <img src={rectangleExplore} className="rect-explore-c-h" />
        <img src={overlayImage} className="rect-explore-overlay-c" />
        <div className="explore-hero-text-c" style={{ left: "40px" }}>
          <p>Demo Videos</p>
        </div>
      </div>
      {/* <div className="demo-video-c">
        <div className="demo-video-container"></div>
      </div> */}
      <div className="demo-video-c">
        {demoVideo.map((item, id) => {
          var totalTime = millisToMinutesAndSeconds(
            item?.resume_video[0]?.total_duration
          );
          return (
            <div className="demo-video-fc">
              {
                (item.resume_video.length > 0) ?

                  <div className="demo-video-container">
                    <div style={{ position: "relative" }}>
                      <img
                        src={item?.resume_video[0]?.thumbnail}
                        style={{ width: "100%" }}
                      />
                      <div className="float-time">
                        <p>{totalTime}</p>
                      </div>
                    </div>
                    <p>
                      <b>{item.title}</b>
                    </p>
                  </div>
                  : <></>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DemoVideo;
