import React, { useState } from 'react'
import TeachersCards from '../TeachersCards/TeachersCards';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Vidhya from '../../Assets/Vidhya.png'
import Manoj from '../../Assets/Manoj.png'
import Bharat from '../../Assets/Bharat.png'
import Suman from '../../Assets/Suman.png'
import Sanjay from '../../Assets/Sanjay.png'
import Himani from '../../Assets/Himani.png'
import Neelshikha from '../../Assets/Neelshikha.png'




function TeachertsSLider({
    partial = false,
    item = 1.5,
    next = 1,
    // data,
    // setSelectCardId,
}) {
    const [testimonials, setTestimonials] = useState([])
    const [responsive, setResponsive] = useState({
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
            partialVisibilityGutter: 40,
            slidesToSlide: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 993 },
            items: 3,
            partialVisibilityGutter: 40,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1100, min: 768 },
            items: 2,
            partialVisibilityGutter: 40,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            partialVisibilityGutter: 40,
            slidesToSlide: 1,
        },
    });
    return (

        <Carousel
            partialVisible={partial}
            responsive={responsive}
            // customButtonGroup={<CustomButtonGroup />}
            arrows={false} // Hide default arrows
            showDots={true}
            autoPlay={true}
        >
            {logos.map((item) => {
                return (
                    <>
                        <TeachersCards data={item} />
                    </>
                )
            })}





        </Carousel>

    )
}

export default TeachertsSLider
const logos = [
    {
        id: 1,
        img: Vidhya,
        name: " Vidhya Manohar",
        degree: "Mathematics ",
        desc: " Having expertise in teaching Mathematics for IIT JEE,SAT & other competitive Exams & produce AIR under 10 for several times under his able guidance."
    },
    {
        id: 2,
        img: Manoj,
        name: "   Rajan Khare ",
        degree: " Chemistry",
        desc: " Well Known faculty trained Engineering & Medical aspirants in Kota, Patna, Hyderabad. Having experience of more than 18 Years."
    },
    {
        id: 3,
        img: Bharat,
        name: " JLN Reddy",
        degree: "Mathematics     ",
        desc: "Having expertise in teaching Mathematics for IIT JEE & other competitive Exams. Having More than 15Years of experience in this field. "
    },
    {
        id: 4,
        img: Suman,
        name: "  K Ravi Kumar ",
        degree: " Chemistry",
        desc: " Having Experience of more than 25 Years in the field of teaching Chemistry for IIT JEE, Medical Entrance Exams."
    },
    {
        id: 5,
        img: Himani,
        name: "  Krishnamurthy ",
        degree: "Mathematics",
        desc: "Having experience of more than 28 years to train IIT & Engineering Aspirants & produce AIR under 10 for several times. "
    },
    {
        id: 6,
        img: Sanjay,
        name: "BM Sharma ",
        degree: " Physics",
        desc: " Well Known Author-Published 12 titles in Physics for different Competitive Exams. Having teaching Experience of more than 18 Years for IIT & Medical Aspirants."
    }, 
    // {
    //     id: 7,
    //     img: Neelshikha,
    //     name: "  Ms. Neelshikha  ",
    //     degree: " M.Sc. (Zoology) Hons. ",
    //     desc: " "
    // },
]