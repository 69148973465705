import React, { useEffect, useState } from 'react'
import { login_api } from '../../Utils/Network';
import styles from './index.module.css';

function Terms() {
    const [terms, setTerms] = useState({});

    useEffect(() => { getTerms() }, [])
    useEffect(() => window.scrollTo(0, 0), []);

    const getTerms = () => {
        login_api
            .get('base/v1/terms_and_policies/1')
            .then((resp) => setTerms(resp.data.data))
            .catch(err => console.log(err));
    }

    return (
        <div className={styles.terms_head}>

            <div>
                <h1 className={styles.title_terms}>{terms?.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: terms?.description }}>
                </div>
            </div>
            <div>
                {terms?.conditions?.map((item, index) => (
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '20px', marginTop: '20px' }}>{index + 1}</div>
                        < div key={index} dangerouslySetInnerHTML={{ __html: item.description }}>
                        </div>
                    </div>

                )
                )}
            </div>
        </div >
    )
}

export default Terms