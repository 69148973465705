import React, { useState } from "react";
import styles from "./index.module.css";
import cardImg from "../../../Assets/FreeTrailLanding/courseCard.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

function CustomButtonGroup({ next, previous }) {
  return (
    <div className={styles.buttonGroup}>
      <button className={styles.customPrev} onClick={previous}>
        <NavigateBeforeIcon style={{ fontSize: "30px" }} />
      </button>
      <button className={styles.customNext} onClick={next}>
        <NavigateNextIcon style={{ fontSize: "30px" }} />
      </button>
    </div>
  );
}

function FreeTrialCourseCard({
  partial = false,
  item = 1,
  next = 1,
  data,
  setSelectCardId,
  checkValidity=() =>{}
}) {
  const [activeCardId, setActiveCardId] = useState(null);
  const [responsive, setResponsive] = useState({
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 993 },
      items: 3,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 2,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
      slidesToSlide: 1,
    },
  });

  const handlecardClick = (v) => {
    console.log(v, "cardIds123");
    setSelectCardId(v);
    checkValidity(v?.id)
    setActiveCardId(v?.id);
  };

  console.log(data, "partialVisible");
  return (
    <>
      <Carousel
        partialVisible={partial}
        responsive={responsive}
        customButtonGroup={<CustomButtonGroup />}
        arrows={false} // Hide default arrows
      >
        {data?.map((v, i) => (
          <div
            key={v?.id}
            className={styles.CardContainer}
            onClick={() => handlecardClick(v)}
          >
            <div className={styles.imgContainer}>
              <img src={v?.image} alt="" />
              <div className={styles.subjectLising}>
                {v?.subject?.map((img, i) => {
                  return (
                    <div className={styles.subjectimgContainer}>
                      <img key={i} src={img?.icon} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.infoBox}>
              <h3 className={styles.cardTitle}>{v?.title}</h3>

              <p className={styles.couresDesc}>
                <span>
                  {v?.chapter_count ? `${v?.chapter_count} Chapters |` : ""}
                </span>
                <span>
                  {v?.video_count ? `${v?.video_count} Videos |` : ""}
                </span>
                <span>{v?.test_count ? `${v?.test_count}Tests` : ""}</span>
              </p>
            </div>
            <div
              className={`${styles.selectCard} ${
                activeCardId == v?.id ? styles.active : ""
              }`}
            ></div>
          </div>
        ))}
      </Carousel>
    </>
  );
}

export default FreeTrialCourseCard;
