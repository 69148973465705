const loadGtag = (tagId, location) => {
  // Create a script element to load the gtag.js script
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
  document.head.appendChild(script);

  // Initialize the dataLayer and gtag functions
  script.onload = () => {
    console.log("gtag loaded");
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    const pagePath = location.pathname + location.search + location.hash;
    window.gtag = gtag;
    console.log("gtag window");
    gtag("js", new Date());
    gtag("config", tagId);
    gtag("set", "page_path", "/freetrial");
    gtag("event", "page_view", { page_title: "freetrial" });
  };
};

export default loadGtag;
