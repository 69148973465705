import React, { useEffect } from 'react';
import { api_token } from '../Utils/Network';

function Success() {
    useEffect(() => {
        handleRedirection()
    }, [])
    const handleRedirection = () => {
        // 
        api_token
            .get(`/auth/v1/redirection`)
            .then(response => {
                console.log(response, 'response jj')
            })
            .catch(error => {

            })
    }
    return (
        <div>
            Success
        </div>
    )
}

export default Success;