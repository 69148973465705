import React from 'react'
import styles from "./About.module.css"
import mission from '../../Assets/mission.png'
import values from '../../Assets/values.png'
import vision from '../../Assets/vision.png'



function About() {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2>About Edunguru </h2>
            </div>
            <div className={styles.middle}>
                <p className={styles.para1}>Edunguru is a Digital Education Platform that offers online and offline education courses spanning a wide variety of topics that are in high demand within the Indian Domestic Market and with Non-Resident Indians (NRIs) living abroad. Courses include a full K12 curriculum; Test </p>
                <p className={styles.para2}>Preparation for Post Graduates Studies, Professional and Government Examinations and Spoken English.<br></br>
                    We want Edunguru to be the premier digital learning academy in India with special focus on providing world-class education and training for smaller cities and the rural markets, driven by our passion for education to help people enrich their lives and fulfil their dreams.</p>

            </div>
            <div className={styles.bottom}>

                {data.map((items) => {
                    return (
                        <div className={styles.one}>
                            <div><img src={items.img}></img></div>
                            <div className={styles.desc}>
                                <h3>{items.head}</h3>
                                <p>{items.desc}</p>

                            </div>


                        </div>
                    )
                })}







            </div>

        </div>
    )
}

export default About
const data = [

    {
        id: 1,
        img: mission,
        head: " Mission",
        desc: " At Edunguru, our mission is to offer high-quality, interactive, bilingual learning through state-of-the-art technology and top-tier teaching methods. ",
    },

    {
        id: 1,
        img: vision,
        head: "Vision ",
        desc: " To transform education through innovative, accessible, and comprehensive learning experiences that empower students to achieve academic excellence. ",
    },

    {
        id: 1,
        img: values,
        head: "Values ",
        desc: "To revolutionize education through innovative, accessible, and comprehensive learning experiences that empower students to achieve academic excellence.  ",
    },

]