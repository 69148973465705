import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import videobg from "../../../Assets/Dashboard/videobg.png";
import CardCarousel from "../../../Components/CardCarousel/CardCarousel";
import { api_token } from "../../../Utils/Network";
import {
  millisToMinutesAndSeconds,
  secondsToMinutes,
} from "../../../Utils/Utils";

function Video({ _data, _course, _vc_list, params_id, ...props }) {
  const [videoData, setVideoData] = useState([]);
  const [videoCData, setVideoCData] = useState([]);
  const [videoListing, setVideoListing] = useState([]);
  const [videoCList, setVideCList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsloading] = useState(false);

  const [paginationData, setPaginationData] = useState({
    count: null,
    max_pages: null,
    next_page: null,
    page: null,
    previous_page: null,
    total_count: null,
  });
  console.log(params_id, "params_idparams_id");
  // useEffect(() => {
  //   if (_data.length) {
  //     setVideoData(_data);
  //   }
  // }, [_data]);
  useEffect(() => {
    // setVideoCData(_vc_list);
    // getVideoListing(_vc_list);
  }, [_vc_list]);

  useEffect(() => {
    getChapterVideos(1);
  }, []);

  useEffect(() => {
    // alert("hi");
  }, []);

  const getChapterVideos = (_page_no) => {
    setIsloading(true);
    api_token
      .get(
        `/cms/v1/chapter_videos_listing/?course=${params_id}&page=${_page_no}&page_size=3`
      )
      .then((response) => {
        const {
          count,
          max_pages,
          next_page,
          page,
          previous_page,
          total_count,
        } = response.data;
        setPaginationData({
          count,
          max_pages,
          next_page,
          page,
          previous_page,
          total_count,
        });
        setCurrentPage(page);
        setIsFetching(false);
        setIsloading(false);

        var chapter_list = response.data.data;
        for (var i = 0; i <= chapter_list.length - 1; i++) {
          var video_listing = [];
          for (var j = 0; j <= chapter_list[i].topic.length - 1; j++) {
            var video_in = [...chapter_list[i].topic[j].videos];
            video_listing = [...video_listing, ...video_in];
          }
          chapter_list[i].video_listing = video_listing;
        }
        if (chapter_list.length) {
          setVideoListing(chapter_list);
          console.log([...chapter_list, ...videoData], "sandesh data");
          setVideoData([...videoData, ...chapter_list]);
        }
        setVideCList(response.data.data);
      })
      .catch((error) => { });
  };

  const getVideoListing = async (_list) => {
    // var videoListArray = [];
    // for (var i = 0; i < _list.length; i++) {
    //   for (var j = 0; j < _list[i].topic.length; j++) {
    //     var chapter_id = _list[i].topic[j].id;
    //     const response = await api_token.get(
    //       `/cms/v1/topic_videos_listing?chapter=${chapter_id}`
    //     );
    //     console.log(response, "response from await");
    //   }
    //   // setVideoCData(videoListArray);
    // }
  };
  const redirectUrl = (_chapter, _item) => {
    console.log(_course, "_course_course");
    // _course.is_first_video_viewed &&
    if (_course.is_purchased == false) {
      console.log(_chapter, "_chapter_chapter");
      localStorage.setItem("video_listing", JSON.stringify(_data));
      localStorage.setItem("chapter_number", _chapter);
      // window.open(`/dashboard/course-details/${_course.id}`, "_blank").focus();
      props.history.push(`/dashboard/course-details/${_course.id}`);
    } else {
      props.history.push(`/video/${_item.id}`);
      // const win = window.open(
      //   `http://localhost:3000/#/video/${_item.id}`,
      //   "_blank"
      // );
      // win.focus();
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // scroller()
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleScroll() {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight ||
      isFetching
    ) return;
    setIsFetching(true);
  }

  useEffect(() => {
    if (!isFetching) return;
    if (currentPage == paginationData.max_pages) {
      return;
    }
    var x = Number(currentPage);
    getChapterVideos(x + 1);
  }, [isFetching]);

  console.log(videoData, "videoData");
  return (
    <div>
      <div className="chapter-carousel-container-explore">
        {videoData.map((item, idx) => {
          return (
            <React.Fragment>
              <div>
                <p>
                  <span style={{ fontWeight: "900" }}>{idx + 1}.</span>{" "}
                  <b> {item.title}</b>
                </p>
              </div>
              <CardCarousel partial={true} item={4}>
                {item.video_listing.map((item_list, _idx) => {
                  console.log(item_list, "item_list");
                  var resume_duration;
                  if (item_list.resume_video.length) {
                    const { video, duration } = item_list.resume_video[0];
                    resume_duration = (video / duration) * 100;
                  }
                  const should_view = true;
                  var totalTime = secondsToMinutes(
                    item_list.video_link.duration
                  );
                  return (
                    <div>
                      <div
                        className="course-video-co"
                        onClick={() => redirectUrl(idx + 1, item)}
                      >
                        <div className="video-bg-sec">
                          <div style={{ position: "relative" }}>
                            <img
                              src={item_list.video_link.pictures.base_link}
                            />
                            <div className="float-timec">
                              <p
                                style={{
                                  backgroundImage: `linear-gradient(to right, #${_course !== null &&
                                    _course?.subject[0]?.dark_colour
                                    }, #${_course !== null &&
                                    _course?.subject[0]?.light_colour
                                    })`,
                                }}
                              >
                                {totalTime}
                              </p>
                            </div>

                            {/* <div className="video-title-sec">
                              <p>{item_list.title}</p>
                            </div> */}
                          </div>
                          {item_list.resume_video.length !== 0 && (
                            <div className="video-bar-resume-c">
                              <div
                                style={{
                                  background: "red",
                                  width: `${resume_duration}%`,
                                  height: "4px",
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="video-title-sec">
                        <p>{item_list.title}</p>
                      </div>
                    </div>
                  );
                })}
              </CardCarousel>
            </React.Fragment>
          );
        })}
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default Video;
