import React from 'react';
import "./Footer.css";
import mailicon from "../../Assets/footer/email.png"
import play from "../../Assets/footer/playstore.png"
import appstore from "../../Assets/footer/appstore.png"
import face from "../../Assets/footer/fb.png"
import insta from "../../Assets/footer/insta.png"
import link from "../../Assets/footer/link.png"
import yt from "../../Assets/footer/yt.png"
import twit from "../../Assets/footer/twit.png"
import { Link } from 'react-router-dom';



function Footer() {
    return (
        <div className='footer_main'>
            <div className='footer_one'>
                <div>
                    <div><h1 className='white_col header_lx'>Our Newsletter</h1></div>
                    <div className='mail_box'>
                        <div className='mail-bx_ch'>
                            <img src={mailicon} alt="message" />
                        </div>
                        <input className='mail_inp' placeholder='Enter valid E-mail'></input>
                    </div>
                    <div><button className='btn_sub_footer'>SUBSCRIBE NOW</button></div>
                </div>
                <div className='ft_list'>
                    <div> <h2 className='white_col header_sm'>Explore</h2></div>
                    <div className='ls_footer'>
                        <ul>
                            <li><Link to='/CourseWare'>Courseware & Methodology</Link></li>
                            <li><Link to='/Performance'>Performance Monitoring</Link></li>
                            <li><Link to='/Governing'>Governing Council </Link></li>
                            <li><Link to='/Academics'>Academic Excellence Board</Link></li>
                            <li><Link to='/Students'>Student Advisory Council</Link></li>
                            <li></li>
                        </ul>
                    </div>

                </div>
                {/* <div className='ft_list'>
                    <div> <h2 className='white_col header_sm'>Quick Links</h2></div>
                    <div className='ls_footer'>
                        <ul>
                            <li>Blogs</li>
                            <li>CEO's Message</li>
                            <li>Eduvantages</li>
                            <li>Events</li>
                            <li>Mock Test</li>
                        </ul>
                    </div>
                </div> */}
                <div className='ft_list'>
                    <div> <h2 className='white_col header_sm'>Support</h2></div>
                    <div className='ls_footer'>
                        <ul>
                            <li><Link to='/contact'>Contact us</Link></li>
                            <li><Link to='/terms'>Terms and Conditions</Link></li>
                            <li><Link to='/policy'>Privacy/Policy</Link></li>
                            <li><Link to='/cancellation-policy'>Cancellation Policy</Link></li>
                            <li>FAQ</li>
                            <li>Toll free No. 1800 891 2868</li>
                            <li>Troubleshooting/Tutorial's</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='footer_content_social'>
                <div className='footer_two'>
                    <div className='footer_social_inner'>
                        <div className='ft_social'>
                            <div><h2 className='white_col header_lx'>Spoken English App</h2></div>
                            <div><img src={play} className='mb_lx img-social' /></div>
                            <div><img src={appstore} className='mb_lx img-social' /></div>
                        </div>
                        <div>
                            <div><h2 className='white_col header_lx'>All Classes App</h2></div>
                            <div><img src={play} className='mb_lx img-social' /></div>
                            <div><img src={appstore} className='mb_lx img-social' /></div>
                        </div>
                    </div>
                    <div className='follow_us'>
                        <div><h3 className='white_col'>Follow us</h3></div>
                        <div className='ft_icons'>
                            <ul>
                                <li><a href='https://www.linkedin.com/company/edunguruonline/' target='_blank'><img src={link} /></a></li>
                                <li><a href='https://www.instagram.com/edunguru/' target='_blank'><img src={insta} /></a></li>
                                <li><a href='https://twitter.com/edunguru' target='blank'><img src={twit} /></a></li>
                                <li><a href='https://www.facebook.com/edunguruindia' target='blank'><img src={face} /></a></li>
                                <li><a href='https://www.youtube.com/c/Edunguru' target='blank'><img src={yt} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default Footer;
