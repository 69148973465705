import React, { useState, useEffect, useContext } from "react";
// import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Masonry from 'react-masonry-css'
import "./Home.css";
import girlImg from "../../Assets/Home/girl.png";
import satelliteImg from "../../Assets/Home/satellite.png";
import heroImg from "../../Assets/Home/Hero.svg";
import a1 from "../../Assets/Home/a1.png";
import a2 from "../../Assets/Home/a2.png";
import a3 from "../../Assets/Home/a3.png";
import a4 from "../../Assets/Home/a4.png";
import a5 from "../../Assets/Home/a5.png";
import a6 from "../../Assets/Home/a6.png";
import a7 from "../../Assets/Home/a7.png";
import a8 from "../../Assets/Home/a8.png";
import a9 from "../../Assets/Home/a9.png";
import a10 from "../../Assets/Home/a10.png";
import a11 from "../../Assets/Home/a11.png";
import a12 from "../../Assets/Home/a12.png";
import rect1 from "../../Assets/Home/rectangle1.png";
import rect2 from "../../Assets/Home/rectangle2.png";
import rect3 from "../../Assets/Home/rectangle3.png";
import rect4 from "../../Assets/Home/rectangle4.png";
import rect5 from "../../Assets/Home/rectangle5.png";
import rect6 from "../../Assets/Home/rectangle6.png";
import rect7 from "../../Assets/Home/rectangle7.png";
import rect8 from "../../Assets/Home/rectangle8.png";
import rect9 from "../../Assets/Home/rectangle9.png";
import rect10 from "../../Assets/Home/rectangle10.png";
import rect11 from "../../Assets/Home/rectangle11.png";
import rect12 from "../../Assets/Home/rectangle12.png";
import greenRect from "../../Assets/Home/greenrectangle.png";
import groupIcon from "../../Assets/Home/groupicon.png";
import rectcardi from "../../Assets/Home/rectcardi.png";
import rectcard from "../../Assets/Home/rectanglecard.png";
import navLogo from "../../Assets/Navbar/navlogo.png";
import learnimg from "../../Assets/Home/learn1.png";
import walkingman from "../../Assets/Home/walkingman.png";
import homebottom from "../../Assets/Home/homebottom.png";
import girlwalk from "../../Assets/Home/girlwalk.png";
import leftpath from "../../Assets/Home/leftpath.png";
import rightpath from "../../Assets/Home/rightpath.png";
import inverted1 from "../../Assets/Testimonial/double_quotes2.png";
import inverted2 from "../../Assets/Testimonial/double.png";
import comments from "../../Assets/Testimonial/comment.png";
import boys from "../../Assets/Testimonial/boy2.png";
import { api_token } from "../../Utils/Network";
import { Link } from "react-router-dom";
import CardCarousel from "../../Components/CardCarousel/CardCarousel";
import Skeleton from "@material-ui/lab/Skeleton";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import DialogBox from "../../Components/DialogBox/DialogBox";


const breakpointColumnsObj = {
  2200: 3,
  1800: 3,
  1400: 3,
  1100: 3,
  700: 2,
  500: 1
};

function HomePage(props) {
  const { setShowLogin, showLogin, setJoinNumber } =
    useContext(UserCredsContext);
  const [homeData, setHomeData] = useState([]);
  const [courses, setCourses] = useState({});
  const [title, setTitle] = useState([]);
  const [learn, setLearn] = useState([]);
  const [whylearn, setWhyLearn] = useState({});
  const [footer, setFooter] = useState({});
  const [banner, setBanner] = useState([]);
  const [testimonials, setTestimonial] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [contactNumber, setContactNumber] = useState({ number: null });
  const validate = () => {
    let temp = {};
    let returnValue = true;
    temp.number = /^[0-9][0-9]{9}$/.test(contactNumber.number)
      ? ""
      : "Please enter valid phone number.";
    setErrors({
      ...temp,
    });
    console.log(temp, "temptemp");
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("required") || temp[key].includes("valid");
    });
    if (found.length) {
      returnValue = false;
    }
    return returnValue;
  };
  useEffect(() => {
    api_token
      .get(`/base/v1/homescreen`)
      .then((response) => {
        // console.log(response, "response in home");
        setHomeData(response.data.data);
      })
      .catch((error) => { });
  }, []);
  useEffect(() => {
    homescreen();
    testi();
  }, []);

  const testi = () => {
    api_token
      .get(`/profile/v1/testimonials/`)
      .then((res) => {
        console.log(res.data.data, "Testing");
        setTestimonial(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  const redirectUrl = (_id) => {
    console.log(props.history, "props.history");
    props.history.push(`/dashboard/explore-courses/${_id}`);
  };

  const homescreen = () => {
    api_token
      .get(`/base/v1/web_homescreen`)
      .then((res) => {
        console.log(res, "Home screen");
        setCourses(res.data.data[1]);
        setTitle(res.data.data[0].data);
        setBanner(res.data.data[2].data);
        setLearn(res.data.data[3].data);
        setFooter(res.data.data[5].data[0]);
        setWhyLearn(res.data.data[4]);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleLogin = () => {
    console.log(validate(), "hh jj");
    if (!validate()) {
      return null;
    }
    setShowLogin(true);
  };

  const handleContact = (e) => {
    const { name, value } = e.target;
    if (value.length > 10) {
      return null;
    }
    setContactNumber({ [name]: value });
    setJoinNumber(value);
    if (errors.hasOwnProperty(name) && errors[name].length !== 0) {
      setErrors({ ...errors, [name]: "" });
    }
  };
  useEffect(() => {
    handleRedirection()
  }, [])
  const handleRedirection = () => {
    // 
    api_token
      .get(`/auth/v1/redirection`)
      .then(response => {
        console.log(response, 'response jj')
      })
      .catch(error => {

      })
  }
  return (
    <div>
      <DialogBox _page="dashboard" _open_value={showLogin} />
      <div>
        {title.map((x, i) => (
          <div
            className="home-hero-banner-h-c"
            // style={{ backgroundImage: `url(${heroImg})` }}
            key={i}
          >
            <div className="hero-join-now-c">
              <span>+91</span>
              <input
                type="number"
                placeholder="Enter Phone Number "
                value={contactNumber.number}
                name="number"
                maxLength="10"
                onChange={(e) => handleContact(e)}
              />
              <button onClick={() => handleLogin()}>Join Now</button>
            </div>
            <p
              className="error-message-text"
              style={{
                position: "absolute",
                width: "400px",
                position: "absolute",
                bottom: "-8%",
                left: "50%",
                transform: "translateX(-50%)",
                textAlign: "center",
              }}
            >
              {errors.number}
            </p>

            <img src={x.icon2} className="banner-background-hero-h" />
            <img src={satelliteImg} className="home-banner-satellite-img" />
            <img src={x.icon1} className="home-banner-girl-img" />

            <div className="inner-content-hero-h-c">
              <div className="h-inner-c-right">
                <h1>{x.title}</h1>
                <p>{x.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="subject-home-f-c container-div-box">
        <p className="subject-home-f-c-title container-div-box-title">
          {courses.title}
        </p>
        <div className="subject-flex-c-h">
          {homeData.length !== 0 &&
            homeData[2].data.map((item, idx) => {
              {
                /* {courses.data && courses.data.length > 0 && courses.data.map((item,idx) => { */
              }
              return (
                <div
                  className="subject-flex-i-d-c"
                // onClick={() => redirectUrl(item.id)}
                >
                  <Link to={`/dashboard/explore-courses/${item.id}`}>
                    <div className="subject-img-c-t">
                      <div
                        style={{
                          width: "80px",
                          height: "80px",
                          padding: "3px",
                          borderRadius: "10px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                        }}
                      >
                        {/* <img src={rect1} className="subject-icon-outer" /> */}
                        <img src={item.icon} className="subject-icon-overlay" />
                      </div>
                    </div>
                    <p>{item.title}</p>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      <div className="prepare-for-banner container-div-box">
        <div className="prepare-for-bamnner-container">
          {/* <img src={greenRect} /> */}
          {/* <p>Prepare For IIT JEE</p> */}

          {loading ? (
            <Skeleton variant="rect" width={1500} height={250} />
          ) : (
            <CardCarousel item={1} next={1}>
              {banner.length > 0 &&
                banner.map((val, i) => {
                  return (
                    <div key={i}>
                      <div className="explore-cat-container">
                        <img src={val.image} />
                      </div>
                    </div>
                  );
                })}
            </CardCarousel>
          )}
        </div>
      </div>
      <div className="card-for-type-c container-div-box">
        <div className="card-for-type-container container-div-box-title">
          <div>
            {learn.length !== 0 &&
              learn.map((val, i) => (
                <div className="card-container-type" key={i}>
                  <h2>{val.title}</h2>
                  {val.features.map((x, j) => (
                    <div className="type-flex-c-h" key={x.id}>
                      <div>
                        <img src={x.icon} />
                      </div>
                      <div>
                        <p>{x.title}</p>
                        <span>{x.description}</span>
                      </div>
                    </div>
                  ))}
                  <div className="rect-card-d-img-c">
                    <div style={{ position: "relative" }}>
                      <img src={rectcard} className="rect-card-img1" />
                      <img src={val.icon} className="rect-card-img2" />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="why-learn-with-c container-div-box">
        <div className="wlw-tc container-div-box-title">
          <span>{whylearn.title} &nbsp;</span>
          <img src={navLogo} />
        </div>

        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {whylearn.data !== undefined &&
            whylearn.data.map((item, id) => {
              return (
                <div className="wlw-cc">
                  <div className="wlw-c-tt">
                    <div>
                      <img src={item.icon} />
                    </div>
                    <div>
                      <p className="grid_titles">{item.title}</p>
                    </div>
                  </div>
                  <p className="wlw-c-p">{item.description}</p>
                </div>
              );
            })}
        </Masonry>
        <img src={walkingman} className="float-wm" />
      </div>
      <div className="homepage-bottom-c">
        <img src={homebottom} className="bottom-back-img" />
        <img src={girlwalk} className="bottom-girlwalk" />
        <img src={leftpath} className="bottom-leftpath" />
        <img src={rightpath} className="bottom-rightpath" />
        <div className="bottom-text-center-c">
          <p className="bottom-text-header-home">
            INDIA’S LARGEST DIGITAL EDUCATION UNIVERSE
          </p>
          <div className="bottom-data-flex-c">
            <div>
              <span>Over</span>
              <h4>{footer.Courses}</h4>
              <p>Courses</p>
            </div>
            <div>
              <span>&nbsp;</span>
              <h4>{footer.Videos}</h4>
              <p>Video</p>
            </div>
            <div>
              <span>&nbsp;</span>
              <h4>{footer.Tests}</h4>
              <p>Tests</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="explore-cat-container">
          <h1 style={{ marginTop: '40px' }}>Testimonial</h1>
        </div>
        <CardCarousel item={1} next={1}>
          {testimonials.map((val, i) => {
            return (
              <div key={i}>
                <div className="explore-cat-container">
                  <div className="testimo_main">
                    <div>
                      <img src={inverted1} />
                    </div>
                    <div>
                      <div class="test_desc">
                        <img src={comments} />
                        <div className="txt_desc">
                          <p className="txt_desc_descs">{val.description}</p>
                          <p className="Caps">
                            {val.user.first_name} {val.user.last_name}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <img src={inverted2} />
                    </div>
                  </div>
                  <div className="class_images">
                    <div className="circle">
                      <img src={val.user.avatar} className="src_circle" />
                    </div>
                    <div className="boy_img">
                      <img src={boys} alt="imgs" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </CardCarousel>
      </div>
    </div>
  );
}

export default HomePage;

const learnCard = [
  {
    title: "Experienced Teachers",
    descp:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nounummy nibh euidmod tinci",
    id: 1,
    img: learnimg,
  },
  {
    title: "Experienced Teachers",
    descp:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nounummy nibh euidmod tinci",
    id: 2,
    img: learnimg,
  },
  {
    title: "Experienced Teachers",
    descp:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nounummy nibh euidmod tinci",
    id: 3,
    img: learnimg,
  },
  {
    title: "Experienced Teachers",
    descp:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nounummy nibh euidmod tinci",
    id: 4,
    img: learnimg,
  },
];

const subjectsList = [
  {
    name: "Mathematics",
    id: 1,
    oimg: rect1,
    iimg: a1,
  },
  {
    name: "Physics",
    id: 2,
    oimg: rect2,
    iimg: a2,
  },
  {
    name: "Chemistry",
    id: 3,
    oimg: rect3,
    iimg: a3,
  },
  {
    name: "Biology",
    id: 4,
    oimg: rect4,
    iimg: a4,
  },
  {
    name: "English",
    id: 5,
    oimg: rect5,
    iimg: a5,
  },
  {
    name: "Hindi",
    id: 6,
    oimg: rect6,
    iimg: a6,
  },
  {
    name: "Mathematics",
    id: 7,
    oimg: rect7,
    iimg: a7,
  },
  {
    name: "Physics",
    id: 8,
    oimg: rect8,
    iimg: a8,
  },
  {
    name: "Chemistry",
    id: 9,
    oimg: rect9,
    iimg: a9,
  },
  {
    name: "Biology",
    id: 10,
    oimg: rect10,
    iimg: a10,
  },
  {
    name: "Chemistry",
    id: 11,
    oimg: rect11,
    iimg: a11,
  },
  {
    name: "Biology",
    id: 12,
    oimg: rect12,
    iimg: a12,
  },
];
